import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"
import { useTranslation } from "react-i18next"
import fp from "lodash/fp"

import { useDeviceAlertTypesQuery } from "features/api"
import useAlertSettingsWStates from "helpers/hooks/useAlertSettingsStates"
import useAlertSettings from "helpers/hooks/useAlertSettings"
import type { AlertSetting, AlertSettingType } from "types/alerts.types"
import AlertSettings from "../AlertSettings"

function DeviceAlertSettings() {
  const { id } = useOutletContext<{ id: string }>()
  const { i18n } = useTranslation()
  const userLanguage = i18n.resolvedLanguage
  const { alertSettingsByDeviceId, isLoading: settingsLoading } =
    useAlertSettingsWStates()
  const { orgAlertsBySettingId } = useAlertSettings()

  const alertSettings: AlertSetting[] = useMemo(
    () =>
      fp.flow(
        fp.get(id),
        fp.defaultTo([] as number[]),
        fp.filter((deviceAlertSettingId) =>
          fp.has(deviceAlertSettingId, orgAlertsBySettingId),
        ),
        fp.map((deviceAlertSettingId) =>
          fp.get(deviceAlertSettingId, orgAlertsBySettingId),
        ),
      )(alertSettingsByDeviceId),
    [orgAlertsBySettingId, alertSettingsByDeviceId, id],
  )

  const { data: alertTypes, isLoading: typesLoading } = useDeviceAlertTypesQuery({
    lessId: Number(id),
    params: {
      lang: userLanguage,
    },
  })

  const sortedAlertTypes = useMemo(
    () => fp.sortBy((type: AlertSettingType) => type.title)(alertTypes),
    [alertTypes],
  )

  return (
    <AlertSettings
      alertSettings={alertSettings}
      alertTypes={sortedAlertTypes}
      isPending={typesLoading || settingsLoading}
      entity="device"
    />
  )
}

export default DeviceAlertSettings
