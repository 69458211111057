import type { FC } from "react"
import { useMemo, useState } from "react"
import Grid from "@mui/material/Grid"
import Container from "@mui/material/Container"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"

import type { AlertSetting, AlertSettingType } from "types/alerts.types"
import Message from "widgets/common/Message"
import CardWithTitle from "widgets/common/CardWithTitle"
import usePermissions from "helpers/hooks/usePermissions"
import type { EntityType } from "types/common.types"
import AddAlertSetting from "./AddAlertSetting"
import AlertSettingCard from "./AlertSettingCard"
import UpdateAlertSetting from "./UpdateAlertSetting"
import AlertsSettingsSkeleton from "./AlertsSettingsSkeleton"

interface AlertSettingsProps {
  alertSettings: AlertSetting[]
  alertTypes: AlertSettingType[]
  isPending: boolean
  entity: EntityType
}

const AlertSettings: FC<AlertSettingsProps> = ({
  alertSettings,
  alertTypes,
  isPending,
  entity,
}) => {
  const { isEditor } = usePermissions()
  const [creatingAlert, setCreatingAlert] = useState(false)
  const [editingAlert, setEditingAlert] = useState(false)
  const [selectedAlert, setSelectedAlert] = useState<AlertSetting | undefined>(undefined)

  const onCreateClick = () => {
    setCreatingAlert(true)
  }
  const onCreateClose = () => {
    setCreatingAlert(false)
  }
  const onEditOpen = () => {
    setEditingAlert(true)
    setCreatingAlert(false)
  }
  const onEditClose = () => {
    setEditingAlert(false)
  }
  const isAlertCreationAllowed = useMemo(
    () => creatingAlert || editingAlert,
    [creatingAlert, editingAlert],
  )

  return (
    <CardWithTitle
      titleKey={"alerts.ALERTS_SETTINGS"}
      actionPermission={isEditor}
      isDoingAction={isAlertCreationAllowed}
      handleAction={onCreateClick}
      actionIcon={<AddCircleOutlineIcon fontSize="small" />}
    >
      <Container maxWidth="xl" disableGutters sx={{ minHeight: "200px" }}>
        {creatingAlert && (
          <AddAlertSetting types={alertTypes} onClose={onCreateClose} entity={entity} />
        )}
        {editingAlert && selectedAlert ? (
          <UpdateAlertSetting
            selectedAlert={selectedAlert}
            onClose={onEditClose}
            types={alertTypes}
            entity={entity}
          />
        ) : isPending ? (
          <AlertsSettingsSkeleton />
        ) : (
          <>
            {alertSettings?.length === 0 && !creatingAlert && (
              <Message messageKey={"alerts.NO_ALERTS_SETTINGS"} />
            )}
            <Grid container spacing={2}>
              {alertTypes &&
                alertSettings.length !== 0 &&
                alertSettings.map((alertSetting) => (
                  <Grid key={alertSetting.id} item xs={12} sm={6} lg={4}>
                    <AlertSettingCard
                      alertSetting={alertSetting}
                      alertTypes={alertTypes}
                      onOpen={onEditOpen}
                      setSelectedAlert={setSelectedAlert}
                      entity={entity}
                    />
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </Container>
    </CardWithTitle>
  )
}

export default AlertSettings
