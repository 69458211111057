import type { FC } from "react"
import Tooltip from "@mui/material/Tooltip"
import Stack from "@mui/material/Stack"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { useTranslation } from "react-i18next"

import type { EntityType } from "types/common.types"
import type { AlertSettingParam } from "../../../types/alerts.types"
import ControlledInput from "../../common/ControlledInput"
import { getAlertSettingParamRules } from "../../../helpers/utils/alerts"
import ThresholdForm from "./ThresholdForm"
import DeviceTelemetryInput from "./device/DeviceTelemetryInput"
import PowerlineTelemetryInput from "./powerline/PowerlineTelemetryInput"

interface AlertSettingInputProps {
  entity: EntityType
  param: AlertSettingParam
}

const getParamInputType = (type: string) => {
  switch (type) {
    case "options": {
      return "select"
    }
    case "bool": {
      return "checkbox"
    }
    default: {
      return "text"
    }
  }
}

const AlertSettingInput: FC<AlertSettingInputProps> = ({ param, entity }) => {
  const inputType = getParamInputType(param.type)
  const isCheckbox = inputType === "checkbox"
  const selectItems =
    inputType === "select" && param?.schema && "oneOf" in param.schema
      ? param.schema.oneOf.map((item) => ({
          label: item.label,
          value: item.const,
        }))
      : undefined
  const { t } = useTranslation()
  const rules = getAlertSettingParamRules(param, t)

  const getInput = (paramId: string) => {
    switch (paramId) {
      case "fields":
        switch (entity) {
          case "powerline":
            return <PowerlineTelemetryInput param={param} />
          case "device":
          default:
            return <DeviceTelemetryInput param={param} />
        }
      case "thresholds":
        return <ThresholdForm param={param} rules={rules} />
      default:
        return (
          <ControlledInput
            type={inputType}
            placeholder=""
            name={param.id}
            label={param.name}
            defaultValue={param.default ?? ""}
            selectItems={selectItems ? selectItems : null}
            variant="outlined"
            defaultChecked={!!param.default}
            rules={rules}
            endAdornment={param.unit}
          />
        )
    }
  }

  return (
    <Stack
      sx={{
        flexDirection: "row",
        width: isCheckbox ? "fit-content" : "100%",
        gap: isCheckbox ? "0rem" : "1rem",
        mb: "1rem",
        mr: isCheckbox ? "2rem" : 0,
        alignItems: "center",
        height: "fit-content",
      }}
    >
      {getInput(param.id)}
      {param.description && (
        <Tooltip
          title={<div style={{ whiteSpace: "pre-line" }}>{param.description}</div>}
          enterTouchDelay={0}
        >
          <InfoOutlinedIcon fontSize="medium" color="primary" />
        </Tooltip>
      )}
    </Stack>
  )
}

export default AlertSettingInput
