import { useMemo, type CSSProperties } from "react"
import Box from "@mui/material/Box"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import { useTranslation } from "react-i18next"

import { getPlotName } from "helpers/utils/translations"
import { formatPlotTitleTooltip } from "helpers/utils/plots"
import { PlotContainer } from "../styled/containers"
import type { RecordType, CellViewType, RecordService } from "../../types/dashboard.types"
import GeoPlot from "./geo/GeoPlot"
import GiraffePlot from "./GiraffePlot"
import SingleStat from "./SingleStat"
import PieChart from "./recharts/PieChart"
import GaugeChart from "./recharts/gauge/GaugeChart"
import PlotTitle from "./PlotTitle"

interface PlotProps {
  view: CellViewType
  data: RecordType[]
  recordService?: RecordService
  draggable?: boolean
  showUnit?: boolean
  styles?: CSSProperties
}

export default function Plot({
  view,
  data,
  recordService,
  styles,
  draggable = false,
  showUnit = false,
}: PlotProps) {
  const InnerPlot = () => {
    const type = view.properties.type
    switch (type) {
      case "geo":
        return <GeoPlot data={data} />
      case "single-stat":
        return <SingleStat view={view} data={data} />
      case "gauge":
        return <GaugeChart view={view} data={data} />
      case "pie":
        return <PieChart view={view} data={data} />
      default:
        return <GiraffePlot view={view} data={data} recordService={recordService} />
    }
  }
  const { t } = useTranslation()
  const { nameKey, unit, tooltip } = getPlotName(view, showUnit)
  const tooltipContent = useMemo(
    () => (tooltip ? formatPlotTitleTooltip(tooltip, t) : undefined),
    [t, tooltip],
  )

  return (
    <>
      {data && view && (
        <>
          <PlotContainer maxWidth="xl" type={view.properties.type} sx={{ ...styles }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: "0.5rem" }}>
              <PlotTitle
                title={t([`device_information.${nameKey}`, view.name])}
                unit={unit}
                showUnit={showUnit}
                tooltipContent={tooltipContent}
              />
              {draggable && <DragIndicatorIcon className="dragIcon" data-testid="drag" />}
            </Box>
            <Box
              sx={{
                height: "calc(100% - 1rem)",
              }}
            >
              {InnerPlot()}
            </Box>
          </PlotContainer>
        </>
      )}
    </>
  )
}
