import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Switch } from "@mui/material"

import { useUpdatePowerlineAlertSettingMutation } from "features/api"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import type { PowerlineAlertSetting } from "types/alerts.types"

interface ToggleActivePowerlineAlertSettingProps {
  alertSetting: PowerlineAlertSetting
}

export default function ToggleActivePowerlineAlertSetting({
  alertSetting,
}: ToggleActivePowerlineAlertSettingProps) {
  const { t } = useTranslation()
  const [checked, setChecked] = useState(Boolean(alertSetting.config.active))
  const [updatePowerlineAlertSetting, { isLoading }] =
    useUpdatePowerlineAlertSettingMutation()

  const handleActiveSetting = () => {
    setChecked(!checked)
    snackbarMutation({
      mutation: updatePowerlineAlertSetting({
        powerlineId: alertSetting.entity_id,
        id: alertSetting.id,
        orgId: alertSetting.org_id,
        type: alertSetting.type,
        config: {
          ...alertSetting.config,
          active: !alertSetting.config.active,
        },
      }).unwrap(),
      getErrorMessage: buildGetErrorMessage(t("alerts.ALERT_UPDATED_ERROR")),
      getSuccessMessage: () => t("alerts.ALERT_UPDATED_SUCCESS"),
    }).catch()
  }

  return (
    <Switch
      size="small"
      checked={checked}
      onChange={handleActiveSetting}
      disabled={isLoading}
    />
  )
}
