import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Grid, Stack, Typography } from "@mui/material"

import CustomLink from "widgets/common/CustomLink"
import useRoutes from "helpers/hooks/useRoutes"
import type { AlertSettingAccordion } from "types/alerts.types"
import AlertAccordionMenu from "widgets/alerts/AlertAccordionMenu"
import usePermissions from "helpers/hooks/usePermissions"
import { formatDateFromISO } from "helpers/utils/dateUtils"
import { getAlertLinkHref, getAlertStateIcon } from "helpers/utils/alerts"
import EntityTag from "widgets/common/EntityTag"

interface AlertAccordionSummaryContentProps {
  alert: AlertSettingAccordion
}

const AlertAccordionSummaryContent = ({ alert }: AlertAccordionSummaryContentProps) => {
  const { t } = useTranslation()
  const { baseOrgURL } = useRoutes()
  const { isEditor } = usePermissions()

  const alertStateIcon = useMemo(() => getAlertStateIcon(alert.state), [alert.state])
  const alertLinkHref = useMemo(
    () => getAlertLinkHref(alert, baseOrgURL),
    [alert, baseOrgURL],
  )

  return (
    <Stack direction={"row"} marginX={0.5} width={"100%"} gap={2}>
      <Stack direction={"row"} alignItems={"center"}>
        {alertStateIcon}
      </Stack>
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Stack spacing={0.5}>
            <Typography color="text.secondary">{t("alerts.ALERT_NAME")}:</Typography>
            <Typography variant="h5">{alert.config.custom_name}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Stack spacing={0.5}>
            <Typography color="text.secondary">{t("alerts.ALERT_TYPE")}:</Typography>
            <Typography variant="h5">
              {t(`alert_types.${alert.type.toUpperCase()}`)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Stack spacing={0.5}>
            <Typography color="text.secondary">{t("alerts.ENTITY_TYPE")}:</Typography>
            <EntityTag entity={alert.entity} />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Stack spacing={0.5}>
            <Typography color="text.secondary">{t("alerts.ENTITY_ID")}:</Typography>
            <CustomLink
              href={alertLinkHref}
              variant="h5"
              hover
              bold
              styles={{ pointerEvents: "auto" }}
            >
              {alert.entity_id}
            </CustomLink>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Stack spacing={0.5}>
            <Typography color="text.secondary">{t("alerts.ENTITY_NAME")}:</Typography>
            <CustomLink
              href={alertLinkHref}
              variant="h5"
              hover
              bold
              styles={{ pointerEvents: "auto" }}
            >
              {alert.name}
            </CustomLink>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Stack spacing={0.5}>
            <Typography color="text.secondary">{t("alerts.LAST_TRIGGERED")}:</Typography>
            <Typography variant="h5">{formatDateFromISO(alert.triggered_at)}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        onClick={(e) => e.stopPropagation()}
        minWidth={"34px"}
      >
        {isEditor && <AlertAccordionMenu alert={alert} />}
      </Stack>
    </Stack>
  )
}

export default AlertAccordionSummaryContent
