import { useMemo } from "react"
import { useTheme } from "@mui/material"
import type { Config } from "@influxdata/giraffe"

import { useTranslation } from "react-i18next"
import type { CellViewRes, RecordService, RecordType } from "types/dashboard.types"

import lineConfig from "./giraffeLine"

interface UseGiraffeConfig {
  view: CellViewRes
  data: RecordType[]
  recordService: RecordService
}

interface GraphConfig {
  containerStyles: object
  configuration: Config
}

export default function useGiraffeConfig({
  view,
  data,
  recordService,
}: UseGiraffeConfig) {
  const theme = useTheme()
  const { i18n } = useTranslation()
  const locale = i18n.resolvedLanguage

  const graphConfig: GraphConfig = useMemo(
    () =>
      lineConfig({
        view,
        data,
        recordService,
        palette: theme.palette,
        locale,
      }),
    [data, view, recordService, theme.palette, locale],
  )

  return graphConfig
}
