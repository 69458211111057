import { useMemo, type Dispatch, type SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { IconButton, Stack, Typography } from "@mui/material"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"

import LoadingButton from "./LoadingButton"

interface PaginationProps {
  count: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  limit?: number
  areMoreAvailable?: boolean
  loadMore?: () => void
  isWaiting?: boolean
}

const Pagination = ({
  count,
  page,
  setPage,
  limit = 10,
  areMoreAvailable = false,
  loadMore = () => {},
  isWaiting = false,
}: PaginationProps) => {
  const { t } = useTranslation()
  const pages = useMemo(() => Math.ceil(count / limit), [count, limit])
  const isFirstPage = useMemo(() => page === 0, [page])
  const isLastPage = useMemo(
    () => Math.ceil(count / limit) === page + 1,
    [count, limit, page],
  )

  const areMoreResultsRequestable = useMemo(() => {
    return isLastPage && areMoreAvailable
  }, [areMoreAvailable, isLastPage])

  return count ? (
    <Stack direction={"row"} justifyContent={"end"} alignItems={"center"}>
      <IconButton
        size="small"
        sx={{ color: (theme) => theme.palette.text.primary }}
        disabled={isFirstPage}
        onClick={() => setPage(page - 1)}
        data-testid={"prev-arrow"}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <Typography data-testid={"pages-info"}>{`${page + 1} ${t(
        "table.OF",
      )} ${pages}`}</Typography>
      <IconButton
        size="small"
        sx={{ color: (theme) => theme.palette.text.primary }}
        disabled={page + 1 === pages}
        onClick={() => setPage(page + 1)}
        data-testid={"next-arrow"}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
      {areMoreResultsRequestable && (
        <LoadingButton type="button" onClick={() => loadMore()} loading={isWaiting}>
          {t("table.LOAD_MORE")}
        </LoadingButton>
      )}
    </Stack>
  ) : (
    <></>
  )
}

export default Pagination
