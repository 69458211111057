import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"
import { useTranslation } from "react-i18next"
import fp from "lodash/fp"

import { usePowerlineAlertTypesQuery } from "features/api"
import useAlertSettingsStates from "helpers/hooks/useAlertSettingsStates"
import useAlertSettings from "helpers/hooks/useAlertSettings"
import type { AlertSetting, AlertSettingType } from "types/alerts.types"
import AlertSettings from "../AlertSettings"

const PowerlineAlertSettings = () => {
  const { id } = useOutletContext<{ id: string }>()
  const { i18n } = useTranslation()
  const userLanguage = i18n.resolvedLanguage
  const { alertSettingsByPowerlineId, isLoading: settingsLoading } =
    useAlertSettingsStates()
  const { orgAlertsBySettingId } = useAlertSettings()

  const alertSettings: AlertSetting[] = useMemo(
    () =>
      fp.flow(
        fp.get(id),
        fp.defaultTo([] as number[]),
        fp.filter((powerlineAlertSettingId) =>
          fp.has(powerlineAlertSettingId, orgAlertsBySettingId),
        ),
        fp.map((powerlineAlertSettingId) =>
          fp.get(powerlineAlertSettingId, orgAlertsBySettingId),
        ),
      )(alertSettingsByPowerlineId),
    [orgAlertsBySettingId, alertSettingsByPowerlineId, id],
  )

  const { data: alertTypes, isLoading: typesLoading } = usePowerlineAlertTypesQuery({
    params: {
      lang: userLanguage,
    },
  })

  const sortedAlertTypes = useMemo(
    () => fp.sortBy((type: AlertSettingType) => type.title)(alertTypes),
    [alertTypes],
  )

  return (
    <AlertSettings
      alertSettings={alertSettings}
      alertTypes={sortedAlertTypes}
      isPending={typesLoading || settingsLoading}
      entity="powerline"
    />
  )
}

export default PowerlineAlertSettings
