import * as L from "leaflet"
import type { Map } from "leaflet"
import fp from "lodash/fp"
import type { Feature, GeometryCollection, LineString, Point, Position } from "geojson"

import type { TFunction } from "i18next"
import towerUrl from "images/tower.png"
import sentriV4IconUrl from "images/sentri-v4.png"
import triggeredSentriV4IconUrl from "images/sentri-v4-alert.png"
import sentriV23IconUrl from "images/sentri-v2-v3.png"
import triggeredSentriV23IconUrl from "images/sentri-v2-v3-alert.png"
import type {
  FeaturePropertiesType,
  DeviceLocation,
  LatLonRecord,
  GeoJSONLayersData,
  OrgGeoJSON,
  Coords,
  LatLonRecordTelemetry,
} from "types/geolocation.types"
import type { RecordType } from "types/dashboard.types"

import type { IDevsWRelsByIdWStates } from "types/device.types"
import defaultIconUrl from "images/marker.png"
import defaultIconRetinaUrl from "images/marker-x2.png"
import triggeredIconUrl from "images/marker-red.png"
import triggeredIconRetinaUrl from "images/marker-red-x2.png"
import { valueFormatter } from "helpers/formatters/plots/plots"
import { isVersionAboveOrEqual, isVirtualDevice } from "./devices"

//MAP ICONS
export const TRIGGERED_DEVICE_MARKER = L.icon({
  iconUrl: triggeredIconUrl,
  iconRetinaUrl: triggeredIconRetinaUrl,
  iconSize: [25, 37],
})

export const SENTRI_V4_ICON = L.icon({
  iconUrl: sentriV4IconUrl,
  iconSize: [35, 35],
})
export const TRIGGERED_SENTRI_V4_ICON = L.icon({
  iconUrl: triggeredSentriV4IconUrl,
  iconSize: [40, 35],
  iconAnchor: [17, 17],
})

export const SENTRI_V2_3_ICON = L.icon({
  iconUrl: sentriV23IconUrl,
  iconSize: [35, 35],
})
export const TRIGGERED_SENTRI_V2_3_ICON = L.icon({
  iconUrl: triggeredSentriV23IconUrl,
  iconSize: [40, 35],
  iconAnchor: [17, 17],
})

export const DEFAULT_MARKER = L.icon({
  iconUrl: defaultIconUrl,
  iconRetinaUrl: defaultIconRetinaUrl,
  iconSize: [25, 37],
})

export const TOWER = L.icon({
  iconUrl: towerUrl,
  iconRetinaUrl: towerUrl,
  iconSize: [25, 25],
})

export const featureProperties: FeaturePropertiesType = {
  Powerline: ["kind", "name", "id", "ampacity_nominal", "voltage_nominal"],
  PowerlineSpan: ["kind", "name", "id", "ampacity_nominal", "length"],
  PowerlineTower: ["kind", "name", "id", "height", "geolocation"],
  Device: ["kind", "name", "less_id", "device_type"],
}

export const getMarkerIcon = (triggered: boolean, version?: string, target?: string) => {
  if (version && target) {
    if (triggered) {
      return isVersionAboveOrEqual(version, target)
        ? TRIGGERED_SENTRI_V4_ICON
        : TRIGGERED_SENTRI_V2_3_ICON
    } else {
      return isVersionAboveOrEqual(version, target) ? SENTRI_V4_ICON : SENTRI_V2_3_ICON
    }
  } else {
    if (triggered) {
      return TRIGGERED_DEVICE_MARKER
    } else {
      return DEFAULT_MARKER
    }
  }
}

export const showTooltips = (mapLayer: Map, shouldShow: boolean) => {
  mapLayer?.eachLayer((layer) => {
    if (layer.getTooltip()) {
      shouldShow ? layer.openTooltip() : layer.closeTooltip()
    }
  })
}

export const getLatLonDataFromRecord = (data: RecordType[]): LatLonRecordTelemetry[] => {
  const formattedTelemetryData: LatLonRecordTelemetry[] = fp.flow(
    fp.filter(({ _field }) => _field === "lat" || _field === "lon"),
    fp.groupBy("id"),
    fp.toPairs,
    fp.map(([_, value]) => {
      let joinedData = { name: "Location" }
      fp.each((obj) => {
        const { _field, _value, ...rest } = obj
        if (_field === "lat") {
          joinedData = { ...rest, ...joinedData, lat: _value }
        } else if (_field === "lon") {
          joinedData = { ...rest, ...joinedData, lon: _value }
        }
      }, value)
      return [_, joinedData]
    }),
    fp.fromPairs,
    fp.values,
    fp.filter((obj: LatLonRecordTelemetry) => {
      return typeof obj.lat === "number" && typeof obj.lon === "number"
    }),
  )(data)

  return formattedTelemetryData
}

export const dropVirtualDevices = (deviceLocations: DeviceLocation[]) => {
  return deviceLocations.filter(
    (deviceLocation) => !isVirtualDevice(deviceLocation.device.device_type),
  )
}

export const formatDeviceLatLonData = (
  latlonData: LatLonRecord[] | Coords[],
  devsWRelsByIdWStates: IDevsWRelsByIdWStates,
): DeviceLocation[] => {
  return latlonData?.map((obj) => {
    const { groups, settingsStates, device } = devsWRelsByIdWStates[obj.id] || {}
    const extras = fp.omitBy(fp.isNil, {
      groups,
      settingsStates,
    })
    return {
      device: { ...device, less_id: Number(obj.id) },
      ...extras,
      location: { ...obj },
    }
  })
}

export const onEachFeature =
  ({
    kind,
    t,
    locale,
  }: {
    kind: string
    t: TFunction
    baseOrgURL?: string
    locale?: string
  }) =>
  (feature: Feature, layer: L.Layer) => {
    const { properties } = feature
    const popupContent = featureProperties[kind as keyof FeaturePropertiesType].map(
      (property: string) => {
        if (properties && property in properties) {
          const formattedProperty =
            typeof properties[property] === "number" && properties[property] % 1 !== 0
              ? valueFormatter({
                  value: properties[property],
                  decimalPlaces: 3,
                  locale,
                })
              : properties[property]

          return `<tr class="row"><td class="first-column">${t(
            `geo_info.${property.toUpperCase()}`,
          )}</td><td>${formattedProperty}</td></tr> `
        }
      },
    )
    layer.bindPopup(`<table>${popupContent.join(" ")}</table>`, {
      className: "map-popup",
    })
  }

export const getDevicePosition = (
  deviceLocation: DeviceLocation,
): L.LatLngTuple | undefined => {
  const { location, device } = deviceLocation
  if (!fp.isNil(location.lat) && !fp.isNil(location.lon)) {
    return [location.lat, location.lon]
  } else if (
    device.settings &&
    device.settings.geo_location &&
    "latitude" in device.settings.geo_location &&
    "longitude" in device.settings.geo_location
  ) {
    return [device.settings.geo_location.latitude, device.settings.geo_location.longitude]
  } else {
    return
  }
}

export const getMapCenterFromDeviceLocation = (
  data: DeviceLocation[] | undefined | null,
): L.LatLngTuple | null => {
  const position = data?.length && getDevicePosition(data[0])
  return position ? position : null
}

export const getMapCenterFromGeo = (
  geoData: GeoJSONLayersData | undefined,
): L.LatLngTuple | null => {
  if (!geoData) return null

  const deviceCoords = geoData.Device?.features[0]?.geometry.coordinates
  if (deviceCoords) return [deviceCoords[1], deviceCoords[0]]

  const towerGeometry = geoData.PowerlineTower?.features[0]?.geometry
  if (!towerGeometry) return null

  const towerCoords =
    towerGeometry.type === "Point"
      ? towerGeometry.coordinates
      : towerGeometry.type === "GeometryCollection"
      ? (
          fp.find((g: Point | LineString) => g.type === "Point")(
            towerGeometry.geometries,
          ) as Point | undefined
        )?.coordinates
      : null

  return towerCoords ? [towerCoords[1], towerCoords[0]] : null
}

export const formatLineStringCoordinates = (data: Position[]): L.LatLngTuple[] =>
  data.map(([lng, lat]) => [lat, lng] as L.LatLngTuple)

export const formatMultiStringCoordinates = (data: Position[][]): L.LatLngTuple[][] =>
  data.map((innerArray) => formatLineStringCoordinates(innerArray))

export const formatPointCollectionCoordinates = (
  data: GeometryCollection<Point | LineString>,
): L.LatLngTuple => {
  const [lng, lat] = data.geometries[0].coordinates as Position
  return [lat, lng]
}

export const formatPointCoordinates = (data: Point): L.LatLngTuple => [
  data.coordinates[1],
  data.coordinates[0],
]

export const getCoordsFromGeoByLessId = (geo: OrgGeoJSON, lessId: number): Coords[] => {
  const coords = geo.Device.features.find(
    (feature) => feature.properties.less_id === lessId,
  )?.geometry.coordinates
  return coords ? [{ id: String(lessId), lat: coords[1], lon: coords[0] }] : []
}

export const getAndFormatLastLatLon = (location: LatLonRecord[] | Coords[]) => {
  return fp.flow(
    fp.map(({ lat, lon }: LatLonRecord | Coords) => ({
      lat: String(lat),
      lon: String(lon),
    })),
    fp.last,
  )(location)
}

export const checkEmptyGeoData = (data: GeoJSONLayersData | undefined): boolean =>
  fp.isEmpty(data?.Device?.features) &&
  fp.isEmpty(data?.Powerline?.features) &&
  fp.isEmpty(data?.PowerlineSpan?.features) &&
  fp.isEmpty(data?.PowerlineTower?.features)
