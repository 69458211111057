import { useMemo } from "react"
import { useParams } from "react-router-dom"

import useDateRange from "helpers/hooks/useDateRange"
import AlertsContainer from "widgets/alerts/AlertContainer"
import { BodyContainer } from "widgets/styled/containers"
import useDevices from "helpers/hooks/useDevices"

const PowerlineEventsLog = () => {
  const { id } = useParams()
  const { powerlineDateRange, dispatchPowerlineDateRange, isPowerlineDateRangeValid } =
    useDateRange()
  const { getLessIdFromPowerlineId } = useDevices()

  const virtualPowerlineId = useMemo(
    () => getLessIdFromPowerlineId(Number(id)),
    [getLessIdFromPowerlineId, id],
  )

  return (
    <BodyContainer disableGutters maxWidth="xl">
      {virtualPowerlineId && (
        <AlertsContainer
          showDatePicker={false}
          dateRange={powerlineDateRange}
          dispatchDateRange={dispatchPowerlineDateRange}
          isDateRangeValid={isPowerlineDateRangeValid}
          devicesIds={[virtualPowerlineId]}
        />
      )}
    </BodyContainer>
  )
}

export default PowerlineEventsLog
