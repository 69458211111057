import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import type { UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"

import type {
  AlertSetting,
  AlertSettingType,
  AlertSettingUpsertForm,
} from "types/alerts.types"

import { formatMutationParams } from "helpers/utils/alerts"
import { selectCurrentOrgId } from "features/store/orgSlice"
import { useUpdatePowerlineAlertSettingMutation } from "features/api"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import AlertSettingForm from "../AlertSettingForm"

interface UpdatePowerlineAlertSettingProps {
  selectedAlert: AlertSetting
  onClose: () => void
  types: AlertSettingType[]
  methods: UseFormReturn<AlertSettingUpsertForm, any>
}

function UpdatePowerlineAlertSetting({
  selectedAlert,
  onClose,
  types,
  methods,
}: UpdatePowerlineAlertSettingProps) {
  const { t } = useTranslation()
  const { id } = useParams()
  const orgId = useSelector(selectCurrentOrgId)
  const [updatePowerlineAlertSetting, { isLoading }] =
    useUpdatePowerlineAlertSettingMutation()

  const selectedTypeName = selectedAlert.type
  const selectedType = useMemo(
    () => types?.find((type) => type.name === selectedTypeName),
    [types, selectedTypeName],
  )

  const onSubmit = async (data: AlertSettingUpsertForm) => {
    const formattedData = formatMutationParams(data, selectedType)
    snackbarMutation({
      mutation: updatePowerlineAlertSetting({
        id: selectedAlert.id,
        orgId: Number(orgId),
        powerlineId: Number(id),
        ...formattedData,
      }).unwrap(),
      getErrorMessage: buildGetErrorMessage(
        t("error.UPDATING_ITEM", { item: t("alert_settings.ALERT_SETTING") }),
      ),
      getSuccessMessage: () =>
        t("success.UPDATING_ITEM", {
          item: t("alert_settings.ALERT_SETTING"),
          count: 1,
          context: "female",
        }),
    })
      .then(() => {
        onClose()
      })
      .catch()
  }

  return (
    <AlertSettingForm
      entity="powerline"
      isLoading={isLoading}
      title="alert_settings.EDIT_ALERT_SETTING"
      onSubmit={methods.handleSubmit(onSubmit)}
      methods={methods}
      types={types}
      selectedTypeName={selectedTypeName}
      onClose={onClose}
      buttonText="generic.UPDATE"
      disableType
    />
  )
}

export default UpdatePowerlineAlertSetting
