import { useMemo, type FC } from "react"

import type {
  AlertSetting,
  DeviceAlertSetting,
  PowerlineAlertSetting,
} from "types/alerts.types"
import type { EntityType } from "types/common.types"
import DeletePowerlineAlertSetting from "./powerline/DeletePowerlineAlertSetting"
import DeleteDeviceAlertSetting from "./device/DeleteDeviceAlertSetting"

interface IDeleteAlertSettingPopupProps {
  alertSetting: AlertSetting
  open: boolean
  onClose: () => void
  entity: EntityType
}

const DeleteAlertSettingPopup: FC<IDeleteAlertSettingPopupProps> = ({
  alertSetting,
  onClose,
  open,
  entity,
}) => {
  const content = useMemo(() => {
    switch (entity) {
      case "powerline":
        return (
          <DeletePowerlineAlertSetting
            alertSetting={alertSetting as PowerlineAlertSetting}
            onClose={onClose}
            open={open}
          />
        )
      default:
        return (
          <DeleteDeviceAlertSetting
            alertSetting={alertSetting as DeviceAlertSetting}
            onClose={onClose}
            open={open}
          />
        )
    }
  }, [entity, alertSetting, onClose, open])

  return content
}

export default DeleteAlertSettingPopup
