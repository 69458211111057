import {
  alpha,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import Popover from "@mui/material/Popover"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { DateTime } from "luxon"
import { useCallback, useMemo, useState, type FC } from "react"
import { useTranslation } from "react-i18next"

import type { DateRangeDateTime } from "types/date.types"
import type { EntityType } from "types/common.types"

import useSelectOptions from "helpers/hooks/useSelectOptions"
import { DatePickerFieldsContainer } from "widgets/styled/containers"
import { ListItemButton } from "widgets/styled/buttons"
import DateTimePicker from "./DateTimePicker"

interface IDatesPopover {
  updateDateRange: (
    fields: { [key: string]: DateTime | null },
    dateRange: DateRangeDateTime,
  ) => void
  dateRangeDateTime: DateRangeDateTime
  anchorEl: HTMLElement | null
  handleClose: () => void
  open: boolean
  setSelectedDateReference: (dateReferenceName: string) => void
  entity: EntityType
}

const DatesPopover: FC<IDatesPopover> = ({
  updateDateRange,
  dateRangeDateTime,
  anchorEl,
  handleClose,
  open,
  setSelectedDateReference,
  entity,
}) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const isMediumOrSmallDevice = useMediaQuery(theme.breakpoints.down("md"))
  const { getSelectOptionsByEntity } = useSelectOptions()
  const locale = i18n.resolvedLanguage === "en" ? "en-gb" : i18n.language
  const [pickedDateRange, setPickedDateRange] = useState<{
    fromDate: DateTime | null
    toDate: DateTime | null
  }>({ fromDate: dateRangeDateTime.fromDate, toDate: dateRangeDateTime.toDate })

  const selectOptions = useMemo(
    () => getSelectOptionsByEntity(entity),
    [entity, getSelectOptionsByEntity],
  )

  const onAccept = useCallback(
    (
      pickedDateRange: {
        fromDate: DateTime | null
        toDate: DateTime | null
      },
      dateRangeDateTime: DateRangeDateTime,
    ) => {
      updateDateRange(
        { fromDate: pickedDateRange.fromDate, toDate: pickedDateRange.toDate },
        dateRangeDateTime,
      )
      handleClose()
    },
    [handleClose, updateDateRange],
  )

  const handleChange = useCallback(
    (reference: string) => {
      setSelectedDateReference(reference)
      reference !== "custom" && handleClose()
    },
    [handleClose, setSelectedDateReference],
  )

  const maxDate = useMemo(
    () => (entity === "powerline" ? DateTime.now().plus({ days: 4 }) : undefined),
    [entity],
  )
  const disableFuture = useMemo(() => entity !== "powerline", [entity])

  const DatePickers = (
    <DatePickerFieldsContainer>
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        adapterLocale={locale}
        localeText={{ okButtonLabel: t("generic.APPLY") }}
      >
        <DateTimePicker
          disableFuture={disableFuture}
          maxDate={maxDate}
          label={t("generic.FROM")}
          value={dateRangeDateTime.fromDate}
          onChange={(value) =>
            setPickedDateRange({ ...pickedDateRange, fromDate: value })
          }
        />
        <DateTimePicker
          disableFuture={disableFuture}
          maxDate={maxDate}
          minDate={pickedDateRange.fromDate ?? undefined}
          label={t("generic.TO")}
          value={dateRangeDateTime.toDate}
          onChange={(value) => setPickedDateRange({ ...pickedDateRange, toDate: value })}
        />
      </LocalizationProvider>
      <Button
        variant="contained"
        onClick={() => onAccept(pickedDateRange, dateRangeDateTime)}
        fullWidth
      >
        {t("generic.ACCEPT")}
      </Button>
    </DatePickerFieldsContainer>
  )

  return (
    <Popover
      aria-label="dates popover"
      id={"dates-popover"}
      open={open}
      anchorEl={anchorEl}
      disableScrollLock
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: isMediumOrSmallDevice ? "left" : "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: isMediumOrSmallDevice ? "left" : "right",
      }}
      sx={{
        zIndex: 1299, // Avoids having tooltip on top allowing popper to stay on top. Popper uses 1300.
        "& .MuiPopover-paper": {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: "unset",
        },
        marginLeft: 0,
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          display: "flex",
        }}
      >
        {!isMediumOrSmallDevice && DatePickers}
        <Box
          sx={{
            maxHeight: "300px",
            overflow: "auto",
            minWidth: "160px",
          }}
        >
          <List
            sx={{
              boxShadow: (theme) =>
                `2px 2px 10px ${alpha(
                  String(theme.palette.neutral[100]),
                  0.5,
                )} inset, -2px -2px 10px ${alpha(
                  String(theme.palette.neutral[100]),
                  0.5,
                )} inset`,
            }}
          >
            {selectOptions.map((opt) => (
              <ListItem key={opt.value} disablePadding>
                <ListItemButton
                  selected={opt.value === dateRangeDateTime.range}
                  onClick={() => {
                    handleChange(opt.value)
                  }}
                >
                  <ListItemText primary={t(opt.label)} />
                </ListItemButton>
              </ListItem>
            ))}
            {isMediumOrSmallDevice && DatePickers}
          </List>
        </Box>
      </Box>
    </Popover>
  )
}

export default DatesPopover
