import { Stack } from "@mui/material"
import Container from "@mui/material/Container"
import { useTranslation } from "react-i18next"

import CardWithTitle from "widgets/common/CardWithTitle"
import CustomLink from "widgets/common/CustomLink"
import Title from "widgets/common/Title"
import { HeaderContainer } from "widgets/styled/containers"

export default function ProductDocs() {
  const { i18n, t } = useTranslation()
  const userLanguage = i18n.resolvedLanguage

  return (
    <Container maxWidth="xl" disableGutters>
      <HeaderContainer>
        <Title text={t("documentation.PRODUCT_MANUALS")} />
      </HeaderContainer>
      <Stack maxWidth="xl" sx={{ pt: 2, gap: 2 }}>
        <CardWithTitle titleKey={"documentation.INSTALLATION_GUIDES"}>
          <Stack gap={1}>
            <CustomLink
              href={
                userLanguage?.includes("es")
                  ? "https://docs.google.com/presentation/d/1A8V1OZowakn0RxcPs2XtoCPMPyI-eP6QJfBWKMMfExU/preview"
                  : "https://docs.google.com/presentation/d/1gRAIP-oM3krDrEiEHNCVtNqc53QkIKDfX8Ypk4YuzAk/preview"
              }
              target="_blank"
              underlineHover
            >
              {t("documentation.ON_SITE_GUIDE_SENTRI", {
                product: "Sentri Line guard 4.0",
              })}
            </CustomLink>
            <CustomLink
              href={
                userLanguage?.includes("es")
                  ? "https://docs.google.com/presentation/d/1x8tvMlZKqpIUDwMMLHE9EcL0laLFxosbggvzQxlhJI0/preview"
                  : "https://docs.google.com/presentation/d/1ueAF2prc7FcFiz4w3kdgzFpo1KD8cDGbmuICurF_KzM/preview"
              }
              target="_blank"
              underlineHover
            >
              {t("documentation.ON_SITE_GUIDE_SENTRI", {
                product: "Sentri Line guard 2.0 / 3.0",
              })}
            </CustomLink>
            <CustomLink
              href={
                userLanguage?.includes("es")
                  ? "https://docs.google.com/presentation/d/1BsrY1PEmnxfG8OVN0oOPpqAvvIH1iWK4lsgy6Avh0Zc/preview"
                  : "https://docs.google.com/presentation/d/1j8gmhmQo3vqmTpE6pTWJlFHHmiVFRs4Z7ZGkPgwbdDs/preview"
              }
              target="_blank"
              underlineHover
            >
              {t("documentation.ON_SITE_GUIDE_SENTRI", {
                product: `Sentri 2.0 / 3.0 ${t("documentation.FOR_TOWERS")}`,
              })}
            </CustomLink>
          </Stack>
        </CardWithTitle>
        <CardWithTitle titleKey={"documentation.USER_MANUALS"}>
          <Stack gap={1}>
            <CustomLink
              href={
                userLanguage?.includes("es")
                  ? "https://docs.google.com/document/d/1hkU25wpSaR_mWB9vg3poVNGiCD89hdbgV0gUffJXonU/preview"
                  : "https://docs.google.com/document/d/1Uv39-gluwBHpI_Og9DGJdFRBqtT5HW7do85fHWr0cRw/preview"
              }
              target="_blank"
              underlineHover
            >
              {t("documentation.USER_MANUAL_TITLE", {
                product: "Sentri Line guard 4.0",
              })}
            </CustomLink>
            <CustomLink
              href={
                userLanguage?.includes("es")
                  ? "https://docs.google.com/document/d/13qdEg8c1vP20BV5kspVclT1jjndrFcXQ46UOcxj_nMY/preview"
                  : "https://docs.google.com/document/d/1RCNFxf3uvYhEl8ATBz9Ego2utD2LKW_RiOTabYnGM34/preview"
              }
              target="_blank"
              underlineHover
            >
              {t("documentation.USER_MANUAL_TITLE", {
                product: "Sentri Line guard 2.0 / 3.0",
              })}
            </CustomLink>
          </Stack>
        </CardWithTitle>
      </Stack>
    </Container>
  )
}
