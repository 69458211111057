import { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Box, useTheme } from "@mui/material"
import fp from "lodash/fp"

import type { AlertSettingAccordion } from "types/alerts.types"
import type { EntityTelemetry } from "types/telemetries.types"
import type { RecordService } from "types/dashboard.types"

import { selectCurrentOrgId } from "features/store/orgSlice"
import { getRecordService } from "helpers/formatters/dataFormatters"
import { formatTelemetryPlotData } from "helpers/utils/plots"
import useEntityTelemetry from "helpers/hooks/useEntityTelemetry"
import AlertsContext from "widgets/alerts/AlertsContext"
import Message from "widgets/common/Message"
import Spinner from "widgets/common/Spinner"
import Plot from "widgets/plots/Plot"
import { getAlertViewAndFields, getQueryParams } from "./helpers"

interface IAlertChartProps {
  alert: AlertSettingAccordion
}

export function AlertChart({ alert }: IAlertChartProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const orgId = useSelector(selectCurrentOrgId)
  const { multipleDateRange } = useContext(AlertsContext)
  const dateRange = useMemo(
    () => multipleDateRange[alert.id],
    // update only when this accordion date range changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [multipleDateRange[alert.id], alert.id],
  )
  const formattedDateRange = useMemo(
    () => dateRange && { from_date: dateRange.fromDate, to_date: dateRange.toDate },
    [dateRange],
  )
  const { fields, view } = getAlertViewAndFields(alert)

  const params = useMemo(
    () => getQueryParams(alert.entity, fields, formattedDateRange, Number(orgId)),
    [alert.entity, fields, formattedDateRange, orgId],
  )

  const { data, isLoading } = useEntityTelemetry({
    entity: alert.entity,
    entityId: alert.entity_id,
    params,
  })

  const recordService: RecordService = useMemo(() => {
    // added name as required by giraffe
    const fill = ["_field", "name"]
    return getRecordService(fill, t)
  }, [t])

  const formattedData = useMemo(() => {
    // needed for giraffe as we can't put code in the tooltip
    type AddName = (records: EntityTelemetry[]) => EntityTelemetry[]
    const addName: AddName = fp.map((record: EntityTelemetry) => ({
      ...record,
      name: recordService.getName(record),
    }))
    return addName(formatTelemetryPlotData(data))
  }, [data, recordService])

  return isLoading || data === undefined ? (
    <Spinner />
  ) : (
    <>
      {formattedData.length ? (
        <Box sx={{ height: "400px" }}>
          <Plot
            data={formattedData}
            view={view}
            recordService={recordService}
            styles={{
              padding: 0,
              border: "none",
              [theme.breakpoints.up("sm")]: {
                padding: 0,
              },
            }}
          />
        </Box>
      ) : (
        <Message messageKey={"generic.NO_DATA_IN_TIME_WINDOW"} />
      )}
    </>
  )
}

export default AlertChart
