import { Box, Dialog, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { GridCloseIcon } from "@mui/x-data-grid"
import type { FC } from "react"
import { useTranslation } from "react-i18next"
import CardWithTitle from "widgets/common/CardWithTitle"

interface TowerViewDialogProps {
  open: boolean
  onClose: () => void
}

export const TowerViewDialog: FC<TowerViewDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
      <CardWithTitle
        titleKey="plots_descriptions.TOWER_STATIC_VIEW"
        handleAction={onClose}
        actionIcon={<GridCloseIcon fontSize="small" color="action" />}
      >
        <Stack gap={1}>
          <Typography>{t("plots_descriptions.LAST_POSITION_TOWER")}</Typography>
          <Box
            component="img"
            src={
              isMobile
                ? "/images/position/tower-static-view-mobile.png"
                : "/images/position/tower-static-view.png"
            }
            height="95%"
          />
        </Stack>
      </CardWithTitle>
    </Dialog>
  )
}
