import { useMemo, type FC } from "react"

import type { EntityType } from "types/common.types"
import type { AlertSettingType } from "types/alerts.types"
import AddPowerlineAlertSetting from "./powerline/AddPowerlineAlertSetting"
import AddDeviceAlertSetting from "./device/AddDeviceAlertSetting"

interface AddAlertSettingsProps {
  types: AlertSettingType[]
  onClose: () => void
  entity: EntityType
}

const AddAlertSetting: FC<AddAlertSettingsProps> = ({ types, onClose, entity }) => {
  const content = useMemo(() => {
    switch (entity) {
      case "powerline":
        return <AddPowerlineAlertSetting onClose={onClose} types={types} />
      default:
        return <AddDeviceAlertSetting onClose={onClose} types={types} />
    }
  }, [entity, onClose, types])

  return content
}

export default AddAlertSetting
