import type { FC } from "react"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { DateTime } from "luxon"

import { useSelector } from "react-redux"
import { useLazyPowerlineTelemetryQuery } from "features/api"
import type { AlertSettingParam } from "types/alerts.types"
import type { TelemetryType } from "types/telemetries.types"
import { formatSelectTelemetries } from "helpers/utils/telemetries"
import { selectCurrentOrgId } from "features/store/orgSlice"
import TelemetryInput from "../TelemetryInput"

interface PowerlineTelemetryInputProps {
  param: AlertSettingParam
}

const PowerlineTelemetryInput: FC<PowerlineTelemetryInputProps> = ({ param }) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const orgId = useSelector(selectCurrentOrgId)

  const [telemetries, setTelemetries] = useState<TelemetryType[]>()
  const [fetchTelemetries, { isFetching }] = useLazyPowerlineTelemetryQuery()

  useEffect(() => {
    const date = DateTime.now()
    const from_date: string = date.minus({ months: 6 }).toISO() as string
    const to_date: string = date.toISO() as string
    if (orgId) {
      fetchTelemetries({
        id: Number(id),
        params: {
          org_id: orgId,
          only_field_names: true,
          types: ["int", "float"],
          from_date,
          to_date,
          measurement: "telemetry",
        },
      })
        .unwrap()
        .then((data: TelemetryType[]) => setTelemetries(data))
        .catch(console.error)
    }
  }, [id, fetchTelemetries, orgId])

  const selectTelemetries = useMemo(
    () => formatSelectTelemetries(telemetries, t) || [],
    [telemetries, t],
  )

  return (
    <TelemetryInput
      param={param}
      isFetching={isFetching}
      telemetries={selectTelemetries}
    />
  )
}

export default PowerlineTelemetryInput
