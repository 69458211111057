import { IconButton, Stack, Tooltip, Typography } from "@mui/material"
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded"
import { useMemo } from "react"

interface PlotTitleProps {
  title: string
  unit?: string
  tooltipContent?: JSX.Element[] | []
  showUnit?: boolean
  onIconClick?: () => void
}

const PlotTitle = ({
  title,
  unit,
  tooltipContent,
  onIconClick,
  showUnit = false,
}: PlotTitleProps) => {
  const icon = useMemo(() => {
    const clickableIcon = (
      <IconButton onClick={onIconClick} sx={{ padding: 0.5, margin: 0 }}>
        <HelpOutlineRoundedIcon fontSize="small" color="primary" />
      </IconButton>
    )

    if (onIconClick) {
      if (tooltipContent) {
        return (
          <Tooltip
            title={<Stack gap={2}>{tooltipContent}</Stack>}
            enterTouchDelay={0}
            placement="bottom-start"
            arrow
          >
            {clickableIcon}
          </Tooltip>
        )
      } else {
        return clickableIcon
      }
    } else if (tooltipContent) {
      return (
        <Tooltip
          title={<Stack gap={2}>{tooltipContent}</Stack>}
          enterTouchDelay={0}
          placement="bottom-start"
          arrow
        >
          <HelpOutlineRoundedIcon
            fontSize="small"
            sx={{
              color: (theme) => theme.palette.neutral[500],
              cursor: "help",
              m: 0.5,
            }}
          />
        </Tooltip>
      )
    }
    return <></>
  }, [tooltipContent, onIconClick])

  return (
    <Stack direction={"row"} gap={1} alignItems={"center"} overflow={"hidden"}>
      <Typography variant="h5" noWrap>
        {title}
        {showUnit && unit && ` ${unit}`}
      </Typography>
      {icon}
    </Stack>
  )
}

export default PlotTitle
