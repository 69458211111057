import { useMemo } from "react"

import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import type {
  AlertSetting,
  AlertSettingType,
  AlertSettingUpsertForm,
} from "types/alerts.types"
import type { EntityType } from "types/common.types"
import { getTelemetryTranslation } from "helpers/utils/telemetries"
import { reverseFormatThresholds } from "helpers/utils/alerts"
import UpdateDeviceAlertSetting from "./device/UpdateDeviceAlertSetting"
import UpdatePowerlineAlertSetting from "./powerline/UpdatePowerlineAlertSetting"

interface UpdateAlertSettingProps {
  selectedAlert: AlertSetting
  onClose: () => void
  types: AlertSettingType[]
  entity: EntityType
}

const UpdateAlertSetting = ({
  selectedAlert,
  onClose,
  types,
  entity,
}: UpdateAlertSettingProps) => {
  const { t } = useTranslation()
  const defaultValues: {
    type: string
    [key: string]: Array<any> | boolean | number | string
  } = {
    type: selectedAlert.type,
    ...selectedAlert.config,
  }
  const fields = selectedAlert.config.fields as string[]
  defaultValues.fields = fields?.map((field) => {
    return {
      label: getTelemetryTranslation(field, t),
      value: field,
    }
  })

  if (defaultValues.thresholds && selectedAlert.config.thresholds) {
    const formattedThresholds = reverseFormatThresholds(selectedAlert.config.thresholds)
    defaultValues.thresholds = formattedThresholds
  }

  const methods = useForm<AlertSettingUpsertForm>({
    mode: "all",
    defaultValues,
  })

  const content = useMemo(() => {
    switch (entity) {
      case "powerline":
        return (
          <UpdatePowerlineAlertSetting
            selectedAlert={selectedAlert}
            onClose={onClose}
            types={types}
            methods={methods}
          />
        )
      default:
        return (
          <UpdateDeviceAlertSetting
            selectedAlert={selectedAlert}
            onClose={onClose}
            types={types}
            methods={methods}
          />
        )
    }
  }, [entity, selectedAlert, onClose, types, methods])

  return content
}

export default UpdateAlertSetting
