import { Card as MuiCard, Chip as MuiChip } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Drawer from "@mui/material/Drawer"
import Stack from "@mui/material/Stack"
import { styled } from "@mui/material/styles"

import { SECTIONS_BORDER_RADIUS, SIDEBAR_WIDTH } from "helpers/utils/constants"
import type {
  ICustomAppBarProps,
  IDefaultStyledProps,
  IOrganizationPickerBoxProps,
  IPlotContainerProps,
} from "types/styled.types"

export const SectionCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== "styles",
})<IDefaultStyledProps>(({ theme, styles }) => ({
  display: "block",
  marginTop: "1rem",
  marginBottom: "1rem",
  backgroundColor: theme.palette.background.paper,
  border: "1px solid",
  borderColor: theme.palette.neutral[400],
  borderRadius: SECTIONS_BORDER_RADIUS,
  ...styles,
}))

export const SectionContainer = styled(Container)<IDefaultStyledProps>(
  ({ theme, styles }) => ({
    margin: "1rem 0",
    padding: "1rem 1.5rem",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid",
    borderColor: theme.palette.neutral[400],
    borderRadius: SECTIONS_BORDER_RADIUS,
    width: "unset",
    ...styles,
  }),
)

export const TabsContainer = styled(Box)<IDefaultStyledProps>(({ theme, styles }) => ({
  margin: 0,
  borderBottom: "1px solid",
  borderColor: theme.palette.neutral[400],
  ...styles,
}))

export const SectionCardHeader = styled(Box)<IDefaultStyledProps>(
  ({ theme, styles }) => ({
    padding: "0.7rem 1rem",
    display: "flex",
    borderRadius: `${SECTIONS_BORDER_RADIUS} ${SECTIONS_BORDER_RADIUS} 0 0`,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.primary.light
        : theme.palette.background.paper,
    ...styles,
  }),
)

export const CodeBox = styled(Box)<IDefaultStyledProps>(({ theme, styles }) => ({
  padding: "0.7rem 1rem",
  borderRadius: SECTIONS_BORDER_RADIUS,
  borderColor: theme.palette.grey[300],
  backgroundColor: theme.palette.background.default,
  marginBottom: "1rem",
  ...styles,
}))

export const Card = styled(MuiCard)<IDefaultStyledProps>(({ theme, styles }) => ({
  padding: "0rem 0.5rem",
  backgroundColor: theme.palette.background.light,
  backgroundImage: "none",
  ...styles,
}))

export const SummaryContainer = styled(Box)<IDefaultStyledProps>(({ theme, styles }) => ({
  padding: "1rem",
  border: `1px solid ${theme.palette.grey[400]}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: SECTIONS_BORDER_RADIUS,
  ...styles,
}))

export const CustomDrawer = styled(Drawer)<IDefaultStyledProps>(({ theme, open }) => ({
  width: SIDEBAR_WIDTH,
  "& .MuiDrawer-paper": {
    whiteSpace: "nowrap",
    backgroundImage: "none",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.grey[100],
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    width: "inherit",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
    }),
  },
}))

export const CustomAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<ICustomAppBarProps>(({ theme, open }) => ({
  root: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: "unset",
    [theme.breakpoints.up("lg")]: {
      ...(open && {
        marginLeft: SIDEBAR_WIDTH,
        width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    },
  },
}))

export const DashboardNavbarRoot = styled(CustomAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
}))

export const MainNavbarRoot = styled(CustomAppBar)(() => ({
  backgroundColor: "transparent",
}))

export const PositionBox = styled(Box)<IDefaultStyledProps>(({ theme, styles }) => ({
  flexDirection: "row",
  alignItems: "center",
  position: "relative",
  display: "flex",
  justifyContent: "space-around",
  width: "100%",
  height: "95%",
  backgroundColor: theme.palette.background.paper,
  borderRadius: 3,
  border: "1px solid",
  borderColor: theme.palette.neutral[400],
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  ...styles,
}))

export const GridBox = styled(Box)<IDefaultStyledProps>(({ theme }) => ({
  backgroundImage:
    theme.palette.mode === "dark"
      ? `url(/images/grid.png)`
      : `url(/images/grid-dark.png)`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  height: "100%",
  width: "100%",
  position: "absolute",
  opacity: 0.5,
  marginTop: "0.5rem",
}))

export const HeaderContainer = styled(Stack)<IDefaultStyledProps>(
  ({ theme, styles }) => ({
    alignItems: "center",
    gap: "0.5rem",
    marginBottom: "0.5rem",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    ...styles,
  }),
)

export const YAxisContainer = styled(Box)<IDefaultStyledProps>(({ styles }) => ({
  width: "100%",
  overflow: "hidden",
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  height: "100%",
  ...styles,
}))

export const PlotContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== "type",
})<IPlotContainerProps>(({ theme, styles, type }) => ({
  height: "100%",
  width: "100%",
  paddingTop: "1rem",
  paddingRight: "1rem",
  paddingLeft: "1rem",
  paddingBottom: type === "single-stat" ? "1rem" : "2rem",
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.neutral[400]}`,
  borderRadius: SECTIONS_BORDER_RADIUS,
  [theme.breakpoints.up("md")]: {
    paddingTop: "1rem",
    paddingRight: "1.5rem",
    paddingLeft: "1.5rem",
  },

  ...styles,
}))
export const BodyContainer = styled(Container)<IDefaultStyledProps>(({ theme }) => ({
  height: "100%",
  padding: 0,
  paddingBottom: "2rem",
  borderRadius: `0 0 ${SECTIONS_BORDER_RADIUS} ${SECTIONS_BORDER_RADIUS}`,
  borderColor: theme.palette.neutral[400],
}))

export const OrganizationPickerBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSidebarOpen",
})<IOrganizationPickerBoxProps>(({ theme, isSidebarOpen }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.grey[700],
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  padding: isSidebarOpen ? "0.8rem" : "0.6rem",
  borderRadius: "5px",
}))

export const DatePickerSectionCard = styled(Box)<IDefaultStyledProps>(
  ({ theme, styles }) => ({
    display: "flex",
    flexDirection: "row-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    ...styles,
  }),
)

export const DatePickerFieldContainer = styled(Box)<IDefaultStyledProps>(
  ({ theme, styles }) => ({
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    ...styles,
  }),
)

export const DatePickerFieldsContainer = styled(Box)<IDefaultStyledProps>(
  ({ styles }) => ({
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    maxWidth: "300px",
    alignItems: "start",
    ...styles,
  }),
)

export const PreloaderContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  width: "100vw",
  height: "100vh",
  position: "absolute",
  inset: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

export const Chip = styled(MuiChip)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.primary.main,
  fontSize: "0.7rem",
}))

export const DashedBox = styled(Box)(({ theme }) => ({
  border: "1px dashed",
  borderRadius: "5px",
  borderColor: theme.palette.primary.main,
  opacity: 0.5,
  width: "100%",
  mb: "1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    opacity: 0.9,
  },
}))

export const LogoContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  paddingTop: "1rem",
  paddingBottom: "1rem",
  display: "flex",
  justifyContent: "center",
}))
