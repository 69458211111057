import { useMemo, type FC } from "react"
import { LayerGroup, LayersControl, Polyline } from "react-leaflet"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material"

import type {
  DeviceFeaturePropsWGroups,
  GeoJSONLayersData,
} from "types/geolocation.types"

import {
  checkEmptyGeoData,
  formatLineStringCoordinates,
  formatMultiStringCoordinates,
  formatPointCollectionCoordinates,
  formatPointCoordinates,
  getMarkerIcon,
  TOWER,
} from "helpers/utils/map"
import { hasDeviceTriggeredAlerts } from "helpers/utils/alerts"
import type { AlertSettingStatesById } from "types/alerts.types"
import useDevices from "helpers/hooks/useDevices"
import useAlertSettings from "helpers/hooks/useAlertSettings"
import MapMarker from "widgets/plots/geo/MapMarker"
import GeoPopup from "widgets/plots/geo/GeoPopup"
import { getDeviceVersionAndTarget } from "helpers/utils/devices"

type LayersVisibility = {
  Powerline?: boolean
  PowerlineSpan?: boolean
  PowerlineTower?: boolean
  Device?: boolean
}

interface GeoJSONLayersProps {
  data: GeoJSONLayersData | undefined
  layersVisibility?: LayersVisibility
}

const GeoJSONLayers: FC<GeoJSONLayersProps> = ({ data, layersVisibility }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { orgAlertsBySettingId } = useAlertSettings()
  const { devsWRelsByIdWStates } = useDevices()

  const _layersVisibility = useMemo(
    () => ({
      Powerline: true,
      PowerlineSpan: true,
      PowerlineTower: true,
      Device: true,
      ...layersVisibility,
    }),
    [layersVisibility],
  )

  const isEmpty = useMemo(() => checkEmptyGeoData(data), [data])

  return (
    <>
      {!isEmpty && data && (
        <LayersControl position="topright">
          {data.Powerline && data.Powerline.features.length > 0 && (
            <LayersControl.Overlay
              checked={_layersVisibility.Powerline}
              name={t("geo_info.POWERLINES")}
            >
              <LayerGroup>
                {data.Powerline.features.map((feature) => {
                  const coordinates = formatMultiStringCoordinates(
                    feature.geometry.coordinates,
                  )
                  return (
                    <Polyline
                      key={feature.properties.id}
                      pathOptions={{ color: theme.palette.primary.main }}
                      positions={coordinates}
                    >
                      <GeoPopup type="powerline" data={feature.properties} />
                    </Polyline>
                  )
                })}
              </LayerGroup>
            </LayersControl.Overlay>
          )}
          {data.PowerlineSpan && data.PowerlineSpan.features.length > 0 && (
            <LayersControl.Overlay
              checked={_layersVisibility.PowerlineSpan}
              name={t("geo_info.SPANS")}
            >
              <LayerGroup>
                {data.PowerlineSpan.features.map((feature) => {
                  const coordinates = formatLineStringCoordinates(
                    feature.geometry.coordinates,
                  )
                  return (
                    <Polyline
                      key={feature.properties.id}
                      pathOptions={{ color: theme.palette.primary.dark }}
                      positions={coordinates}
                    >
                      <GeoPopup type="span" data={feature.properties} />
                    </Polyline>
                  )
                })}
              </LayerGroup>
            </LayersControl.Overlay>
          )}
          {data.PowerlineTower && data.PowerlineTower.features.length > 0 && (
            <LayersControl.Overlay
              checked={_layersVisibility.PowerlineTower}
              name={t("geo_info.TOWERS")}
            >
              <LayerGroup>
                {data.PowerlineTower.features.map((feature) => {
                  const position =
                    "geometries" in feature.geometry
                      ? formatPointCollectionCoordinates(feature.geometry)
                      : formatPointCoordinates(feature.geometry)
                  return (
                    <MapMarker
                      key={feature.id}
                      popup={<GeoPopup type="tower" data={feature.properties} />}
                      position={position}
                      icon={TOWER}
                      zIndexOffset={0}
                      tooltipContent={feature.properties.name}
                    />
                  )
                })}
              </LayerGroup>
            </LayersControl.Overlay>
          )}
          {data.Device && data.Device.features.length > 0 && (
            <LayersControl.Overlay
              checked={_layersVisibility.Device}
              name={t("geo_info.DEVICES")}
            >
              <LayerGroup>
                {data.Device.features.map((feature) => {
                  const { less_id: lessId } = feature.properties
                  const deviceLocation = {
                    device: { ...feature.properties },
                    groups:
                      devsWRelsByIdWStates[feature.properties?.less_id]?.groups ?? [],
                  } as DeviceFeaturePropsWGroups
                  const triggered = Boolean(
                    devsWRelsByIdWStates[lessId]?.settingsStates &&
                      hasDeviceTriggeredAlerts(
                        devsWRelsByIdWStates[lessId]
                          .settingsStates as AlertSettingStatesById,
                        orgAlertsBySettingId,
                      ),
                  )
                  const { target, version } = getDeviceVersionAndTarget(
                    deviceLocation.device,
                  )
                  const icon = getMarkerIcon(triggered, version, target)

                  if (
                    feature.properties &&
                    (feature.properties.device_type === "virtual_span" ||
                      feature.properties.device_type === "virtual_powerline")
                  )
                    return

                  return (
                    <MapMarker
                      key={lessId}
                      popup={<GeoPopup type="device" data={deviceLocation} />}
                      position={formatPointCoordinates(feature.geometry)}
                      zIndexOffset={triggered ? 500 : 100}
                      icon={icon}
                      tooltipContent={deviceLocation.device.name}
                    />
                  )
                })}
              </LayerGroup>
            </LayersControl.Overlay>
          )}
        </LayersControl>
      )}
    </>
  )
}

export default GeoJSONLayers
