import type { FC } from "react"
import { Stack, Typography } from "@mui/material"

import Spinner from "widgets/common/Spinner"
import ControlledAutocomplete from "widgets/common/ControlledAutocomplete"
import type { AlertSettingParam } from "types/alerts.types"

interface TelemetryInputProps {
  param: AlertSettingParam
  isFetching: boolean
  telemetries: { label: string; value: string }[]
}

const TelemetryInput: FC<TelemetryInputProps> = ({ param, isFetching, telemetries }) => {
  return (
    <Stack sx={{ width: "100%" }}>
      <Typography sx={{ mb: "1rem" }}>{param.name}</Typography>
      {isFetching ? (
        <Spinner />
      ) : (
        <Stack sx={{ flexDirection: "row", mb: "0.5rem" }}>
          <ControlledAutocomplete multiple options={telemetries} name={param.id} />
        </Stack>
      )}
    </Stack>
  )
}

export default TelemetryInput
