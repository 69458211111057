import type { Layout } from "react-grid-layout"

export const spanTabLayoutLg: Record<string, Layout> = {
  LOCATION: {
    w: 12,
    h: 4,
    x: 0,
    y: 0,
    minH: 0,
    i: "LOCATION",
  },
  MOTION_DETECTION: {
    w: 12,
    h: 3,
    x: 0,
    y: 5,
    minH: 0,
    i: "MOTION_DETECTION",
  },
  MOTION_INTENSITY: {
    w: 12,
    h: 3,
    x: 0,
    y: 8,
    minH: 0,
    i: "MOTION_INTENSITY",
  },
  TEMPERATURE: {
    w: 9,
    h: 3,
    x: 0,
    y: 11,
    minH: 0,
    i: "TEMPERATURE",
  },
  LAST_TEMPERATURE: {
    w: 3,
    h: 3,
    x: 9,
    y: 11,
    minH: 0,
    i: "LAST_TEMPERATURE",
  },
  DISPLACEMENT_AMPLITUDE: {
    w: 9,
    h: 3,
    x: 0,
    y: 14,
    minH: 0,
    i: "DISPLACEMENT_AMPLITUDE",
  },
  MAX_DISPLACEMENT_AMPLITUDE: {
    w: 3,
    h: 3,
    x: 9,
    y: 14,
    minH: 0,
    i: "MAX_DISPLACEMENT_AMPLITUDE",
  },
  TRANSVERSE_WIND_SPEED: {
    w: 9,
    h: 3,
    x: 0,
    y: 17,
    minH: 0,
    i: "TRANSVERSE_WIND_SPEED",
  },
  LAST_T_WIND_SPEED: {
    w: 3,
    h: 3,
    x: 9,
    y: 17,
    minH: 0,
    i: "LAST_T_WIND_SPEED",
  },
  SAG: {
    w: 9,
    h: 3,
    x: 0,
    y: 20,
    minH: 0,
    i: "SAG",
  },
  LAST_SAG: {
    w: 3,
    h: 3,
    x: 9,
    y: 20,
    minH: 0,
    i: "LAST_SAG",
  },
  CURRENT: {
    w: 9,
    h: 3,
    x: 0,
    y: 23,
    minH: 0,
    i: "CURRENT",
  },
  LAST_CURRENT: {
    w: 3,
    h: 3,
    x: 9,
    y: 23,
    minH: 0,
    i: "LAST_CURRENT",
  },
}

export const deviceTabLayoutLg: Record<string, Layout> = {
  BATTERY_STATUS: {
    w: 6,
    h: 3,
    x: 0,
    y: 4,
    minH: 0,
    i: "BATTERY_STATUS",
  },
  SIGNAL_LEVEL: {
    w: 6,
    h: 3,
    x: 7,
    y: 4,
    minH: 0,
    i: "SIGNAL_LEVEL",
  },
  BATTERY: {
    w: 12,
    h: 3,
    x: 0,
    y: 7,
    minH: 0,
    i: "BATTERY",
  },
  /*
    DATA_ENTRIES: {
      w: 3,
      h: 1,
      x: 0,
      y: 9,
      minH: 0,
      i: "DATA_ENTRIES",
    },
    COUNT: {
      w: 3,
      h: 1,
      x: 4,
      y: 9,
      minH: 0,
      i: "COUNT",
    },
    FIRST_MESSAGE: {
      w: 3,
      h: 1,
      x: 7,
      y: 9,
      minH: 0,
      i: "FIRST_MESSAGE",
    },
    LAST_MESSAGE: {
      w: 3,
      h: 1,
      x: 10,
      y: 9,
      minH: 0,
      i: "LAST_MESSAGE",
    },*/
}

export const powerlineInfoTabLayoutLg: Record<string, Layout> = {
  LOCATION: {
    w: 12,
    h: 4,
    x: 0,
    y: 0,
    minH: 0,
    i: "LOCATION",
  },
  AMPACITY: {
    w: 9,
    h: 3,
    x: 0,
    y: 5,
    minH: 0,
    i: "AMPACITY",
  },
  LAST_AMPACITY_AAR: {
    w: 3,
    h: 1.5,
    x: 9,
    y: 5,
    minH: 0,
    i: "LAST_AMPACITY_AAR",
  },
  LAST_AMPACITY_DLR: {
    w: 3,
    h: 1.5,
    x: 9,
    y: 6.5,
    minH: 0,
    i: "LAST_AMPACITY_DLR",
  },
  CURRENT: {
    w: 9,
    h: 3,
    x: 0,
    y: 8,
    minH: 0,
    i: "CURRENT",
  },
  LAST_CURRENT: {
    w: 3,
    h: 3,
    x: 9,
    y: 8,
    minH: 0,
    i: "LAST_CURRENT",
  },
}
