import fp from "lodash/fp"

import type { DevicePositionPlotValues } from "widgets/plots/device/devicePosition/DevicePositionPlot"
import type { Device } from "types/device.types"
import type { EntityTelemetry } from "types/telemetries.types"
import { getDeviceVersionAndTarget, isVersionAboveOrEqual } from "./devices"

const toMillis = (date: string) => new Date(date).getTime()
const getTime = (o: EntityTelemetry) => toMillis(o._time)

export const getLatestAngles = (
  data: EntityTelemetry[] | undefined,
): DevicePositionPlotValues => {
  type MaybePartialValues = Partial<DevicePositionPlotValues> | null

  // Pivot data and convert time to number
  type _Mangle = (
    o: EntityTelemetry,
    valueKey: string,
  ) => Partial<DevicePositionPlotValues>
  const _mangle: _Mangle = (o, valueKey) => ({
    ...o,
    [valueKey]: o._value,
    _time: toMillis(o._time),
  })

  // Latest for field, mangle the result to form the position plot values
  type _LatestPivot = (
    field: string,
    pivotKey: string,
    byField: Record<string, EntityTelemetry[]>,
  ) => MaybePartialValues
  const _latestPivot: _LatestPivot = (field, pivotKey, byField) => {
    const telemetries = fp.getOr([], field, byField)
    const latest = fp.maxBy(getTime, telemetries)
    return latest ? _mangle(latest, pivotKey) : null
  }

  // Merge the partial values, use max time
  type _Merge = (
    sources: MaybePartialValues[],
    fallback: DevicePositionPlotValues,
  ) => DevicePositionPlotValues
  const _merge: _Merge = (sources, fallback) => {
    return fp.mergeAllWith(
      (a, b, key) => {
        if (key === "_time") return fp.max([a, b])
      },
      [fallback, ...fp.filter((o) => Boolean(o), sources)],
    )
  }

  const byField = fp.groupBy((o: EntityTelemetry) => o._field, data)

  const latestAlpha = _latestPivot("derived.motion.alpha", "alpha", byField)
  const latestBeta = _latestPivot("derived.motion.beta", "beta", byField)

  // The position plot doesn't deal with missing data nicely, so add a fallback
  const fallback: DevicePositionPlotValues = {
    _time: Number.NEGATIVE_INFINITY,
    alpha: 0.0,
    beta: 0.0,
  }

  return _merge([latestAlpha, latestBeta], fallback)
}

export const getAnglesDrift = (data: EntityTelemetry[] | undefined) => {
  if (!data || data.length === 0)
    return { alpha: { _value: undefined }, beta: { _value: undefined } }

  const byField = fp.groupBy((o: EntityTelemetry) => o._field, data)

  const getFirstAndLast = (field: string) => {
    const telemetries = fp.getOr([], field, byField)
    if (telemetries.length === 0) return undefined

    const sorted = fp.sortBy(getTime, telemetries)
    return {
      first: Number(sorted[0]._value),
      last: Number(sorted[sorted.length - 1]._value),
    }
  }

  const alphaValues = getFirstAndLast("derived.motion.alpha")
  const betaValues = getFirstAndLast("derived.motion.beta")

  return {
    alpha: { _value: alphaValues ? alphaValues.last - alphaValues.first : undefined },
    beta: { _value: betaValues ? betaValues.last - betaValues.first : undefined },
  }
}

export const getViewsSrcs = (device: Device) => {
  if (device.settings?.installation_type === "tower") {
    return {
      alphaViewSrc: "/images/sentrisense/sentri-v2-v3-tower-s1.png",
      betaViewSrc: "/images/sentrisense/sentri-v2-v3-tower-s2.png",
    }
  } else {
    const { version, target } = getDeviceVersionAndTarget(device)
    const isNewVersion = isVersionAboveOrEqual(version, target)
    if (isNewVersion) {
      return {
        alphaViewSrc: "/images/sentrisense/sentri-v4-s3.png",
        betaViewSrc: "/images/sentrisense/sentri-v4-s1.png",
      }
    } else {
      return {
        alphaViewSrc: "/images/sentrisense/sentri-v2-v3-s1.png",
        betaViewSrc: "/images/sentrisense/sentri-v2-v3-s2.png",
      }
    }
  }
}

export const getPlotTooltipContent = (installationType: string | undefined) => {
  switch (installationType) {
    case "powerline":
      return ["plots_descriptions.LAST_POSITION_POWERLINE"]
    case "tower":
      return ["plots_descriptions.LAST_POSITION_TOWER"]
    default:
      return [
        "plots_descriptions.LAST_POSITION_POWERLINE",
        "plots_descriptions.LAST_POSITION_TOWER",
      ]
  }
}
