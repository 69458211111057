import { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import type { CSSProperties } from "react"
import type { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables"

import type { Powerline } from "types/powerlines.types"

import GenericTable from "widgets/common/Table"
import SkeletonWrapper from "widgets/common/SkeletonWrapper"
import {
  buildPowerlinesSearch,
  formatPowerlinesTableCsv,
  getPowerlinesTableColumns,
  getPowerlinesTableRows,
} from "helpers/utils/powerlines"

import {
  selectPowerlinesTable,
  setPowerlinesTableRowsAmount,
  setPowerlinesTableSortOrder,
} from "features/store/tablesSlice"
import { getColsByName } from "helpers/utils/alerts"
import useRoutes from "helpers/hooks/useRoutes"
import { formatFilename } from "helpers/utils/common"
import { selectCurrentOrgName } from "features/store/orgSlice"
import CustomTableFooter from "widgets/common/CustomTableFooter"

interface PowerlinesTableProps {
  powerlines: Powerline[]
  isFetching: boolean
  isLoading: boolean
  areMoreAvailable: boolean
  loadMore: () => void
  titleKey?: string
  customColumns?: MUIDataTableColumn[]
  options?: MUIDataTableOptions
  styles?: CSSProperties
}

const PowerlinesTable = ({
  powerlines,
  isFetching,
  isLoading,
  areMoreAvailable,
  loadMore,
  titleKey,
  customColumns = [],
  options,
  styles = {},
}: PowerlinesTableProps) => {
  const { baseOrgURL } = useRoutes()
  const { t } = useTranslation()
  const powerlinesTable = useSelector(selectPowerlinesTable)
  const orgName = useSelector(selectCurrentOrgName)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(powerlinesTable?.qtyRows)

  const columns: MUIDataTableColumn[] = useMemo(
    () =>
      customColumns.length > 0 ? customColumns : getPowerlinesTableColumns(baseOrgURL, t),
    [baseOrgURL, customColumns, t],
  )

  const { rows, columnsIndexes } = useMemo(() => {
    return {
      rows: getPowerlinesTableRows(powerlines),
      columnsIndexes: getColsByName(columns),
    }
  }, [powerlines, columns])

  return (
    <SkeletonWrapper isWaiting={isLoading || !powerlines} height={540} width={"100vw"}>
      <GenericTable
        styles={styles}
        title={titleKey}
        rows={rows}
        columns={columns}
        options={{
          page,
          customSearch: buildPowerlinesSearch(columnsIndexes),
          downloadOptions: {
            filename: formatFilename(`${orgName}_${t("powerlines.POWERLINES")}`, "csv"),
          },
          onDownload: (buildHead, buildBody, columns, data) => {
            const formattedData = formatPowerlinesTableCsv(data, columnsIndexes)
            return `${buildHead(columns)}${buildBody(formattedData)}`.trim()
          },
          /* --- Persistence values --- */
          rowsPerPage,
          sortOrder: powerlinesTable?.sortOrder,
          onColumnSortChange: (changedColumn, direction) => {
            dispatch(setPowerlinesTableSortOrder({ name: changedColumn, direction }))
          },
          customFooter: (rowCount: number, page: number, rowsPerPage: number) => {
            return (
              <CustomTableFooter
                rowCount={rowCount}
                page={page}
                rowsPerPage={rowsPerPage}
                changePage={(page) => setPage(page)}
                changeRowsPerPage={(rowsPerPage) => {
                  setRowsPerPage(rowsPerPage)
                  dispatch(setPowerlinesTableRowsAmount(rowsPerPage))
                }}
                areMoreAvailable={areMoreAvailable}
                loadMore={loadMore}
                isWaiting={isFetching}
              />
            )
          },
          ...options,
        }}
      />
    </SkeletonWrapper>
  )
}

export default PowerlinesTable
