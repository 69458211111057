import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import {
  formatDeviceAlerts,
  getAlertAmounts,
  getFilteredSortedAlerts,
  sortAndGroupAlerts,
} from "helpers/formatters/alertSettingsFormatters"
import useAlertSettings from "helpers/hooks/useAlertSettings"
import useAlertSettingsWStates from "helpers/hooks/useAlertSettingsStates"
import useDevices from "helpers/hooks/useDevices"
import { paginateList } from "helpers/utils/common"
import type { AlertsFilterOptions } from "types/alerts.types"
import Spinner from "widgets/common/Spinner"
import AlertsFilter from "widgets/alerts/AlertsFilter"
import AccordionsSection from "widgets/alerts/AccordionsSection"
import Pagination from "widgets/common/Pagination"
import { SectionContainer } from "widgets/styled/containers"

const DeviceAlertsContent = () => {
  const { id } = useParams()
  const { getDeviceAlertSettingsWStatesByEntityId, isLoading } = useAlertSettingsWStates()
  const { orgActiveDevicesAlertSettingsById } = useAlertSettings()
  const { devsWRelsById } = useDevices()
  const [filters, setFilters] = useState<AlertsFilterOptions | null>(null)
  const [page, setPage] = useState(0)

  const deviceSettingsStates = useMemo(
    () => getDeviceAlertSettingsWStatesByEntityId(Number(id)),
    [getDeviceAlertSettingsWStatesByEntityId, id],
  )

  const formattedDeviceAlerts = useMemo(
    () =>
      formatDeviceAlerts(
        deviceSettingsStates,
        orgActiveDevicesAlertSettingsById,
        devsWRelsById,
      ),
    [deviceSettingsStates, orgActiveDevicesAlertSettingsById, devsWRelsById],
  )
  const sortedAndGroupedAlerts = useMemo(
    () => sortAndGroupAlerts(formattedDeviceAlerts),
    [formattedDeviceAlerts],
  )
  const deviceAlertsAmount = useMemo(
    () => getAlertAmounts(sortedAndGroupedAlerts),
    [sortedAndGroupedAlerts],
  )
  const deviceAlertsSortedByState = useMemo(
    () => getFilteredSortedAlerts(sortedAndGroupedAlerts, filters),
    [sortedAndGroupedAlerts, filters],
  )
  const pagedAlerts = useMemo(
    () => paginateList(page, deviceAlertsSortedByState),
    [page, deviceAlertsSortedByState],
  )

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <SectionContainer disableGutters maxWidth="xl">
          <AlertsFilter
            alertsAmount={deviceAlertsAmount}
            filters={filters}
            setFilters={setFilters}
          />
          <AccordionsSection alerts={pagedAlerts} />
          <Pagination
            count={deviceAlertsSortedByState.length}
            page={page}
            setPage={setPage}
          />
        </SectionContainer>
      )}
    </>
  )
}

export default DeviceAlertsContent
