import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Switch } from "@mui/material"

import { useUpdateDeviceAlertSettingMutation } from "features/api"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import type { DeviceAlertSetting } from "types/alerts.types"

interface ToggleActiveDeviceAlertSettingProps {
  alertSetting: DeviceAlertSetting
}

export default function ToggleActiveDeviceAlertSetting({
  alertSetting,
}: ToggleActiveDeviceAlertSettingProps) {
  const { t } = useTranslation()
  const [checked, setChecked] = useState(Boolean(alertSetting.config.active))
  const [updateDeviceAlertSetting, { isLoading }] = useUpdateDeviceAlertSettingMutation()

  const handleActiveSetting = () => {
    setChecked(!checked)
    snackbarMutation({
      mutation: updateDeviceAlertSetting({
        ...alertSetting,
        config: {
          ...alertSetting.config,
          active: !alertSetting.config.active,
        },
      }).unwrap(),
      getErrorMessage: buildGetErrorMessage(t("alerts.ALERT_UPDATED_ERROR")),
      getSuccessMessage: () => t("alerts.ALERT_UPDATED_SUCCESS"),
    }).catch()
  }

  return (
    <Switch
      size="small"
      checked={checked}
      onChange={handleActiveSetting}
      disabled={isLoading}
    />
  )
}
