interface DeviceIconProps {
  color: string
}

const DeviceIcon = ({ color }: DeviceIconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 35 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2510_16955)">
        <rect y="21" width="35" height="35" rx="6" fill={color} />
        <path
          d="M34.9843 9.32516C34.9301 9.69522 34.7201 10.0341 34.4084 10.2503C34.1668 10.4175 33.889 10.5 33.6112 10.5C33.1957 10.5 32.7824 10.315 32.5069 9.96497C28.8619 5.37707 23.3921 2.7465 17.5 2.7465C11.6079 2.7465 6.13812 5.37707 2.49536 9.96497C2.0324 10.5468 1.1968 10.6717 0.591553 10.2503C0.279897 10.0341 0.0698684 9.69522 0.0156675 9.32516C-0.0407919 8.95287 0.0563182 8.58503 0.291189 8.29076C4.46466 3.0207 10.7362 0 17.5 0C24.2638 0 30.5353 3.0207 34.7088 8.29076C34.9437 8.58503 35.0408 8.95287 34.9843 9.32516Z"
          fill={color}
        />
        <path
          d="M27.9888 12.806C27.9402 13.1848 27.7325 13.5282 27.4231 13.7475L27.401 13.763C27.1668 13.9225 26.8995 14 26.6365 14C26.2498 14 25.8654 13.8294 25.5958 13.5082C23.5873 11.107 20.6354 9.72911 17.5 9.72911C14.3646 9.72911 11.4127 11.107 9.40419 13.5082C8.9446 14.0554 8.16021 14.1595 7.57909 13.7475L7.55258 13.7297C7.2565 13.5082 7.05985 13.1737 7.01124 12.806C6.96263 12.4294 7.0731 12.0462 7.31616 11.7538C9.84168 8.73228 13.5537 7 17.5 7C21.4463 7 25.1583 8.73228 27.6816 11.7538C27.9269 12.0462 28.0374 12.4294 27.9888 12.806Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2510_16955">
          <rect width="35" height="63" fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DeviceIcon
