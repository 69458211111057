import type { FC, PropsWithChildren } from "react"
import { useMemo, useReducer, useState } from "react"
import { useTranslation } from "react-i18next"
import { Responsive, WidthProvider } from "react-grid-layout"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import { Box, Stack, Typography } from "@mui/material"
import fp from "lodash/fp"

import { GRID_LAYOUT_GAP } from "helpers/utils/constants"
import { cellStateReducer, layoutReducer } from "helpers/reducers/dashboardReducers"
import type { PowerlinePlotDef } from "types/dashboard.types"
import { getCellHeight, getLayout } from "helpers/utils/dashboards"

import Message from "widgets/common/Message"
import Map from "widgets/plots/geo/Map"
import usePowerlineGeo from "helpers/hooks/powerlines/usePowerlineGeo"
import { getMapCenterFromGeo } from "helpers/utils/map"
import GeoJSONLayers from "widgets/plots/geo/GeoJSONLayers"
import { PlotContainer } from "widgets/styled/containers"
import PowerlineTelemetryPlot from "widgets/plots/powerline/PowerlineTelemetryPlot"
import DashboardContext from "./DashboardContext"

const GridLayout = WidthProvider(Responsive)

interface DashboardProps {
  powerlineId: number
  powerlineFetching: boolean
  config: {
    type: string
    plotDefs: PowerlinePlotDef[]
  }
}

const Dashboard: FC<PropsWithChildren<DashboardProps>> = ({
  powerlineId,
  powerlineFetching,
  config,
  children,
}) => {
  const { t } = useTranslation()
  const initialLayout = getLayout(config.type)
  //TODO: Once we have more layouts for the different media queries, we'll have to take that into account for the initial layout
  const [plotLayout, dispatchPlotLayout] = useReducer(layoutReducer, initialLayout)
  const layout = useMemo(() => fp.values(plotLayout), [plotLayout])
  const [plotState, dispatchPlotState] = useReducer(cellStateReducer, {})
  const lgRowHeight = 130
  const [rowHeight, setRowHeight] = useState(lgRowHeight)

  const plotsAreFetching = Object.values(plotState).some((value) => value)
  const isData = Object.values(layout).some((value) => value.h !== 0)

  const { powerlineGeoData, isLoading: geoLoading } = usePowerlineGeo({
    id: powerlineId,
  })

  const shouldRenderMap = useMemo(
    () =>
      powerlineGeoData?.Powerline?.features &&
      powerlineGeoData.Powerline.features.length > 0,
    [powerlineGeoData],
  )

  const center = useMemo(() => getMapCenterFromGeo(powerlineGeoData), [powerlineGeoData])

  return (
    <>
      <DashboardContext.Provider
        value={{
          config,
          powerlineId,
          dispatchPlotLayout,
          dispatchPlotState,
        }}
      >
        {children}
        <GridLayout
          style={{
            position: "relative",
            marginRight: `-${GRID_LAYOUT_GAP}px`,
            marginLeft: `-${GRID_LAYOUT_GAP}px`,
          }}
          layouts={{ lg: layout }}
          breakpoints={{
            lg: 1000,
            md: 800,
            sm: 650,
          }}
          cols={{ lg: 12, md: 6, sm: 3 }}
          margin={[GRID_LAYOUT_GAP, GRID_LAYOUT_GAP]}
          rowHeight={rowHeight}
          draggableHandle=".dragIcon"
          onBreakpointChange={(breakpoint: string) =>
            breakpoint === "sm" ? setRowHeight(120) : setRowHeight(lgRowHeight)
          }
        >
          <div key={"LOCATION"}>
            {!powerlineFetching && !geoLoading && powerlineGeoData && shouldRenderMap && (
              <PlotContainer maxWidth="xl" type={"line"}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Stack
                    direction={"row"}
                    gap={1}
                    alignItems={"center"}
                    pb={2}
                    height={40}
                  >
                    <Typography variant="h5">{t("powerlines.LOCATION")}</Typography>
                  </Stack>
                  <DragIndicatorIcon className="dragIcon" data-testid="drag" />
                </Box>
                <Map
                  center={center}
                  mapHeight={`calc(${
                    getCellHeight("LOCATION", "powerline") * rowHeight * 0.9
                  }px)`}
                >
                  <GeoJSONLayers data={powerlineGeoData} />
                </Map>
              </PlotContainer>
            )}
          </div>
          {config.plotDefs.map(({ view, draggable, reqConfig }) => {
            return (
              <div key={view.nameKey}>
                <PowerlineTelemetryPlot
                  view={view}
                  reqConfig={reqConfig}
                  draggable={draggable}
                />
              </div>
            )
          })}
        </GridLayout>
        {!plotsAreFetching && !isData && (
          <Message messageKey={"generic.NO_DATA_IN_TIME_WINDOW"} />
        )}
      </DashboardContext.Provider>
    </>
  )
}

export default Dashboard
