import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"

import type { Powerline } from "types/powerlines.types"

import { useLazyPowerlineQuery } from "features/api"
import { selectCurrentOrgId } from "features/store/orgSlice"
import { getPowerlinesById } from "helpers/utils/powerlines"

interface useSelectedPowerlinesProps {
  powerlineIds: number[]
}

const useSelectedPowerlines = ({ powerlineIds }: useSelectedPowerlinesProps) => {
  const orgId = useSelector(selectCurrentOrgId)
  const [powerlines, setPowerlines] = useState<Powerline[]>([])
  const [powerlinesLoading, setPowerlinesLoading] = useState(false)
  const [fetchPowerline] = useLazyPowerlineQuery()

  useEffect(() => {
    if (powerlineIds.length) {
      setPowerlinesLoading(true)
      Promise.all(
        powerlineIds.map(
          async (id) =>
            await fetchPowerline(
              { params: { org_id: Number(orgId) }, id },
              true,
            ).unwrap(),
        ),
      ).then((res) => {
        setPowerlines(res)
        setPowerlinesLoading(false)
      })
    }

    return () => setPowerlines([])
  }, [fetchPowerline, orgId, powerlineIds])

  const powerlinesById = useMemo(() => getPowerlinesById(powerlines), [powerlines])

  return {
    powerlines,
    powerlinesById,
    powerlinesLoading,
  }
}

export default useSelectedPowerlines
