import { useCallback, useMemo } from "react"
import { useSelector } from "react-redux"

import type {
  DeviceAlertSettingWState,
  GroupAlertSettingWState,
  PowerlineAlertSettingWState,
} from "types/alerts.types"

import { useOrgAlertSettingsStatesQuery } from "features/api"
import { selectCurrentOrgId } from "features/store/orgSlice"
import {
  formatSettingsByPowerlineId,
  formatSettingsByDeviceId,
  formatSettingsByGroupId,
  formatStateBySettingId,
} from "helpers/formatters/alertSettingsFormatters"
import { ALERT_TIME } from "helpers/utils/constants"

const useAlertSettingsWStates = () => {
  const orgId = useSelector(selectCurrentOrgId)
  const {
    currentData: orgAlertSettingsWStates,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useOrgAlertSettingsStatesQuery(
    {
      org_id: orgId as number,
    },
    {
      pollingInterval: ALERT_TIME,
      skip: !orgId,
    },
  )

  const { alertSettingsByDeviceId, alertSettingsByGroupId, alertSettingsByPowerlineId } =
    useMemo(() => {
      const alertSettingsByDeviceId = formatSettingsByDeviceId(orgAlertSettingsWStates)
      const alertSettingsByGroupId = formatSettingsByGroupId(orgAlertSettingsWStates)
      const alertSettingsByPowerlineId = formatSettingsByPowerlineId(
        orgAlertSettingsWStates,
      )
      return {
        alertSettingsByDeviceId,
        alertSettingsByGroupId,
        alertSettingsByPowerlineId,
      }
    }, [orgAlertSettingsWStates])

  const statesBySettingId = useMemo(
    () => formatStateBySettingId(orgAlertSettingsWStates),
    [orgAlertSettingsWStates],
  )

  const orgDevicesAlertSettingsWStates = useMemo(
    () =>
      (orgAlertSettingsWStates || []).filter(
        (settingState) => settingState.entity === "device",
      ) as DeviceAlertSettingWState[],
    [orgAlertSettingsWStates],
  )

  const getDeviceAlertSettingsWStatesByEntityId = useCallback(
    (entityId: number) =>
      orgDevicesAlertSettingsWStates.filter(
        (settingState) => settingState.entity_id === entityId,
      ),
    [orgDevicesAlertSettingsWStates],
  )

  const orgPowerlinesAlertSettingsWStates = useMemo(
    () =>
      (orgAlertSettingsWStates || []).filter(
        (settingState) => settingState.entity === "powerline",
      ) as PowerlineAlertSettingWState[],
    [orgAlertSettingsWStates],
  )

  const getPowerlineAlertSettingsWStatesByEntityId = useCallback(
    (entityId: number) =>
      orgPowerlinesAlertSettingsWStates.filter(
        (settingState) => settingState.entity_id === entityId,
      ),
    [orgPowerlinesAlertSettingsWStates],
  )

  const orgGroupsAlertSettingsWStates = useMemo(
    () =>
      (orgAlertSettingsWStates || []).filter(
        (settingState) => settingState.entity === "group",
      ) as GroupAlertSettingWState[],
    [orgAlertSettingsWStates],
  )

  const getGroupAlertSettingsWStatesByEntityId = useCallback(
    (entityId: number) =>
      orgGroupsAlertSettingsWStates.filter(
        (settingState) => settingState.entity_id === entityId,
      ),
    [orgGroupsAlertSettingsWStates],
  )

  return {
    orgAlertSettingsWStates,
    orgDevicesAlertSettingsWStates,
    getDeviceAlertSettingsWStatesByEntityId,
    orgPowerlinesAlertSettingsWStates,
    getPowerlineAlertSettingsWStatesByEntityId,
    orgGroupsAlertSettingsWStates,
    getGroupAlertSettingsWStatesByEntityId,
    statesBySettingId,
    alertSettingsByDeviceId,
    alertSettingsByGroupId,
    alertSettingsByPowerlineId,
    isFetching,
    isLoading,
    isError,
    refetch,
  }
}

export default useAlertSettingsWStates
