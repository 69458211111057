import Stack from "@mui/material/Stack"
import { useSelector } from "react-redux"
import { useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/system"
import type { QuerySubState } from "@reduxjs/toolkit/dist/query/core/apiState"
import type { BaseEndpointDefinition } from "@reduxjs/toolkit/dist/query/endpointDefinitions"

import type { RootState } from "features/store"
import type { DateRange } from "types/date.types"
import type { EntityType } from "types/common.types"

import DateRangePicker from "../../dates/DateRangePicker"
import DashboardMenu from "../DashboardMenu"

interface DashboardDatePickerProps {
  dateRange: DateRange | null
  dispatchDateRange: (dateRange: DateRange) => void
  entity?: EntityType
}

const DashboardDatePicker = ({
  dateRange,
  dispatchDateRange,
  entity = "device",
}: DashboardDatePickerProps) => {
  const theme = useTheme()
  const isMediumOrSmallDevice = useMediaQuery(theme.breakpoints.down("md"))

  const isFetching = useSelector((state: RootState) => {
    const queries = Object.values(state.api.queries)
    return queries.some(
      (query: QuerySubState<BaseEndpointDefinition<any, any, any>> | undefined) =>
        query?.status === "pending",
    )
  })

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: isMediumOrSmallDevice ? "space-between" : "end",
        width: isMediumOrSmallDevice ? "100%" : "fit-content",
        gap: 1,
        flexShrink: 0,
      }}
    >
      <DateRangePicker
        dateRange={dateRange}
        dispatchDateRange={dispatchDateRange}
        isFetching={isFetching}
        entity={entity}
      />
      <DashboardMenu />
    </Stack>
  )
}

export default DashboardDatePicker
