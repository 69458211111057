import type { Dispatch, SetStateAction } from "react"
import { useMemo, useState } from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"

import { Card } from "widgets/styled/containers"
import {
  getTypeParams,
  getUiConfigValue,
} from "helpers/formatters/alertSettingsFormatters"
import DeleteAlertSettingPopup from "widgets/alerts/settings/DeleteAlertSettingPopup"
import AlertSettingCardMenu from "widgets/alerts/settings/AlertSettingCardMenu"
import usePermissions from "helpers/hooks/usePermissions"
import type {
  AlertSetting,
  AlertSettingType,
  DeviceAlertSetting,
  PowerlineAlertSetting,
} from "types/alerts.types"
import type { EntityType } from "types/common.types"
import ToggleActivePowerlineAlertSetting from "./powerline/ToggleActivePowerlineAlertSetting"
import ToggleActiveDeviceAlertSetting from "./device/ToggleActiveDeviceAlertSetting"

interface AlertSettingCardProps {
  alertSetting: AlertSetting
  alertTypes: AlertSettingType[]
  onOpen: () => void
  setSelectedAlert: Dispatch<SetStateAction<AlertSetting | undefined>>
  entity: EntityType
}

export default function AlertSettingCard({
  alertSetting,
  alertTypes,
  onOpen,
  setSelectedAlert,
  entity,
}: AlertSettingCardProps) {
  const typeParams = useMemo(
    () =>
      getTypeParams(alertSetting.type, alertTypes, ["active", "custom_name", "send_sms"]),
    [alertSetting.type, alertTypes],
  )
  const { isEditor } = usePermissions()
  const [openDeletePopup, setOpenDeletePopup] = useState(false)

  const handleOpenDeletePopup = () => {
    setOpenDeletePopup(true)
  }
  const handleCloseDeletePopup = () => {
    setOpenDeletePopup(false)
  }

  const activeSwitch = useMemo(() => {
    switch (entity) {
      case "powerline":
        return (
          <ToggleActivePowerlineAlertSetting
            alertSetting={alertSetting as PowerlineAlertSetting}
          />
        )
      default:
        return (
          <ToggleActiveDeviceAlertSetting
            alertSetting={alertSetting as DeviceAlertSetting}
          />
        )
    }
  }, [entity, alertSetting])

  return (
    <>
      <Card
        elevation={3}
        styles={{
          height: "100%",
          paddingTop: "0.5rem",
        }}
      >
        <CardContent>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={"1rem"}
          >
            <Typography variant="h5" noWrap>
              {alertSetting.config.custom_name}
            </Typography>
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              {isEditor && (
                <>
                  {activeSwitch}
                  <AlertSettingCardMenu
                    alertSetting={alertSetting}
                    setSelectedAlert={setSelectedAlert}
                    onOpen={onOpen}
                    handleOpenDeletePopup={handleOpenDeletePopup}
                  />
                </>
              )}
            </Stack>
          </Stack>
          <Stack spacing={2} divider={<Divider flexItem />}>
            {typeParams?.map((typeParam, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <Typography>{typeParam.name}:</Typography>
                  <Typography align="right" color="text.secondary">
                    {getUiConfigValue(typeParam, alertSetting)}
                  </Typography>
                </Box>
              )
            })}
          </Stack>
        </CardContent>
      </Card>
      {openDeletePopup && (
        <DeleteAlertSettingPopup
          open={openDeletePopup}
          onClose={handleCloseDeletePopup}
          alertSetting={alertSetting}
          entity={entity}
        />
      )}
    </>
  )
}
