import { useTranslation } from "react-i18next"
import { Divider, Stack, Typography, useTheme } from "@mui/material"
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded"
import BoltRoundedIcon from "@mui/icons-material/BoltRounded"

import type { EntityType } from "types/common.types"
import DeviceIcon from "icons/DeviceIcon"

interface BaseTagProps {
  color: string
  lightColor: string
  icon: JSX.Element
  label: string
}

const BaseTag = ({ color, lightColor, icon, label }: BaseTagProps) => {
  return (
    <Stack
      width={"fit-content"}
      direction="row"
      justifyContent={"center"}
      alignItems={"center"}
      bgcolor={lightColor}
      border={1}
      borderColor={color}
      borderRadius={"5px"}
    >
      <Stack p={0.5} justifyContent={"center"} alignItems={"center"}>
        {icon}
      </Stack>
      <Divider orientation="vertical" flexItem sx={{ borderColor: color }} />
      <Typography py={0.5} px={1}>
        {label}
      </Typography>
    </Stack>
  )
}

interface EntityTagProps {
  entity: EntityType
}

const EntityTag = ({ entity }: EntityTagProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  switch (entity) {
    case "powerline":
      return (
        <BaseTag
          color="extras.blue.main"
          lightColor="extras.blue.light"
          icon={<BoltRoundedIcon fontSize="small" sx={{ color: "extras.blue.main" }} />}
          label={t(`entities.${entity.toUpperCase()}`)}
        />
      )
    case "group":
      return (
        <BaseTag
          color="extras.orange.main"
          lightColor="extras.orange.light"
          icon={
            <GridViewRoundedIcon fontSize="small" sx={{ color: "extras.orange.main" }} />
          }
          label={t(`entities.${entity.toUpperCase()}`)}
        />
      )
    case "device":
    default:
      return (
        <BaseTag
          color="extras.purple.main"
          lightColor="extras.purple.light"
          icon={<DeviceIcon color={theme.palette.extras.purple.main} />}
          label={t(`entities.${entity.toUpperCase()}`)}
        />
      )
  }
}

export default EntityTag
