import fp from "lodash/fp"

import type { EntityType } from "types/common.types"
import type {
  AlertSettingAccordion,
  AlertSettingConfigThreshold,
} from "types/alerts.types"
import type { DeviceParams, PowerlineParams } from "types/telemetries.types"

import { alertTypeDefs } from "helpers/config/plots/alertTypeDefs"
import { lineView } from "helpers/config/plots"

export const getAlertViewAndFields = (alert: AlertSettingAccordion) => {
  const type = alert.type
  const isThreshold = !fp.has(type, alertTypeDefs)
  const nonThresholdPlotDef = alertTypeDefs[type as keyof typeof alertTypeDefs]
  const view = isThreshold
    ? getAlertView(alert)
    : { ...nonThresholdPlotDef.view, name: "", nameKey: "" }
  const fields = isThreshold
    ? (alert.config.fields as string[])
    : (nonThresholdPlotDef.reqConfig.fields as string[])

  return { fields, view }
}

export const getAlertView = (alert: AlertSettingAccordion) => {
  const alertFields = alert.config.fields as string[]
  const fields = alertFields?.length > 0 ? alertFields : []
  const alertThresholds = alert.config.thresholds
  const thresholds = alertThresholds?.length ? alertThresholds : []
  const { min, max } = findMinAndMaxAlertThresholds(thresholds)
  const bounds = getAlertBounds(fields, min, max)

  return fp.merge(lineView, {
    properties: {
      axes: {
        y: {
          ...bounds,
        },
      },
      fill: ["_field"],
      legendColumns: ["_time", "_value", "name"],
    },
  })
}

export const findMinAndMaxAlertThresholds = (
  thresholds: AlertSettingConfigThreshold[],
): { min: number | null; max: number | null } => {
  const allThresholds = thresholds.flatMap((th) => {
    if (th.min !== undefined && th.max !== undefined) {
      return [th.min, th.max]
    }
    if (th.min !== undefined) {
      return [th.min]
    }
    return [th.max]
  }) as number[]
  const min = allThresholds.length > 0 ? Math.min(...allThresholds) : null
  const max = allThresholds.length > 0 ? Math.max(...allThresholds) : null
  return { min, max }
}

export const getAlertBounds = (
  fields: string[],
  min: number | null,
  max: number | null,
) => {
  const isBattery = fields.includes("power.battery.level")

  return isBattery
    ? { bounds: ["0", "100"] }
    : { bounds: [min !== null ? min : "", max !== null ? max : ""] }
}

export const getQueryParams = (
  entity: EntityType,
  fields: string[],
  dateRange: { from_date: string; to_date: string },
  orgId: number,
): DeviceParams | PowerlineParams => {
  switch (entity) {
    case "powerline":
      return {
        ...dateRange,
        field: fields,
        aggregate: ["mean"],
        aggregate_n: 360,
        group: ["powerline_id", "_field"],
        measurement: "telemetry",
        org_id: orgId,
      }
    case "group":
      return {}
    case "device":
    default:
      return {
        ...dateRange,
        field: fields,
        aggregate: ["mean"],
        aggregate_n: 360,
        group: ["id", "_field"],
      }
  }
}
