import { useMemo, useState, type FC } from "react"
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"

import Message from "widgets/common/Message"
import type { Powerline } from "types/powerlines.types"
import PowerlineCardSkeleton from "widgets/skeletons/PowerlineCardSkeleton"
import Pagination from "widgets/common/Pagination"
import { paginateList } from "helpers/utils/common"
import PowerlineCard from "./PowerlineCard"

interface PowerlineCardsSectionProps {
  powerlines: Powerline[]
  isFetching: boolean
  isLoading: boolean
  areMoreAvailable: boolean
  loadMore: () => void
  isError: boolean
}

const PAGE_LIMIT = 6

const PowerlineCardsSection: FC<PowerlineCardsSectionProps> = ({
  powerlines,
  isFetching,
  isLoading,
  areMoreAvailable,
  loadMore,
  isError,
}) => {
  const { t } = useTranslation()
  const [page, setPage] = useState(0)

  const pagedPowerlines = useMemo(
    () => paginateList(page, powerlines, PAGE_LIMIT),
    [page, powerlines],
  )

  return (
    <>
      {isLoading ? (
        <PowerlineCardSkeleton />
      ) : isError ? (
        <Message
          messageKey={"error.FETCHING_ITEM"}
          messageOptions={{
            item: t("powerlines.POWERLINES").toLowerCase(),
          }}
          type="error"
        />
      ) : pagedPowerlines.length ? (
        <Grid
          container
          spacing={2}
          alignItems="stretch"
          overflow={"hidden"}
          mb={"0.5rem"}
        >
          {pagedPowerlines.map((powerline: Powerline) => {
            return (
              <Grid item xs={12} sm={6} md={4} xl={4} key={powerline.id}>
                <PowerlineCard powerline={powerline} />
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <Message messageKey="powerlines.NO_POWERLINES" />
      )}
      <Pagination
        count={powerlines.length}
        page={page}
        setPage={setPage}
        limit={PAGE_LIMIT}
        areMoreAvailable={areMoreAvailable}
        loadMore={loadMore}
        isWaiting={isFetching}
      />
    </>
  )
}

export default PowerlineCardsSection
