import ConditionalInput from "widgets/common/ConditionalInput"
import type { InstallationDetailsForm } from "./helpers"

interface DetailsSectionTwoProps {
  defaultValues: InstallationDetailsForm
  isEditing: boolean
  isLoading: boolean
}

const DetailsSectionTwo = ({
  defaultValues,
  isEditing,
  isLoading,
}: DetailsSectionTwoProps) => {
  return (
    <>
      <ConditionalInput
        name="towerOneId"
        type="number"
        labelKey="device_installation.TOWER_N_ID"
        labelOptions={{ number: 1 }}
        displayedValue={defaultValues.towerOneId}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="towerOneAnchoringPointHeight"
        type="number"
        labelKey="device_installation.TOWER_N_ANCHORING_POINT_HEIGHT"
        labelOptions={{ number: 1 }}
        displayedValue={defaultValues.towerOneAnchoringPointHeight}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="towerOneGeolocation"
        labelKey="device_installation.TOWER_N_LOCATION"
        labelOptions={{ number: 1 }}
        displayedValue={defaultValues.towerOneGeolocation}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="towerTwoId"
        type="number"
        labelKey="device_installation.TOWER_N_ID"
        labelOptions={{ number: 2 }}
        displayedValue={defaultValues.towerTwoId}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="towerTwoAnchoringPointHeight"
        type="number"
        labelKey="device_installation.TOWER_N_ANCHORING_POINT_HEIGHT"
        labelOptions={{ number: 2 }}
        displayedValue={defaultValues.towerTwoAnchoringPointHeight}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="towerTwoGeolocation"
        labelKey="device_installation.TOWER_N_LOCATION"
        labelOptions={{ number: 2 }}
        displayedValue={defaultValues.towerTwoGeolocation}
        isEditing={isEditing}
        isLoading={isLoading}
      />
    </>
  )
}

export default DetailsSectionTwo
