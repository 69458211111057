import { useMemo } from "react"
import { useSelector } from "react-redux"

import type {
  DeviceGroup,
  IGroupsWRelsById,
  IGroupsWRelsByIdWStates,
} from "../../types/group.types"
import { formatGroupsById } from "../../helpers/formatters/dataFormatters"
import { formatEntitiesWithStates } from "../../helpers/formatters/alertSettingsFormatters"
import { selectCurrentOrgId } from "../../features/store/orgSlice"
import { useDeviceGroupsQuery } from "../../features/api"
import { DEVICE_GROUPS_TIME } from "../utils/constants"
import useAlertSettingsWStates from "./useAlertSettingsStates"

export interface IUseDeviceGroups {
  groups: DeviceGroup[] | undefined
  groupsWRelsById: IGroupsWRelsById
  groupsWRelsByIdWStates: IGroupsWRelsByIdWStates
  isSuccess: boolean
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  settingsIsFetching: boolean
  settingsIsLoading: boolean
}

const useDeviceGroups = (): IUseDeviceGroups => {
  const orgId = useSelector(selectCurrentOrgId)
  const {
    alertSettingsByGroupId,
    statesBySettingId,
    isFetching: settingsIsFetching,
    isLoading: settingsIsLoading,
  } = useAlertSettingsWStates()
  const {
    currentData: groups,
    isError,
    isSuccess,
    isFetching,
    isLoading,
  } = useDeviceGroupsQuery(
    {
      params: { describe_devices: false, org_id: orgId as number },
    },
    {
      skip: !orgId,
      pollingInterval: DEVICE_GROUPS_TIME,
    },
  )

  const groupsWRelsById = useMemo(() => formatGroupsById(groups), [groups])

  const groupsWRelsByIdWStates = useMemo(
    () =>
      formatEntitiesWithStates(
        groupsWRelsById,
        alertSettingsByGroupId,
        statesBySettingId,
      ) as IGroupsWRelsByIdWStates,
    [groupsWRelsById, alertSettingsByGroupId, statesBySettingId],
  )

  return {
    groups,
    groupsWRelsById,
    groupsWRelsByIdWStates,
    isError,
    isSuccess,
    isFetching,
    isLoading,
    settingsIsFetching,
    settingsIsLoading,
  }
}

export default useDeviceGroups
