import { useMemo, type FC } from "react"
import { Skeleton } from "@mui/material"

import type { Coords, LatLonRecord } from "types/geolocation.types"

import useDevices from "helpers/hooks/useDevices"
import { formatDeviceLatLonData, getMapCenterFromDeviceLocation } from "helpers/utils/map"
import DeviceMapMarkers from "./DeviceMapMarkers"
import Map from "./Map"

interface GeoSettingsPlotProps {
  data: LatLonRecord[] | Coords[] | null
  isLoading: boolean
  mapHeight: string
}

const GeoSettingsPlot: FC<GeoSettingsPlotProps> = ({ data, isLoading, mapHeight }) => {
  const { devsWRelsByIdWStates } = useDevices()
  const formattedLocationData = useMemo(
    () => data && formatDeviceLatLonData(data, devsWRelsByIdWStates),
    [data, devsWRelsByIdWStates],
  )
  const center = useMemo(
    () => getMapCenterFromDeviceLocation(formattedLocationData),
    [formattedLocationData],
  )

  if (isLoading || !formattedLocationData) {
    return <Skeleton variant="rectangular" height={mapHeight} />
  }

  return (
    <>
      {formattedLocationData && formattedLocationData.length > 0 && (
        <Map center={center} mapHeight={mapHeight}>
          <DeviceMapMarkers devices={formattedLocationData} />
        </Map>
      )}
    </>
  )
}

export default GeoSettingsPlot
