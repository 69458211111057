import type { ReactNode } from "react"
import type { TFunction } from "i18next"
import { Stack, Typography } from "@mui/material"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import LoopIcon from "@mui/icons-material/Loop"
import type { Dictionary } from "lodash"
import fp from "lodash/fp"
import type { MUISortOptions } from "mui-datatables"
import WarningIcon from "@mui/icons-material/Warning"
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff"
import AdjustIcon from "@mui/icons-material/Adjust"

import type {
  AlertSettingParam,
  AlertSettingStatesById,
  AlertSettingState,
  AlertSettingType,
  AlertSettingUpsertForm,
  AlertsRes,
  AlertsTableRow,
  AlertState,
  EventDescription,
  ThresholdsField,
  AlertSetting,
  AlertSettingParamSchema,
  AlertSettingConfigThreshold,
  AlertSettingConfig,
  Alert,
  AlertSettingAccordion,
  PowerlineAlertSetting,
} from "types/alerts.types"
import type { TelemetryOptionType } from "types/telemetries.types"
import type { InputRulesType } from "widgets/common/ControlledInput"
import type { Powerline } from "types/powerlines.types"
import type { EntityType } from "types/common.types"

import { formatDateFromMillis } from "helpers/utils/dateUtils"
import CustomLink from "widgets/common/CustomLink"
import IconTextStack from "widgets/common/IconTextStack"
import EntityTag from "widgets/common/EntityTag"
import { defaultSort } from "./tables"

interface Iname {
  name: string
}

export const isTriggeredState = (state: AlertSettingState | undefined): boolean => {
  const triggered = fp.getOr(false, "triggered", state)
  const silenced = fp.getOr(false, "silenced", state)
  return triggered && !silenced
}

export const hasOrgTriggeredAlerts = (
  orgActiveDevicesAlertSettings: AlertSetting[] | undefined,
  statesBySettingId: { [key: string]: AlertSettingState },
): boolean => {
  return fp.flow(
    fp.defaultTo([] as AlertSetting[]),
    fp.filter((setting: AlertSetting) => isTriggeredState(statesBySettingId[setting.id])),
    fp.negate(fp.isEmpty),
  )(orgActiveDevicesAlertSettings)
}

export const hasTriggeredAlerts = (states: AlertSettingStatesById) =>
  states && Object.values(states).some((state) => isTriggeredState(state))

export const hasDeviceTriggeredAlerts = (
  states: AlertSettingStatesById,
  orgAlertsBySettingId: { [key: string]: AlertSetting },
) => {
  return fp.flow(
    fp.toPairs,
    fp.some(
      ([settingId, settingState]: [string, AlertSettingState]) =>
        isTriggeredState(settingState) &&
        Boolean(orgAlertsBySettingId[settingId]?.config?.active),
    ),
  )(states)
}

export const getAlertSettingParamRules = (param: AlertSettingParam, t: TFunction) => {
  const message = t("generic.FIELD_REQUIRED") || ""
  let result: InputRulesType = { required: param?.required ? message : false }
  if (!param.schema || !("type" in param.schema) || param.schema.type !== "number") {
    return result
  }
  const schema = param.schema
  if (schema.minimum === 0) {
    result = {
      ...result,
      min: {
        value: schema.minimum,
        message: t("generic.FIELD_MIN_VALUE", { minValue: schema.minimum }),
      },
    }
  }

  return result
}

export const formatThresholds = (thresholds: ThresholdsField[]) => {
  const result = thresholds
    .map((threshold) => {
      switch (threshold.rangeCondition) {
        case "above":
          return {
            min: +threshold[threshold.rangeCondition],
            target: threshold.alertStatus.toLowerCase(),
          }
        case "below":
          return {
            max: +threshold[threshold.rangeCondition],
            target: threshold.alertStatus.toLowerCase(),
          }
        case "inside":
          return {
            min: +threshold[threshold.rangeCondition].from,
            max: +threshold[threshold.rangeCondition].to,
            target: threshold.alertStatus.toLowerCase(),
          }
        case "outside":
          return {
            max: +threshold[threshold.rangeCondition].from,
            min: +threshold[threshold.rangeCondition].to,
            target: threshold.alertStatus.toLowerCase(),
          }
        default:
          return null
      }
    })
    .filter((threshold) => threshold !== null && threshold !== undefined)
  return result
}

export const reverseFormatThresholds = (thresholds: AlertSettingConfigThreshold[]) => {
  const result = thresholds.map((threshold) => {
    const above = threshold.max === undefined && threshold.min !== undefined
    const below = threshold.max !== undefined && threshold.min === undefined
    const inside =
      threshold.min !== undefined &&
      threshold.max !== undefined &&
      threshold.min < threshold.max

    if (above) {
      return {
        rangeCondition: "above",
        above: threshold.min?.toString(),
        alertStatus: threshold.target.toUpperCase(),
      }
    } else if (below) {
      return {
        rangeCondition: "below",
        below: threshold.max?.toString(),
        alertStatus: threshold.target.toUpperCase(),
      }
    } else if (inside) {
      return {
        rangeCondition: "inside",
        inside: {
          from: threshold.min?.toString(),
          to: threshold.max?.toString(),
        },
        alertStatus: threshold.target.toUpperCase(),
      }
    } else {
      return {
        rangeCondition: "outside",
        outside: {
          from: threshold.max?.toString(),
          to: threshold.min?.toString(),
        },
        alertStatus: threshold.target.toUpperCase(),
      }
    }
  })
  return result
}

export const formatMutationParams = (
  data: AlertSettingUpsertForm,
  settingType?: AlertSettingType,
) => {
  const { type, thresholds, fields, ...rest } = data

  // Aux functions
  const formatSchemasById = fp.flow(
    fp.flatMap(({ id, schema }) => (schema ? [[id, schema]] : [])),
    fp.fromPairs,
  )
  const tweakNumbers = (schemasById: { [key: string]: AlertSettingParamSchema }) =>
    fp.flow(
      fp.toPairs,
      fp.map(([key, value]) => {
        const schema = schemasById[key]
        if (schema && "type" in schema && schema.type === "number") {
          return [key, Number(value)]
        }
        return [key, value]
      }),
      fp.fromPairs,
    )

  const typedThresholds = thresholds as ThresholdsField[]
  const typedTelemetries = fields as TelemetryOptionType[]
  const formattedTelemetries =
    typedTelemetries && typedTelemetries.map((telemetry) => telemetry.value)
  const formattedThresholds = typedThresholds && formatThresholds(typedThresholds)

  const schemasById = formatSchemasById(settingType?.params)
  const formattedConfig = tweakNumbers(schemasById)(rest)

  return {
    config: {
      ...formattedConfig,
      ...(formattedThresholds && {
        thresholds: formattedThresholds,
      }),
      ...(formattedTelemetries && {
        fields: formattedTelemetries,
      }),
    } as AlertSettingConfig,
    type,
  }
}

const getEventDescription = (
  triggered: boolean | undefined,
  resolved: boolean | undefined,
): EventDescription => {
  switch (true) {
    case triggered:
      return {
        icon: <ErrorOutlineIcon color="error" fontSize="small" />,
        description: "alerts.EVENT_TRIGGERED",
      }
    case resolved:
      return {
        icon: <CheckCircleOutlineIcon color="success" fontSize="small" />,
        description: "alerts.EVENT_RESOLVED",
      }
    default:
      return {
        icon: <LoopIcon color="warning" fontSize="small" />,
        description: "alerts.EVENT_RENOTIFICATION",
      }
  }
}

export const getEventsTableRows = (alerts: AlertsRes, t: TFunction) => {
  return alerts?.map((alert) => {
    const { icon, description } = getEventDescription(alert.triggered, alert.resolved)
    return {
      eventDescription: {
        icon,
        description: t(description, { alertName: alert.alert_name }),
      },
      timestamp: formatDateFromMillis(alert.timestamp),
    }
  })
}

export const getEntityHref = (
  entityType: EntityType,
  entityId: number,
  baseOrgURL: string,
) => {
  switch (entityType) {
    case "powerline":
      return `${baseOrgURL}/powerlines/${entityId}`
    case "group":
      return `${baseOrgURL}/device-groups/${entityId}`
    case "device":
    default:
      return `${baseOrgURL}/devices/${entityId}`
  }
}

export const getAlertsTableColumns = (
  baseOrgURL: string,
  devicesIds: number[] | undefined,
  groupId: number | undefined,
  t: TFunction,
) => {
  return [
    {
      name: "entityType",
      label: t("alerts.ENTITY_TYPE"),
      options: {
        filter: false,
        sort: true,
        display: !(devicesIds && !groupId),
        customBodyRender: (value: EntityType) => <EntityTag entity={value} />,
      },
    },
    {
      name: "entity",
      label: t("alerts.ENTITY"),
      options: {
        filter: false,
        sort: true,
        display: !(devicesIds && !groupId),
        setCellProps: () => ({ style: { minWidth: "200px" } }),
        customBodyRender: (value: {
          entityType: EntityType
          entityId: number
          entityName: string
        }) => {
          const href = getEntityHref(value.entityType, value.entityId, baseOrgURL)
          return (
            <Stack direction={"column"}>
              <Stack direction={"row"} flexWrap={"wrap"}>
                <Typography color="text.secondary">
                  {`${t("generic.NAME")}: `}&nbsp;
                </Typography>
                <CustomLink href={href} hover bold>
                  {value.entityName}
                </CustomLink>
              </Stack>
              <Stack direction={"row"} gap={1}>
                <Typography color="text.secondary" flexWrap={"wrap"}>
                  {`${t("generic.ID")}:`}
                </Typography>
                <CustomLink href={href} hover bold>
                  {value.entityId}
                </CustomLink>
              </Stack>
            </Stack>
          )
        },
        sortCompare: (order: MUISortOptions["direction"]) =>
          defaultSort(order, "entityName"),
      },
    },
    {
      name: "eventDescription",
      label: t("alerts.EVENT_DESCRIPTION"),
      options: {
        filter: false,
        sort: true,
        display: true,
        setCellProps: () => ({ style: { minWidth: "250px" } }),
        customBodyRender: (value: {
          description: string
          icon: ReactNode
          eventName: string
        }) => {
          return value.description ? (
            <IconTextStack icon={value.icon} text={value.description} />
          ) : (
            <Typography>{value.eventName}</Typography>
          )
        },
        sortCompare: (order: MUISortOptions["direction"]) =>
          defaultSort(order, "description"),
      },
    },
    {
      name: "timestamp",
      label: t("generic.TIMESTAMP"),
      options: {
        setCellProps: () => ({ style: { minWidth: "150px" } }),
        filter: false,
        sort: true,
        display: true,
      },
    },
  ]
}

export const getEntityValues = (
  alert: Alert,
  powerline: Powerline | undefined,
): {
  entityId: number
  entityName: string
  entityType: EntityType
} => {
  if (powerline) {
    const entityId = powerline.id
    const entityName = powerline.name
    const entityType = "powerline"
    return { entityId, entityName, entityType }
  } else if (alert.less_id) {
    const entityId = alert.less_id
    const entityName = alert.device_name
    const entityType = "device"
    return { entityId, entityName, entityType }
  } else {
    const entityId = alert.group_id as number
    const entityName = alert.group_name as string
    const entityType = "group"
    return { entityId, entityName, entityType }
  }
}

export const getAlertsTableRows = (
  alerts: AlertsRes,
  powerlineIdByLessId: { [key: number]: number },
  powerlinesById: { [key: number]: Powerline },
  t: TFunction,
) => {
  return alerts?.map((alert) => {
    const powerline =
      (alert.less_id &&
        powerlineIdByLessId[alert.less_id] &&
        powerlinesById[powerlineIdByLessId[alert.less_id]]) ||
      undefined

    const { entityId, entityName, entityType } = getEntityValues(alert, powerline)
    const { icon, description } = getEventDescription(alert.triggered, alert.resolved)
    const timestamp = formatDateFromMillis(alert.timestamp)
    return {
      entityType,
      entity: { entityType, entityId, entityName },
      eventDescription: {
        icon,
        description: t(description, { alertName: alert.alert_name }),
      },
      timestamp,
    }
  })
}

export const getColsByName = fp.flow(
  (data) => fp.zip(fp.range(0, data.length), data),
  fp.map(([i, column]) => [(column as Iname).name, i]),
  fp.fromPairs,
)

export const getAlertState = (alertState: AlertSettingState): AlertState => {
  return alertState.silenced && alertState.triggered
    ? "silenced"
    : alertState.triggered
    ? "open"
    : "notTriggered"
}

export const alertsCustomSearch = (
  searchQuery: string,
  currentRow: AlertsTableRow,
  columnsIndexes: Dictionary<number>,
  t: TFunction,
) => {
  const searchText = searchQuery.toLowerCase()
  const entityType = currentRow[columnsIndexes.entityType] as EntityType
  const entity = currentRow[columnsIndexes.entity] as {
    entityId: number
    entityName: string
    entityType: EntityType
  }
  const eventDescription = currentRow[columnsIndexes.eventDescription] as {
    description: string
    icon: JSX.Element
  }
  const searchInEntityType = t(`entities.${entityType.toUpperCase()}`)
    .toLowerCase()
    .includes(searchText)
  const searchInEntity =
    entity.entityId.toString().includes(searchText) ||
    entity.entityName.toLowerCase().includes(searchText)
  const searchInEventDescription = eventDescription.description
    .toLowerCase()
    .includes(searchText)

  const isFound = currentRow.some(
    (col) =>
      col.toString().toLowerCase().includes(searchText) ||
      searchInEntityType ||
      searchInEntity ||
      searchInEventDescription,
  )
  return isFound
}

export const formatAlertsTableCsv = (
  data: { index: number; data: AlertsTableRow }[],
  columnsIndexes: Dictionary<number>,
  t: TFunction,
) => {
  return data?.map((row, index: number) => ({
    index,
    data: row?.data?.map((field: any, index) => {
      // field can be any of the types of AlertsTableRows
      // array order comes from columns definition
      switch (index) {
        case columnsIndexes.entityType:
          return t(`entities.${field.toUpperCase()}`)
        case columnsIndexes.entity:
          return `${field.entityName} - ${field.entityId}`
        case columnsIndexes.eventDescription:
          return field.description
        case columnsIndexes.viewed:
          return field ? t("alerts.VIEWED") : t("alerts.NOT_VIEWED")
        default:
          return field
      }
    }),
  }))
}

export const getPowerlineIdsFromEvents = (
  events: Alert[] | undefined,
  powerlineIdByLessId: { [key: number]: number },
): number[] =>
  fp.flow(
    fp.filter(
      (event: Alert) => !!event.less_id && fp.has(event.less_id, powerlineIdByLessId),
    ),
    fp.map((event) => powerlineIdByLessId[Number(event.less_id)]),
    fp.uniq,
  )(events)

export const getAlertStateIcon = (alertState: AlertState): JSX.Element => {
  switch (alertState) {
    case "open":
      return <WarningIcon color="error" fontSize="small" />
    case "silenced":
      return <NotificationsOffIcon color="warning" fontSize="small" />
    case "notTriggered":
    default:
      return <AdjustIcon color="action" fontSize="small" />
  }
}

export const getAlertLinkHref = (
  alert: AlertSettingAccordion,
  baseOrgURL: string,
): string => {
  switch (alert.entity) {
    case "powerline":
      return `${baseOrgURL}/powerlines/${alert.entity_id}`
    case "group":
      return `${baseOrgURL}/device-groups/${alert.entity_id}`
    case "device":
    default:
      return `${baseOrgURL}/devices/${alert.entity_id}`
  }
}

export const getPowerlineIdsFromSettings = (
  orgAlertSettings: PowerlineAlertSetting[],
): number[] =>
  fp.map((setting: PowerlineAlertSetting) => setting.entity_id)(orgAlertSettings)
