import { useCallback, useMemo } from "react"
import fp from "lodash/fp"

import type { DateReference } from "types/date.types"
import type { EntityType } from "types/common.types"
import type { AlertState } from "types/alerts.types"

const useSelectOptions = () => {
  const dateRefOptions = useMemo(
    () => [
      {
        value: "lastTrigger",
        time: { timeAmount: 0, timeUnit: "" },
        label: "dates.LAST_TRIGGER",
      },
      {
        value: "lastHour",
        time: { timeAmount: 1, timeUnit: "hours" },
        label: "dates.LAST_HOUR",
      },
      {
        value: "lastThreeHours",
        time: { timeAmount: 3, timeUnit: "hours" },
        label: "dates.LAST_3_HOURS",
      },
      {
        value: "lastThreeHoursPlusOneHour",
        time: { timeAmount: 3, timeUnit: "hours" },
        forecastTime: { timeAmount: 1, timeUnit: "hours" },
        label: "dates.LAST_3_HOURS_PLUS_1_HOUR",
      },
      {
        value: "lastTwelveHours",
        time: { timeAmount: 12, timeUnit: "hours" },
        label: "dates.LAST_12_HOURS",
      },
      {
        value: "lastTwelveHoursPlusThreeHours",
        time: { timeAmount: 12, timeUnit: "hours" },
        forecastTime: { timeAmount: 3, timeUnit: "hours" },
        label: "dates.LAST_12_HOURS_PLUS_3_HOURS",
      },
      {
        value: "lastDay",
        time: { timeAmount: 1, timeUnit: "days" },
        label: "dates.LAST_DAY",
      },
      {
        value: "lastDayPlusSixHours",
        time: { timeAmount: 1, timeUnit: "days" },
        forecastTime: { timeAmount: 6, timeUnit: "hours" },
        label: "dates.LAST_DAY_PLUS_6_HOURS",
      },
      {
        value: "lastWeek",
        time: { timeAmount: 7, timeUnit: "days" },
        label: "dates.LAST_WEEK",
      },
      {
        value: "lastWeekPlusTwoDays",
        time: { timeAmount: 7, timeUnit: "days" },
        forecastTime: { timeAmount: 2, timeUnit: "days" },
        label: "dates.LAST_WEEK_PLUS_2_DAYS",
      },
      {
        value: "lastMonth",
        time: { timeAmount: 1, timeUnit: "months" },
        label: "dates.LAST_MONTH",
      },
      {
        value: "lastQuarter",
        time: { timeAmount: 3, timeUnit: "months" },
        label: "dates.LAST_QUARTER",
      },
      {
        value: "lastYear",
        time: { timeAmount: 1, timeUnit: "years" },
        label: "dates.LAST_YEAR",
      },
      {
        value: "custom",
        time: { timeAmount: 0, timeUnit: "" },
        label: "dates.CUSTOM",
      },
    ],
    [],
  )

  const deviceSelectOptions = useMemo(
    () =>
      dateRefOptions.filter((opt) => opt.value !== "lastTrigger" && !opt.forecastTime),
    [dateRefOptions],
  )
  const powerlineSelectOptions = useMemo(
    () => dateRefOptions.filter((opt) => opt.value !== "lastTrigger"),
    [dateRefOptions],
  )
  const getSelectOptionsByEntity = useCallback(
    (entity: EntityType) => {
      switch (entity) {
        case "powerline":
          return powerlineSelectOptions
        default:
          return deviceSelectOptions
      }
    },
    [deviceSelectOptions, powerlineSelectOptions],
  )

  const triggeredAccordionSelectOptions = useMemo(
    () => dateRefOptions.filter((opt) => opt.value !== "custom" && !opt.forecastTime),
    [dateRefOptions],
  )
  const notTriggeredAccordionSelectOptions = useMemo(
    () =>
      dateRefOptions.filter(
        (opt) =>
          opt.value !== "custom" && opt.value !== "lastTrigger" && !opt.forecastTime,
      ),
    [dateRefOptions],
  )
  const getSelectOptionsByAlertState = useCallback(
    (alertState: AlertState) => {
      switch (alertState) {
        case "notTriggered":
          return notTriggeredAccordionSelectOptions
        default:
          return triggeredAccordionSelectOptions
      }
    },
    [notTriggeredAccordionSelectOptions, triggeredAccordionSelectOptions],
  )

  // nit: Could do with the same format as selectOptions
  const dateReferences: { [key: string]: DateReference } = useMemo(() => {
    const references: DateReference[] = dateRefOptions.map(
      ({ value, time, forecastTime }): DateReference => ({
        name: value,
        timeAmount: time.timeAmount,
        timeUnit: time.timeUnit,
        ...(forecastTime && {
          forecastTimeAmount: forecastTime.timeAmount,
          forecastTimeUnit: forecastTime.timeUnit,
        }),
      }),
    )
    return fp.indexBy(({ name }: DateReference) => name)(references)
  }, [dateRefOptions])

  const deviceDateReferences = useMemo(
    () =>
      fp.pickBy(
        (dr: DateReference) =>
          dr.name !== "lastTrigger" && !dr.forecastTimeAmount && !dr.forecastTimeUnit,
      )(dateReferences),
    [dateReferences],
  )
  const powerlineDateReferences = useMemo(
    () => fp.pickBy((dr: DateReference) => dr.name !== "lastTrigger")(dateReferences),
    [dateReferences],
  )
  const getDateReferencesByEntity = useCallback(
    (entity: EntityType) => {
      switch (entity) {
        case "powerline":
          return powerlineDateReferences
        default:
          return deviceDateReferences
      }
    },
    [deviceDateReferences, powerlineDateReferences],
  )

  const triggeredAccordionDateReferences = useMemo(
    () =>
      fp.pickBy(
        (dr: DateReference) =>
          dr.name !== "custom" && !dr.forecastTimeAmount && !dr.forecastTimeUnit,
      )(dateReferences),
    [dateReferences],
  )
  const notTriggeredAccordionDateReferences = useMemo(
    () =>
      fp.pickBy(
        (dr: DateReference) =>
          dr.name !== "custom" &&
          dr.name !== "lastTrigger" &&
          !dr.forecastTimeAmount &&
          !dr.forecastTimeUnit,
      )(dateReferences),
    [dateReferences],
  )
  const getDateReferencesByAlertState = useCallback(
    (alertState: AlertState) => {
      switch (alertState) {
        case "notTriggered":
          return notTriggeredAccordionDateReferences
        default:
          return triggeredAccordionDateReferences
      }
    },
    [notTriggeredAccordionDateReferences, triggeredAccordionDateReferences],
  )

  return {
    dateRefOptions,
    deviceSelectOptions,
    powerlineSelectOptions,
    getSelectOptionsByEntity,
    triggeredAccordionSelectOptions,
    notTriggeredAccordionSelectOptions,
    getSelectOptionsByAlertState,
    dateReferences,
    deviceDateReferences,
    powerlineDateReferences,
    getDateReferencesByEntity,
    triggeredAccordionDateReferences,
    notTriggeredAccordionDateReferences,
    getDateReferencesByAlertState,
  }
}

export default useSelectOptions
