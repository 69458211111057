import fp from "lodash/fp"
import { DateTime } from "luxon"

import type { Dispatch } from "@reduxjs/toolkit"
import type { SetURLSearchParams } from "react-router-dom"

import type {
  DateRange,
  DateRangeDateTime,
  DateRangeParams,
  DateReference,
} from "types/date.types"
import type { MultipleDateRangeAction } from "helpers/reducers/dateRangeReducer"
import type { EntityType } from "types/common.types"

import { formatISOToDateTime } from "helpers/utils/dateUtils"

interface DispatchCombo {
  setSearchParams: SetURLSearchParams
  dispatchDateRange: (dateRange: DateRange) => void
}

export const formatDateRange = (dateRange: DateRange | null): DateRangeDateTime | null =>
  dateRange
    ? {
        range: dateRange.range,
        fromDate: DateTime.fromISO(dateRange.fromDate),
        toDate: DateTime.fromISO(dateRange.toDate),
      }
    : null

export const getCustomDateRangeFromParams = (
  dateRangeParams: DateRangeParams,
  memoryDateRange: DateRange | null,
): DateRangeDateTime | null => {
  // The dateRangeParams have the values from the user input
  const { range, fromDate, toDate } = dateRangeParams
  const isCustom = range === "custom" && fromDate && toDate
  const isAlreadyInMemory =
    memoryDateRange &&
    memoryDateRange.range === "custom" &&
    fp.isEqual(memoryDateRange, dateRangeParams)
  if (!isCustom || isAlreadyInMemory) {
    return null
  }
  const dateRange = {
    fromDate: DateTime.fromISO(fromDate),
    toDate: DateTime.fromISO(toDate),
    range: "custom",
  }
  return dateRange
}

export const getRangeDateRangeFromParams = (
  range: string | null,
  dateReferences: { [key: string]: DateReference },
  memoryRange: string | undefined,
): DateRangeDateTime | null => {
  const isNotRange = !range || range === "custom"
  const isAlreadyInMemory =
    memoryRange && memoryRange !== "custom" && memoryRange === range
  if (isNotRange || isAlreadyInMemory) return null

  const dateNow = DateTime.now()
  const dateReferenceFromParams = dateReferences[range]
  const rangeFrom =
    dateReferenceFromParams &&
    dateNow.minus({
      [dateReferenceFromParams.timeUnit]: dateReferenceFromParams.timeAmount,
    })
  const rangeTo =
    dateReferenceFromParams?.forecastTimeAmount &&
    dateReferenceFromParams?.forecastTimeUnit
      ? dateNow.plus({
          [dateReferenceFromParams.forecastTimeUnit]:
            dateReferenceFromParams.forecastTimeAmount,
        })
      : dateNow

  const usefulRange = rangeFrom && rangeTo
  if (!usefulRange) {
    return null
  }
  const dateRange = {
    fromDate: rangeFrom,
    toDate: rangeTo,
    range,
  }
  return dateRange
}

const getDefaultDateRange = (entity: EntityType): DateRangeDateTime => {
  const dateNow = DateTime.now()
  switch (entity) {
    case "powerline":
      return {
        fromDate: dateNow.minus({
          days: 1,
        }),
        toDate: dateNow.plus({ hours: 6 }),
        range: "lastDayPlusSixHours",
      }
    default:
      return {
        fromDate: dateNow.minus({
          days: 7,
        }),
        toDate: dateNow,
        range: "lastWeek",
      }
  }
}

export const getDateRangeOrDefault = (
  dateRange: DateRange | null,
  entity: EntityType,
): DateRangeDateTime => {
  if (!dateRange) {
    return getDefaultDateRange(entity)
  }

  return {
    fromDate: formatISOToDateTime(dateRange.fromDate),
    toDate: formatISOToDateTime(dateRange.toDate),
    range: dateRange.range,
  }
}

export const setInitialDateRange = (
  initialDateRange: DateRangeDateTime,
  dispatchCombo: DispatchCombo,
): void => {
  const { range, fromDate, toDate } = initialDateRange
  const dateRange = {
    range,
    fromDate: String(fromDate.toISO()),
    toDate: String(toDate.toISO()),
  }

  const updater = genUpdater(dispatchCombo)
  updater(dateRange)
}

export const setRangeDateRange = (
  dateReferenceName: string,
  dateReferences: { [key: string]: DateReference },
  currentDateRange: DateRangeDateTime,
  dispatchCombo: DispatchCombo,
): void => {
  const updater = genUpdater(dispatchCombo)
  if (dateReferenceName === "custom") {
    const fromDate = currentDateRange.fromDate.toISO() as string
    const toDate = currentDateRange.toDate.toISO() as string
    updater({ fromDate, toDate, range: "custom" })
    return
  }
  const dateReference = dateReferences[dateReferenceName]
  const dateNow = DateTime.now()
  const fromDate = dateNow
    .minus({ [dateReference.timeUnit]: dateReference.timeAmount })
    .toISO() as string
  const toDate =
    dateReference.forecastTimeAmount && dateReference.forecastTimeUnit
      ? (dateNow
          .plus({
            [dateReference.forecastTimeUnit]: dateReference.forecastTimeAmount,
          })
          .toISO() as string)
      : (dateNow.toISO() as string)
  const dateRange = { fromDate, toDate, range: dateReference.name }

  updater(dateRange)
}

export const setCustomDateRange = (
  fields: { [key: string]: DateTime | null },
  currentDateRange: DateRangeDateTime,
  dispatchCombo: DispatchCombo,
): void => {
  const dateRange = {
    range: "custom",
    fromDate: String(currentDateRange.fromDate.toISO()),
    toDate: String(currentDateRange.toDate.toISO()),
  }

  const fieldsToISO = fp.mapValues((value: DateTime) => value.toISO(), fields)
  const updatedDateRange = fp.merge(dateRange, fieldsToISO)

  const updater = genUpdater(dispatchCombo)
  updater(updatedDateRange)
}

type GenUpdater = (combo: DispatchCombo) => (dateRange: DateRange) => void
const genUpdater: GenUpdater =
  ({ setSearchParams, dispatchDateRange }) =>
  (dateRange) => {
    const { range } = dateRange
    const isCustom = range === "custom"
    const searchParams = isCustom ? dateRange : { range }

    setSearchParams(
      (prev: URLSearchParams) => {
        const pairs: [string, string][] = []
        prev.forEach((value: string, key: string) => {
          const isRangeFromDate = !isCustom && key === "fromDate"
          const isRangeToDate = !isCustom && key === "toDate"
          if (isRangeFromDate || isRangeToDate) return
          return pairs.push([key, value])
        })
        return { ...fp.fromPairs(pairs), ...searchParams }
      },
      { replace: true },
    )
    dispatchDateRange(dateRange)
  }

export const initializePicker = (
  searchParams: URLSearchParams,
  dateRange: DateRange | null,
  dateReferences: { [key: string]: DateReference },
  entity: EntityType,
): { initialDateRange: DateRangeDateTime; defaultDateReference: DateReference } => {
  const range = searchParams.get("range")
  const fromDate = searchParams.get("fromDate")
  const toDate = searchParams.get("toDate")
  const dateRangeParams = { range, fromDate, toDate }

  const customDateRangeParams = getCustomDateRangeFromParams(dateRangeParams, dateRange)
  const rangeDateRangeParams = getRangeDateRangeFromParams(
    dateRangeParams.range,
    dateReferences,
    dateRange?.range,
  )
  const memoryOrDefaultDateRange = getDateRangeOrDefault(dateRange, entity)
  const initialDateRange = customDateRangeParams
    ? customDateRangeParams
    : rangeDateRangeParams
    ? rangeDateRangeParams
    : memoryOrDefaultDateRange

  const defaultDateReference = dateReferences[initialDateRange.range]

  return {
    initialDateRange,
    defaultDateReference,
  }
}

export const setAccordionDateRange = (
  alertInfo: { id: number; triggeredAt: string | undefined },
  dateReferenceName: string,
  dateReferences: { [key: string]: DateReference },
  dispatchMultipleDateRange: Dispatch<MultipleDateRangeAction>,
): void => {
  const { id, triggeredAt } = alertInfo
  // When triggeredAt value is missing in a triggered alert
  // the lastTrigger option will show the last week
  // triggeredAt should not be missing
  const dateReference =
    dateReferenceName === "lastTrigger" && !triggeredAt
      ? dateReferences.lastWeek
      : dateReferences[dateReferenceName]
  const dateNow = DateTime.now()

  const triggeredRefDate =
    triggeredAt &&
    dateReferenceName === "lastTrigger" &&
    (formatISOToDateTime(triggeredAt).minus({ days: 7 }).toISO() as string)
  const fromDate = triggeredRefDate
    ? triggeredRefDate
    : (dateNow
        .minus({
          [dateReference.timeUnit]: dateReference.timeAmount,
        })
        .toISO() as string)
  const toDate = dateNow.toISO() as string

  dispatchMultipleDateRange({
    type: "UPDATE",
    payload: {
      [id]: { range: dateReferenceName, fromDate, toDate },
    },
  })
}
