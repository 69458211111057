import { useSelector } from "react-redux"

import { selectCurrentOrgId } from "features/store/orgSlice"
import PowerlinesContent from "widgets/powerlines/PowerlinesContent"

const Powerlines = () => {
  const orgId = useSelector(selectCurrentOrgId)
  return <PowerlinesContent key={orgId} />
}

export default Powerlines
