import { useMemo } from "react"
import { useSelector } from "react-redux"
import fp from "lodash/fp"

import type { DeviceAlertSetting, PowerlineAlertSetting } from "types/alerts.types"

import { useOrgAlertSettingsQuery } from "features/api"
import { selectCurrentOrgId } from "features/store/orgSlice"
import { formatSettingsById } from "helpers/formatters/alertSettingsFormatters"
import { ALERT_SETTINGS_TIME } from "helpers/utils/constants"

const useAlertSettings = () => {
  const orgId = useSelector(selectCurrentOrgId)
  const {
    currentData: orgAlertSettings,
    isLoading,
    isError,
  } = useOrgAlertSettingsQuery(
    {
      org_id: orgId as number,
    },
    {
      pollingInterval: ALERT_SETTINGS_TIME,
      skip: !orgId,
    },
  )

  const orgAlertsBySettingId = useMemo(
    () => formatSettingsById(orgAlertSettings),
    [orgAlertSettings],
  )

  const orgActiveDevicesAlertSettings = useMemo(
    () =>
      (orgAlertSettings || []).filter(
        (setting) => setting.entity === "device" && !!setting.config?.active,
      ) as DeviceAlertSetting[],
    [orgAlertSettings],
  )

  const orgActiveDevicesAlertSettingsById = useMemo(
    () => fp.indexBy("id", orgActiveDevicesAlertSettings),
    [orgActiveDevicesAlertSettings],
  )

  const orgActivePowerlinesAlertSettings = useMemo(
    () =>
      (orgAlertSettings || []).filter(
        (setting) => setting.entity === "powerline" && !!setting.config?.active,
      ) as PowerlineAlertSetting[],
    [orgAlertSettings],
  )

  const orgActivePowerlinesAlertSettingsById = useMemo(
    () => fp.indexBy("id", orgActivePowerlinesAlertSettings),
    [orgActivePowerlinesAlertSettings],
  )

  // Excludes group alert settings
  const orgActiveAlertSettings = useMemo(
    () => [...orgActiveDevicesAlertSettings, ...orgActivePowerlinesAlertSettings],
    [orgActivePowerlinesAlertSettings, orgActiveDevicesAlertSettings],
  )

  const orgActiveAlertSettingsById = useMemo(
    () => fp.indexBy("id", orgActiveAlertSettings),
    [orgActiveAlertSettings],
  )

  return {
    orgAlertSettings,
    orgActiveAlertSettings,
    orgActiveAlertSettingsById,
    orgActiveDevicesAlertSettings,
    orgActiveDevicesAlertSettingsById,
    orgActivePowerlinesAlertSettings,
    orgActivePowerlinesAlertSettingsById,
    orgAlertsBySettingId,
    isLoading,
    isError,
  }
}

export default useAlertSettings
