import { Grid, Typography } from "@mui/material"
import type { FC } from "react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import ControlledInput from "widgets/common/ControlledInput"
import type { DeviceLocationForm } from "widgets/device/settings/DeviceLocation"
import SkeletonWrapper from "widgets/common/SkeletonWrapper"

interface GeolocationInputProps {
  coords: DeviceLocationForm | null
  disabled: boolean
  isEditing: boolean
  isWaiting: boolean
  name: "lat" | "lon"
}

export const GeolocationInput: FC<GeolocationInputProps> = ({
  coords,
  disabled,
  isEditing,
  isWaiting,
  name,
}) => {
  const { t } = useTranslation()

  const getType = (name: string) => {
    if (name === "lat") {
      return "LATITUDE"
    }
    if (name === "lon") {
      return "LONGITUDE"
    }
    return ""
  }

  const rules = useMemo(
    () => ({
      required: t("generic.FIELD_REQUIRED"),
      pattern: {
        // optional minus sign, 3 digits with optionally n decimals
        value: /^-?\d{0,3}(\.\d+)?$/,
        message: t("generic.FIELD_INVALID_NUMBER"),
      },
    }),
    [t],
  )

  const inputProps = useMemo(
    () => ({
      maxLength: 21,
    }),
    [],
  )

  const type = useMemo(() => getType(name), [name])

  return (
    <Grid container spacing={4} mb={2} alignItems={"center"}>
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Typography>{t(`generic.${type}`)}</Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6} mb={isEditing ? -2 : 0}>
        {isEditing ? (
          <ControlledInput
            id={name}
            name={name}
            variant="outlined"
            size="small"
            placeholder={""}
            inputProps={inputProps}
            rules={rules}
            disabled={disabled}
          />
        ) : (
          <SkeletonWrapper isWaiting={isWaiting} width={"150px"}>
            <Typography color={"text.disabled"}>
              {coords?.lat && coords?.lon
                ? coords[name]
                : t(`device_settings.NO_${type}_SET`)}
            </Typography>
          </SkeletonWrapper>
        )}
      </Grid>
    </Grid>
  )
}
