import { useEffect, useMemo, useState } from "react"

import type { EntityType } from "types/common.types"
import type {
  DeviceParams,
  EntityTelemetry,
  PowerlineParams,
} from "types/telemetries.types"

import { useLazyDeviceTelemetryQuery, useLazyPowerlineTelemetryQuery } from "features/api"

interface useEntityTelemetryProps {
  entity: EntityType
  entityId: number
  params: DeviceParams | PowerlineParams
}

const useEntityTelemetry = ({ entity, entityId, params }: useEntityTelemetryProps) => {
  const [fetchDeviceTelemetryQuery, { isLoading: deviceTelemetryLoading }] =
    useLazyDeviceTelemetryQuery()
  const [fetchPowerlineTelemetryQuery, { isLoading: powerlineTelemetryLoading }] =
    useLazyPowerlineTelemetryQuery()
  const [data, setData] = useState<EntityTelemetry[]>([])

  useEffect(() => {
    switch (entity) {
      case "powerline":
        fetchPowerlineTelemetryQuery(
          {
            id: entityId,
            params: params as PowerlineParams,
          },
          true,
        )
          .unwrap()
          .then((res) => setData(res))
        break
      case "group":
        break
      case "device":
      default:
        fetchDeviceTelemetryQuery(
          {
            id: entityId,
            params: params as DeviceParams,
          },
          true,
        )
          .unwrap()
          .then((res) => setData(res))
    }
  }, [entity, entityId, fetchDeviceTelemetryQuery, fetchPowerlineTelemetryQuery, params])

  const isLoading = useMemo(() => {
    switch (entity) {
      case "powerline":
        return powerlineTelemetryLoading
      case "group":
        return false
      case "device":
      default:
        return deviceTelemetryLoading
    }
  }, [deviceTelemetryLoading, entity, powerlineTelemetryLoading])

  return {
    data,
    isLoading,
  }
}

export default useEntityTelemetry
