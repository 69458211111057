import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import type { DateRange } from "types/date.types"

import {
  selectAlertsTable,
  setAlertsTableRowsAmount,
  setAlertsTableSortOrder,
} from "features/store/tablesSlice"
import useEvents from "helpers/hooks/useEvents"
import useRoutes from "helpers/hooks/useRoutes"
import {
  alertsCustomSearch,
  formatAlertsTableCsv,
  getAlertsTableColumns,
  getAlertsTableRows,
  getColsByName,
  getPowerlineIdsFromEvents,
} from "helpers/utils/alerts"
import Spinner from "widgets/common/Spinner"
import GenericTable from "widgets/common/Table"
import { selectCurrentOrgName } from "features/store/orgSlice"
import useDevices from "helpers/hooks/useDevices"
import useDeviceGroups from "helpers/hooks/useDeviceGroups"
import { formatFilename } from "helpers/utils/common"
import useSelectedPowerlines from "helpers/hooks/powerlines/useSelectedPowerlines"
import { isVirtualDevice } from "helpers/utils/devices"

interface AlertsTableProps {
  dateRange: DateRange | null
  isDateRangeValid: boolean
  devicesIds?: number[]
  groupId?: number
}

function AlertsTable({
  dateRange,
  isDateRangeValid,
  devicesIds,
  groupId,
}: AlertsTableProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { baseOrgURL } = useRoutes()
  const { devsWRelsById, powerlineIdByLessId, isLoading: devicesLoading } = useDevices()
  const { groupsWRelsById } = useDeviceGroups()
  const orgName = useSelector(selectCurrentOrgName)
  const alertsTable = useSelector(selectAlertsTable)
  const { events, isLoading: eventsLoading } = useEvents({
    fromDate: dateRange?.fromDate,
    toDate: dateRange?.toDate,
    isDateRangeValid,
    devicesIds,
    groupId,
  })
  const powerlineIds = useMemo(
    () => getPowerlineIdsFromEvents(events, powerlineIdByLessId),
    [events, powerlineIdByLessId],
  )
  const { powerlinesById, powerlinesLoading } = useSelectedPowerlines({ powerlineIds })

  const fileEntityName = useMemo(() => {
    if (groupId) {
      const groupName = groupsWRelsById[groupId].group.name
      return `${groupName}_`
    } else if (
      devicesIds !== undefined &&
      powerlineIdByLessId &&
      powerlineIdByLessId[devicesIds[0]] &&
      powerlinesById[powerlineIdByLessId[devicesIds[0]]]
    ) {
      const powerline = powerlinesById[powerlineIdByLessId[devicesIds[0]]]
      const powerlineName = powerline.name
      return `${powerlineName}_`
    } else if (
      devicesIds !== undefined &&
      devsWRelsById &&
      devicesIds[0] &&
      devsWRelsById[devicesIds[0]] &&
      !isVirtualDevice(devsWRelsById[devicesIds[0]].device.device_type)
    ) {
      const device = devsWRelsById[devicesIds[0]]
      const deviceName = device.device.name
      return `${deviceName}_`
    } else {
      return ""
    }
  }, [
    devicesIds,
    devsWRelsById,
    groupId,
    groupsWRelsById,
    powerlineIdByLessId,
    powerlinesById,
  ])

  const columns = useMemo(
    () => getAlertsTableColumns(baseOrgURL, devicesIds, groupId, t),
    [baseOrgURL, devicesIds, groupId, t],
  )

  const { rows, columnsIndexes } = useMemo(() => {
    const alertList =
      events && powerlineIdByLessId && powerlinesById
        ? getAlertsTableRows(events, powerlineIdByLessId, powerlinesById, t)
        : []
    return { rows: alertList, columnsIndexes: getColsByName(columns) }
  }, [events, powerlineIdByLessId, powerlinesById, t, columns])

  return (
    <>
      {eventsLoading || powerlinesLoading || devicesLoading ? (
        <Spinner />
      ) : (
        <>
          {rows && (
            <GenericTable
              rows={rows}
              columns={columns}
              options={{
                customSearch: (searchQuery, currentRow, _columns) => {
                  return alertsCustomSearch(searchQuery, currentRow, columnsIndexes, t)
                },
                downloadOptions: {
                  filename: formatFilename(
                    `${orgName}_${fileEntityName}${t("alerts.EVENTS_LOG")}`,
                    "csv",
                  ),
                },
                onDownload: (buildHead, buildBody, columns, data) => {
                  const formattedData = formatAlertsTableCsv(data, columnsIndexes, t)
                  return `${buildHead(columns)}${buildBody(formattedData)}`.trim()
                },

                /* --- Persistence values --- */
                rowsPerPage: alertsTable?.qtyRows,
                sortOrder: alertsTable?.sortOrder,
                onChangeRowsPerPage: (numberOfRows) => {
                  dispatch(setAlertsTableRowsAmount(numberOfRows))
                },
                onColumnSortChange: (changedColumn, direction) => {
                  dispatch(setAlertsTableSortOrder({ name: changedColumn, direction }))
                },
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default AlertsTable
