import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Box, useTheme } from "@mui/material"
import type { MUIDataTableColumn } from "mui-datatables"

import type { UsersLog } from "types/users.types"
import type { DateRange } from "types/date.types"

import { useOrgUsersLogsQuery } from "features/api"
import { selectCurrentOrgId } from "features/store/orgSlice"
import useDateRange from "helpers/hooks/useDateRange"
import { getColsByName } from "helpers/utils/alerts"
import CustomTableFooter from "widgets/common/CustomTableFooter"
import SkeletonWrapper from "widgets/common/SkeletonWrapper"
import GenericTable from "widgets/common/Table"
import DateRangePicker from "widgets/dates/DateRangePicker"
import {
  formatAuditTrailTableCsv,
  getAuditTrailTableColumns,
  updateTableData,
} from "./helpers"

const AuditTrailTable = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const orgId = useSelector(selectCurrentOrgId)
  const { auditTrailDateRange, dispatchAuditTrailDateRange, isAuditTrailDateRangeValid } =
    useDateRange()
  const [results, setResults] = useState<UsersLog[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [cursor, setCursor] = useState<string | undefined>()

  const { data, isLoading, isFetching } = useOrgUsersLogsQuery(
    {
      org_id: Number(orgId),
      from_date: String(auditTrailDateRange?.fromDate),
      to_date: String(auditTrailDateRange?.toDate),
      cursor,
      limit: 100,
    },
    {
      skip:
        !auditTrailDateRange?.fromDate ||
        !auditTrailDateRange?.toDate ||
        !isAuditTrailDateRangeValid ||
        !orgId,
    },
  )

  useEffect(() => {
    updateTableData(data, results, setResults)
    //Intentionally re-evaluating only on "data" change

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const columns: MUIDataTableColumn[] = useMemo(() => getAuditTrailTableColumns(t), [t])

  const { rows, columnsIndexes } = useMemo(() => {
    return {
      rows: results,
      columnsIndexes: getColsByName(columns),
    }
  }, [results, columns])

  const dispatchDateRange = useCallback(
    (dateRange: DateRange) => {
      setResults([])
      setPage(0)
      setCursor(undefined)
      dispatchAuditTrailDateRange(dateRange)
    },
    [dispatchAuditTrailDateRange],
  )

  useEffect(() => {
    // This table date range is not refreshing with an interval
    // Need to reset audit trail date range to null on unmount
    // This is for the date range to be recalculated on mount
    return () => {
      dispatchAuditTrailDateRange(null)
    }
  }, [dispatchAuditTrailDateRange])

  return (
    <>
      <Box
        sx={{
          p: "0 0 0.75rem 0",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <DateRangePicker
          dateRange={auditTrailDateRange}
          dispatchDateRange={dispatchDateRange}
          isFetching={isFetching}
        />
      </Box>
      <SkeletonWrapper isWaiting={isLoading} height={540} width={"100vw"}>
        <GenericTable
          rows={rows}
          columns={columns}
          options={{
            page,
            rowsPerPage,
            onDownload: (buildHead, buildBody, columns, data) => {
              const formattedData = formatAuditTrailTableCsv(data, columnsIndexes, t)
              return `${buildHead(columns)}${buildBody(formattedData)}`.trim()
            },
            customFooter: (rowCount: number, page: number, rowsPerPage: number) => {
              return (
                <CustomTableFooter
                  rowCount={rowCount}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  changePage={(page) => setPage(page)}
                  changeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
                  areMoreAvailable={!!data?.next}
                  loadMore={() => setCursor(data?.next)}
                  isWaiting={isFetching}
                />
              )
            },
          }}
          styles={{ border: `1px solid ${theme.palette.neutral[400]}` }}
        />
      </SkeletonWrapper>
    </>
  )
}

export default AuditTrailTable
