import { useCallback, useEffect, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Grid, useMediaQuery, useTheme } from "@mui/material"
// import EditIcon from "@mui/icons-material/Edit"

import usePowerline from "helpers/hooks/powerlines/usePowerline"
import useSpan from "helpers/hooks/spans/useSpan"
import useTower from "helpers/hooks/towers/useTower"
import type { Device } from "types/device.types"
import FormButtons from "widgets/common/FormButtons"
import CardWithTitle from "widgets/common/CardWithTitle"
// import usePermissions from "helpers/hooks/usePermissions"
import type { InstallationDetailsForm } from "./helpers"
import { getDefaultValues, getTowerTwoId } from "./helpers"
import DetailsSectionOne from "./DetailsSectionOne"
import DetailsSectionTwo from "./DetailsSectionTwo"

interface InstallationDetailsProps {
  device: Device
}

const InstallationDetails = ({ device }: InstallationDetailsProps) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"))
  // const { isAdmin } = usePermissions()

  const deviceSettings = useMemo(() => device.settings, [device.settings])
  const { powerline } = usePowerline({ id: deviceSettings?.powerline_id })
  const { span } = useSpan({ id: deviceSettings?.span_id })
  const { towerOneId, towerTwoId } = useMemo(
    () => ({
      towerOneId: deviceSettings?.tower_id,
      towerTwoId: getTowerTwoId(deviceSettings?.tower_id, span?.tower_ids),
    }),
    [deviceSettings?.tower_id, span?.tower_ids],
  )
  const { tower: towerOne, isLoading: towerOneLoading } = useTower({ id: towerOneId })
  const { tower: towerTwo, isLoading: towerTwoLoading } = useTower({ id: towerTwoId })
  const isLoading = useMemo(
    () => towerOneLoading || towerTwoLoading,
    [towerOneLoading, towerTwoLoading],
  )
  const defaultValues = useMemo(
    () => getDefaultValues(deviceSettings, powerline, span, towerOne, towerTwo),
    [deviceSettings, powerline, span, towerOne, towerTwo],
  )

  const methods = useForm<InstallationDetailsForm>({
    mode: "all",
    defaultValues,
  })

  const [isEditing, setIsEditing] = useState(false)
  // const handleOpen = useCallback(() => setIsEditing(true), [])
  const handleClose = useCallback(() => {
    methods.reset()
    setIsEditing(false)
  }, [methods])

  const onSubmit = async (data: InstallationDetailsForm) => {
    console.log("data", data)
    setIsEditing(false)
  }

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues, methods])

  return (
    <CardWithTitle
      titleKey={"device_settings.INSTALLATION_DETAILS"}
      // actionPermission={isAdmin}
      // isDoingAction={isEditing}
      // handleAction={handleOpen}
      // actionIcon={<EditIcon fontSize="small" />}
    >
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Grid container rowSpacing={0} columnSpacing={isDesktop ? 10 : 0}>
            <Grid item xs={12} sm={12} md={8} lg={4} xl={4}>
              <DetailsSectionOne
                defaultValues={defaultValues}
                isEditing={isEditing}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={4} xl={4}>
              <DetailsSectionTwo
                defaultValues={defaultValues}
                isEditing={isEditing}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
          {isEditing && (
            <FormButtons onClose={handleClose} buttonText={"generic.UPDATE"} />
          )}
        </FormProvider>
      </form>
    </CardWithTitle>
  )
}

export default InstallationDetails
