import { useMemo } from "react"
import { Skeleton, Typography } from "@mui/material"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import Divider from "@mui/material/Divider"
import fp from "lodash/fp"
import { useTranslation } from "react-i18next"

import GeoJSONLayers from "widgets/plots/geo/GeoJSONLayers"
import useRoutes from "helpers/hooks/useRoutes"
import { Card } from "widgets/styled/containers"
import CustomLink from "widgets/common/CustomLink"
import type { Powerline } from "types/powerlines.types"
import { featureProperties, getMapCenterFromGeo } from "helpers/utils/map"
import { formatDecimalValues } from "helpers/utils/common"
import usePowerlineGeo from "helpers/hooks/powerlines/usePowerlineGeo"
import Map from "widgets/plots/geo/Map"
import { CARD_MAP_HEIGHT } from "helpers/utils/constants"

interface GroupCardProps {
  powerline: Powerline
}

const mapLayersVisibility = {
  PowerlineSpan: false,
  PowerlineTower: false,
  Device: false,
}

const PowerlineCard = ({ powerline }: GroupCardProps) => {
  const { baseOrgURL } = useRoutes()
  const { powerlineGeoData, isLoading } = usePowerlineGeo({
    id: powerline.id,
  })
  const { i18n, t } = useTranslation()
  const locale = i18n.resolvedLanguage

  const center = useMemo(() => getMapCenterFromGeo(powerlineGeoData), [powerlineGeoData])

  return (
    <>
      <Card
        id="pwCard"
        elevation={0}
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          minHeight: "450px",
          border: (theme) => `1px solid ${theme.palette.neutral[400]}`,
          padding: 0,
        }}
      >
        <CardContent
          sx={{
            width: "100%",
            p: "1rem",
          }}
        >
          <Stack spacing={2}>
            <Stack spacing={1} divider={<Divider flexItem />}>
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: "0.5rem",
                  alignItems: "center",
                  py: "0.5rem",
                }}
              >
                <CustomLink
                  variant="h6"
                  href={`${baseOrgURL}/powerlines/${powerline.id}`}
                  hover
                  bold
                >
                  {powerline.name}
                </CustomLink>
              </Stack>

              {featureProperties.Powerline.map((property: string, i) => {
                const propertyValue = powerline[property as keyof Powerline]
                if (!fp.isNil(propertyValue)) {
                  const formattedProperty =
                    typeof propertyValue === "number"
                      ? formatDecimalValues(propertyValue, locale)
                      : propertyValue
                  return (
                    <Stack
                      flexDirection="row"
                      justifyContent="space-between"
                      key={`PowerlineProperty-${powerline.id}-${i}`}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: (theme) => theme.palette.text.secondary }}
                      >
                        {t(`geo_info.${property.toUpperCase()}`)}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: (theme) => theme.palette.text.secondary }}
                      >
                        {formattedProperty}
                      </Typography>
                    </Stack>
                  )
                }
              })}
            </Stack>
            {isLoading ? (
              <Skeleton variant="rounded" height={CARD_MAP_HEIGHT} />
            ) : (
              <Map center={center} mapHeight={CARD_MAP_HEIGHT}>
                <GeoJSONLayers
                  data={powerlineGeoData}
                  layersVisibility={mapLayersVisibility}
                />
              </Map>
            )}
          </Stack>
        </CardContent>
      </Card>
    </>
  )
}

export default PowerlineCard
