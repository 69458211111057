import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import type { SelectChangeEvent } from "@mui/material"
import { FormControl, MenuItem, Select } from "@mui/material"

import useSelectOptions from "helpers/hooks/useSelectOptions"
import type { AlertState } from "types/alerts.types"

interface AccordionSelectProps {
  setSelectedDateReference: (dateReferenceName: string) => void
  dateReferenceName: string
  alertState: AlertState
}

const AccordionSelect = ({
  setSelectedDateReference,
  dateReferenceName,
  alertState,
}: AccordionSelectProps) => {
  const { t } = useTranslation()
  const { getSelectOptionsByAlertState } = useSelectOptions()
  const options = useMemo(
    () => getSelectOptionsByAlertState(alertState),
    [alertState, getSelectOptionsByAlertState],
  )

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedDateReference(event.target.value)
  }

  return (
    <FormControl
      onKeyDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      sx={{
        maxWidth: 220,
        minWidth: 150,
        marginBottom: 0,
      }}
    >
      <Select
        size="small"
        value={dateReferenceName}
        onChange={handleChange}
        color="primary"
        sx={{ backgroundImage: "none" }}
      >
        {options.map((opt) => (
          <MenuItem key={opt.value} value={opt.value}>
            {t(opt.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default AccordionSelect
