import { type FC } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import ConfirmationPopup from "widgets/common/ConfirmationPopup"
import { selectCurrentOrgId } from "features/store/orgSlice"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import type { PowerlineAlertSetting } from "types/alerts.types"
import { useDeletePowerlineAlertSettingMutation } from "features/api"

interface DeletePowerlineAlertSettingProps {
  alertSetting: PowerlineAlertSetting
  open: boolean
  onClose: () => void
}

const DeletePowerlineAlertSetting: FC<DeletePowerlineAlertSettingProps> = ({
  alertSetting,
  onClose,
  open,
}) => {
  const { t } = useTranslation()
  const orgId = useSelector(selectCurrentOrgId)
  const [deletePowerlineAlertSetting, { isLoading }] =
    useDeletePowerlineAlertSettingMutation()

  const handleDeleteAlert = () => {
    snackbarMutation({
      mutation: deletePowerlineAlertSetting({
        id: alertSetting.id,
        org_id: Number(orgId),
        powerlineId: alertSetting.entity_id,
      }).unwrap(),
      getErrorMessage: buildGetErrorMessage(
        t("error.DELETING_ITEM", { item: t("alert_settings.ALERT_SETTING") }),
      ),
      getSuccessMessage: () =>
        t("success.DELETING_ITEM", {
          item: t("alert_settings.ALERT_SETTING"),
          count: 1,
          context: "female",
        }),
    })
      .then(() => {
        onClose()
      })
      .catch()
  }

  return (
    <ConfirmationPopup
      open={open}
      onClose={onClose}
      description={t("alert_settings.ARE_YOU_SURE_TO_DELETE_SETTING", {
        alertName: alertSetting.config.custom_name,
      })}
      mainButtonText={t("alert_settings.DELETE_ALERT_SETTING")}
      onConfirm={handleDeleteAlert}
      isLoading={isLoading}
      isDeletePopup
    />
  )
}

export default DeletePowerlineAlertSetting
