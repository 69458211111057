import { useReducer } from "react"

import { multipleDateRangeReducer } from "helpers/reducers/dateRangeReducer"
import AlertsContext from "widgets/alerts/AlertsContext"
import GroupAlertsContent from "widgets/deviceGroups/GroupAlertsContent"
import { BodyContainer } from "widgets/styled/containers"

function GroupAlerts() {
  const [multipleDateRange, dispatchMultipleDateRange] = useReducer(
    multipleDateRangeReducer,
    {},
  )

  return (
    <AlertsContext.Provider
      value={{
        multipleDateRange,
        dispatchMultipleDateRange,
      }}
    >
      <BodyContainer disableGutters maxWidth="xl">
        <GroupAlertsContent />
      </BodyContainer>
    </AlertsContext.Provider>
  )
}

export default GroupAlerts
