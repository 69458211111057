import { useMemo, useReducer, useState } from "react"

import {
  formatDeviceAlerts,
  formatPowerlineAlerts,
  getAlertAmounts,
  getFilteredSortedAlerts,
  sortAndGroupAlerts,
} from "helpers/formatters/alertSettingsFormatters"
import useAlertSettings from "helpers/hooks/useAlertSettings"
import useAlertSettingsWStates from "helpers/hooks/useAlertSettingsStates"
import useDevices from "helpers/hooks/useDevices"
import { multipleDateRangeReducer } from "helpers/reducers/dateRangeReducer"
import { paginateList } from "helpers/utils/common"
import type { AlertsFilterOptions } from "types/alerts.types"
import AlertsContext from "widgets/alerts/AlertsContext"
import { SectionContainer } from "widgets/styled/containers"
import Spinner from "widgets/common/Spinner"
import AlertsFilter from "widgets/alerts/AlertsFilter"
import AccordionsSection from "widgets/alerts/AccordionsSection"
import Pagination from "widgets/common/Pagination"
import { getPowerlineIdsFromSettings } from "helpers/utils/alerts"
import useSelectedPowerlines from "helpers/hooks/powerlines/useSelectedPowerlines"

function AlertsContent() {
  const {
    orgDevicesAlertSettingsWStates,
    orgPowerlinesAlertSettingsWStates,
    isLoading: settingsStatesLoading,
  } = useAlertSettingsWStates()
  const {
    orgActiveDevicesAlertSettingsById,
    orgActivePowerlinesAlertSettings,
    orgActivePowerlinesAlertSettingsById,
    isLoading: settingsLoading,
  } = useAlertSettings()
  const { devsWRelsById, isLoading: devicesLoading } = useDevices()
  const [multipleDateRange, dispatchMultipleDateRange] = useReducer(
    multipleDateRangeReducer,
    {},
  )
  const [filters, setFilters] = useState<AlertsFilterOptions | null>(null)
  const [page, setPage] = useState(0)

  const powerlineIds = useMemo(
    () => getPowerlineIdsFromSettings(orgActivePowerlinesAlertSettings),
    [orgActivePowerlinesAlertSettings],
  )
  const { powerlinesById, powerlinesLoading } = useSelectedPowerlines({ powerlineIds })

  const formattedDeviceAlerts = useMemo(
    () =>
      formatDeviceAlerts(
        orgDevicesAlertSettingsWStates,
        orgActiveDevicesAlertSettingsById,
        devsWRelsById,
      ),
    [orgDevicesAlertSettingsWStates, orgActiveDevicesAlertSettingsById, devsWRelsById],
  )

  const formattedPowerlineAlerts = useMemo(
    () =>
      formatPowerlineAlerts(
        orgPowerlinesAlertSettingsWStates,
        orgActivePowerlinesAlertSettingsById,
        powerlinesById,
      ),
    [
      orgPowerlinesAlertSettingsWStates,
      orgActivePowerlinesAlertSettingsById,
      powerlinesById,
    ],
  )

  const sortedAndGroupedAlerts = useMemo(
    () => sortAndGroupAlerts([...formattedDeviceAlerts, ...formattedPowerlineAlerts]),
    [formattedDeviceAlerts, formattedPowerlineAlerts],
  )

  const alertsAmount = useMemo(
    () => getAlertAmounts(sortedAndGroupedAlerts),
    [sortedAndGroupedAlerts],
  )
  const alertsSortedByState = useMemo(
    () => getFilteredSortedAlerts(sortedAndGroupedAlerts, filters),
    [sortedAndGroupedAlerts, filters],
  )
  const pagedAlerts = useMemo(
    () => paginateList(page, alertsSortedByState),
    [alertsSortedByState, page],
  )

  const isLoading = useMemo(
    () => settingsStatesLoading || settingsLoading || devicesLoading || powerlinesLoading,
    [devicesLoading, powerlinesLoading, settingsLoading, settingsStatesLoading],
  )

  return (
    <AlertsContext.Provider
      value={{
        multipleDateRange,
        dispatchMultipleDateRange,
      }}
    >
      <SectionContainer disableGutters maxWidth="xl">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <AlertsFilter
              alertsAmount={alertsAmount}
              filters={filters}
              setFilters={setFilters}
              defaultValues={["open", "silenced"]}
            />
            <AccordionsSection alerts={pagedAlerts} />
            <Pagination
              count={alertsSortedByState.length}
              page={page}
              setPage={setPage}
            />
          </>
        )}
      </SectionContainer>
    </AlertsContext.Provider>
  )
}

export default AlertsContent
