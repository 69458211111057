import type { FC } from "react"
import { useCallback, useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import type { DateTime } from "luxon"

import type { EntityType } from "types/common.types"
import type { DateRange, DateRangeDateTime } from "types/date.types"

import useSelectOptions from "helpers/hooks/useSelectOptions"
import { DatePickerSectionCard } from "widgets/styled/containers"
import {
  setInitialDateRange,
  setRangeDateRange,
  initializePicker,
  formatDateRange,
  setCustomDateRange,
} from "./helpers"
import DateRangeCustomSelect from "./DateRangeCustomSelect"

interface DateRangePickerProps {
  dateRange: DateRange | null
  dispatchDateRange: (dateRange: DateRange) => void
  isFetching?: boolean
  entity?: EntityType
}

const DateRangePicker: FC<DateRangePickerProps> = ({
  dateRange,
  dispatchDateRange,
  isFetching = false,
  entity = "device",
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatchCombo = useMemo(() => {
    return { setSearchParams, dispatchDateRange }
  }, [dispatchDateRange, setSearchParams])

  const { getDateReferencesByEntity } = useSelectOptions()
  const dateReferences = useMemo(
    () => getDateReferencesByEntity(entity),
    [entity, getDateReferencesByEntity],
  )

  // read url params only on first render, ignore updates made by ourselves
  const { initialDateRange } = useMemo(
    () => initializePicker(searchParams, dateRange, dateReferences, entity),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateReferences],
  )

  useEffect(() => {
    // Set initial redux value
    setInitialDateRange(initialDateRange, dispatchCombo)
    // This is only updated after the first render.
    // Intentionally not re-evaluating on update.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dateRangeDateTime = useMemo(
    () => formatDateRange(dateRange),
    // Value from redux, this is the one used in the component
    [dateRange],
  )

  const updateDateRange = useCallback(
    (fields: { [key: string]: DateTime | null }, dateRange: DateRangeDateTime) => {
      if (dateRange) {
        setCustomDateRange(fields, dateRange, dispatchCombo)
      }
    },
    [dispatchCombo],
  )

  const updateDateReference = useCallback(
    (dateReferenceName: string, dateRange: DateRangeDateTime) => {
      setRangeDateRange(dateReferenceName, dateReferences, dateRange, dispatchCombo)
    },
    [dateReferences, dispatchCombo],
  )

  return (
    <>
      {dateRangeDateTime && (
        <>
          <DatePickerSectionCard>
            <DateRangeCustomSelect
              dateRangeDateTime={dateRangeDateTime}
              updateDateRange={updateDateRange}
              setSelectedDateReference={(value) =>
                updateDateReference(value, dateRangeDateTime)
              }
              isFetching={isFetching}
              entity={entity}
            />
          </DatePickerSectionCard>
        </>
      )}
    </>
  )
}

export default DateRangePicker
