import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { stringify as qsStringify } from "query-string"
import { useSelector } from "react-redux"

import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import { successMessage } from "helpers/utils/snackbars"

import { API_URL } from "features/config"
import { selectToken } from "features/store/authSlice"

interface DownloadProps {
  id: number
  filename: string
  fromDate: string
  toDate: string
}

interface GetUrlParamsProps {
  fromDate: string
  toDate: string
}

type FileSaver = (blob: Blob) => null

type Status = "pending" | "rejected" | "fulfilled" | undefined

const useTelemetryCSV = () => {
  const { t } = useTranslation()
  const token = useSelector(selectToken)
  const [status, setStatus] = useState<Status>(undefined)

  const taskName = useMemo(() => t("tasks.CSV_DOWNLOAD"), [t])

  const apiFetch = useCallback(
    (id: number, params: { fromDate: string; toDate: string }) => {
      const url = getUrl({ id })
      const urlParams = getUrlParams(params)
      const headers = {
        authorization: `Bearer ${token}`,
      }
      return fetch(`${url}?${urlParams}`, { method: "GET", headers })
    },
    [token],
  )

  const download = useCallback(
    async ({ id, filename, fromDate, toDate }: DownloadProps): Promise<null> => {
      const fileSaver = buildFileSaver(filename)
      setStatus("pending")
      const promise = apiFetch(id, { fromDate, toDate })
        .then((response) => response.blob())
        .then(fileSaver)

      successMessage(t("tasks.START_TASK", { taskName }))
      const getErrorMessage = buildGetErrorMessage(
        t("tasks.ERROR_EXECUTING_TASK", { taskName }),
      )
      return snackbarMutation({
        mutation: promise,
        getSuccessMessage: (): undefined => {
          setStatus("fulfilled")
        },
        getErrorMessage: (error) => {
          setStatus("rejected")
          return getErrorMessage(error)
        },
      }).then(() => null)
    },
    [apiFetch, t, taskName, setStatus],
  )
  return { download, isFetching: status === "pending", isError: status === "rejected" }
}

const stripSlash = (value: string): string =>
  value.endsWith("/") ? value.slice(0, -1) : value

const getUrl = ({ id }: { id: number }): string =>
  `${stripSlash(API_URL)}/v1/devices/${id}/telemetry`

const getUrlParams = ({ fromDate, toDate }: GetUrlParamsProps) => {
  const params = {
    from_date: fromDate,
    to_date: toDate,
    format: "csv",
  }
  const urlParams = qsStringify(params)
  return urlParams
}

const buildFileSaver = (filename: string): FileSaver => {
  const fileSaver = (blob: Blob) => {
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `${filename}`)
    link.setAttribute("class", "hidden")
    document.body.appendChild(link)
    link.click()
    link.parentNode?.removeChild(link)
    window.URL.revokeObjectURL(url)
    return null
  }
  return fileSaver
}

export default useTelemetryCSV
