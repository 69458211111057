import { useState, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Button, Stack } from "@mui/material"
import { MuiOtpInput } from "mui-one-time-password-input"
import fp from "lodash/fp"

import { useUserOTPVerifyMutation } from "features/api"
import { snackbarMutation, buildGetErrorMessage } from "helpers/utils/mutations"
import Spinner from "widgets/common/Spinner"

interface OTPVerifierProps {
  username: string
  accessToken?: string
  onComplete: () => Promise<void>
}

const OTPVerifier = ({ username, accessToken, onComplete }: OTPVerifierProps) => {
  const { t } = useTranslation()
  const [otp, setOtp] = useState("")
  const [isCompleting, setIsCompleting] = useState<boolean>(false)

  const handleChange = useCallback(
    (value: string) => {
      setOtp(value)
    },
    [setOtp],
  )

  const [otpVerify, { isLoading: isVerifying }] = useUserOTPVerifyMutation()

  const otpVerifyWithSnackbar = useCallback(
    async (value) => {
      const req = { username, token: value, accessToken }
      const mutation = otpVerify(req).unwrap()
      return snackbarMutation({
        mutation,
        getErrorMessage: buildGetErrorMessage(
          t("error.ENABLING_ITEM", {
            item: t("account_settings.TWO_FA").toLowerCase(),
            count: 1,
            context: "female",
          }),
        ),
        getSuccessMessage: () =>
          t("success.ENABLING_ITEM", {
            item: t("account_settings.TWO_FA"),
            count: 1,
            context: "female",
          }),
      }).catch()
    },
    [username, accessToken, otpVerify, t],
  )
  const verify = useMemo(
    () => fp.throttle(1000, (value) => otpVerifyWithSnackbar(value).then(onComplete)),
    [onComplete, otpVerifyWithSnackbar],
  )
  const handleComplete = (value: string) => {
    setIsCompleting(true)
    return verify(value)
      ?.then(() => setIsCompleting(false))
      .catch(() => {
        handleChange("")
        setIsCompleting(false)
      })
  }

  const handlePaste = async () => {
    await navigator.clipboard.readText().then((authCode) => {
      handleChange(authCode)
      handleComplete(authCode)
    })
  }

  return (
    <>
      <Stack direction="row" justifyContent={"center"} alignItems={"center"} gap={4}>
        {isVerifying || isCompleting ? (
          <Spinner />
        ) : (
          <Stack gap={1} justifyContent={"center"} alignItems={"center"}>
            <MuiOtpInput
              TextFieldsProps={{ type: "number" }}
              value={otp}
              onChange={handleChange}
              onComplete={handleComplete}
              autoFocus
              length={6}
              sx={{
                maxWidth: "350px",
                "& .MuiTextField-root": { margin: 0 },
                "& .MuiOutlinedInput-input": { padding: "10px 5px", height: "30px" },
                "& input[type=number]": {
                  "-moz-appearance": "textfield",
                },
                "& input[type=number]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "& input[type=number]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
              }}
              gap={"5px"}
            />
            <Button size="small" onClick={handlePaste}>
              {t("account_settings.PASTE_CODE")}
            </Button>
          </Stack>
        )}
      </Stack>
    </>
  )
}

export default OTPVerifier
