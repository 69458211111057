import { filterObject } from "helpers/utils/common"
import type {
  OrgAlertSettingsReq,
  OrgAlertSettingsRes,
  OrgAlertSettingsStatesReq,
  OrgAlertSettingsStatesRes,
  UpdateOrgAlertStateReq,
  UpdateOrgAlertStateRes,
} from "types/orgs.types"
import type {
  AddDeviceAlertSettingReq,
  AddDeviceAlertSettingRes,
  AddPowerlineAlertSettingReq,
  AddPowerlineAlertSettingRes,
  AlertsReq,
  AlertsRes,
  DeleteDeviceAlertSettingReq,
  DeletePowerlineAlertSettingReq,
  DeviceAlertsTypesReq,
  DeviceAlertsTypesRes,
  GroupAlertsInfoReq,
  GroupAlertsInfoRes,
  MarkAlertAsViewedReq,
  PowerlineAlertsTypesReq,
  PowerlineAlertsTypesRes,
  UpdateDeviceAlertSettingReq,
  UpdateDeviceAlertSettingRes,
  UpdatePowerlineAlertSettingReq,
  UpdatePowerlineAlertSettingRes,
} from "types/alerts.types"
import type { Paged } from "types/common.types"

import { api } from "./base"

export const alertsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    alerts: builder.query<AlertsRes, AlertsReq>({
      query: ({ params }) => ({
        url: "/v1/alerts",
        params,
      }),
      providesTags: (result, _error, { params }) => {
        return result
          ? [
              ...result.map(({ id }) => ({ type: "Alert" as const, id })),
              { type: "Alert", id: `Organization(${params.org_id})` },
            ]
          : [{ type: "Alert", id: `Organization(${params.org_id})` }]
      },
    }),
    limitedAlerts: builder.query<Paged<AlertsRes>, AlertsReq>({
      query: ({ params }) => ({
        url: "/v1/alerts",
        params,
      }),
      providesTags: (result, _error, { params }) => {
        return result
          ? [
              ...result.results.map(({ id }) => ({ type: "Alert" as const, id })),
              { type: "Alert", id: `Organization(${params.org_id})` },
            ]
          : [{ type: "Alert", id: `Organization(${params.org_id})` }]
      },
    }),

    // Device ----------------
    deviceAlertTypes: builder.query<DeviceAlertsTypesRes, DeviceAlertsTypesReq>({
      query: ({ lessId, params }) => ({
        url: `/v1/devices/${encodeURIComponent(lessId)}/alerts/types`,
        params,
      }),
    }),

    addDeviceAlertSetting: builder.mutation<
      AddDeviceAlertSettingRes,
      AddDeviceAlertSettingReq
    >({
      query: ({ less_id, config, org_id, type }) => {
        const body = {
          config: filterObject(config, (_, value) => value !== null),
          org_id,
          type,
        }
        return {
          url: `/v1/devices/${encodeURIComponent(less_id)}/alerts/settings`,
          method: "POST",
          body,
        }
      },
      invalidatesTags: (_result, error, { org_id }) => {
        return !error
          ? [
              { type: "AlertSetting" as const, id: `Organization(${org_id})` },
              {
                type: "AlertSettingState" as const,
                id: `Organization(${org_id})`,
              },
            ]
          : []
      },
    }),

    updateDeviceAlertSetting: builder.mutation<
      UpdateDeviceAlertSettingRes,
      UpdateDeviceAlertSettingReq
    >({
      query: ({ less_id, config, org_id, type, id }) => {
        const body = {
          id,
          config: filterObject(config, (_, value) => value !== null),
          org_id,
          type,
        }
        return {
          url: `/v1/devices/${encodeURIComponent(less_id)}/alerts/settings`,
          method: "PATCH",
          body,
        }
      },
      invalidatesTags: (_result, error, { org_id }) => {
        return !error
          ? [{ type: "AlertSetting" as const, id: `Organization(${org_id})` }]
          : []
      },
    }),

    deleteDeviceAlertSetting: builder.mutation<null, DeleteDeviceAlertSettingReq>({
      query: ({ id, org_id, less_id }) => ({
        url: `/v1/devices/${encodeURIComponent(less_id)}/alerts/settings`,
        method: "DELETE",
        body: { id, org_id },
      }),
      invalidatesTags: (_result, error, { org_id }) => {
        return !error
          ? [{ type: "AlertSetting" as const, id: `Organization(${org_id})` }]
          : []
      },
    }),

    // Powerline ----------------
    powerlineAlertTypes: builder.query<PowerlineAlertsTypesRes, PowerlineAlertsTypesReq>({
      query: ({ params }) => ({
        url: `/v1/powerlines/-/alerts/types`,
        params,
      }),
    }),

    addPowerlineAlertSetting: builder.mutation<
      AddPowerlineAlertSettingRes,
      AddPowerlineAlertSettingReq
    >({
      query: ({ powerlineId, config, org_id, type }) => {
        const body = {
          config: filterObject(config, (_, value) => value !== null),
          org_id,
          type,
        }
        return {
          url: `/v1/powerlines/${encodeURIComponent(powerlineId)}/alerts/settings`,
          method: "POST",
          body,
        }
      },
      invalidatesTags: (_result, error, { org_id }) => {
        return !error
          ? [
              { type: "AlertSetting" as const, id: `Organization(${org_id})` },
              {
                type: "AlertSettingState" as const,
                id: `Organization(${org_id})`,
              },
            ]
          : []
      },
    }),

    updatePowerlineAlertSetting: builder.mutation<
      UpdatePowerlineAlertSettingRes,
      UpdatePowerlineAlertSettingReq
    >({
      query: ({ powerlineId, config, orgId, type, id }) => {
        const body = {
          config: filterObject(config, (_, value) => value !== null),
          org_id: orgId,
          type,
          id,
        }
        return {
          url: `/v1/powerlines/${encodeURIComponent(powerlineId)}/alerts/settings`,
          method: "PATCH",
          body,
        }
      },
      invalidatesTags: (_result, error, { orgId }) => {
        return !error
          ? [{ type: "AlertSetting" as const, id: `Organization(${orgId})` }]
          : []
      },
    }),

    deletePowerlineAlertSetting: builder.mutation<null, DeletePowerlineAlertSettingReq>({
      query: ({ id, org_id, powerlineId }) => ({
        url: `/v1/powerlines/${encodeURIComponent(powerlineId)}/alerts/settings`,
        method: "DELETE",
        body: { id, org_id },
      }),
      invalidatesTags: (_result, error, { org_id }) => {
        return !error
          ? [{ type: "AlertSetting" as const, id: `Organization(${org_id})` }]
          : []
      },
    }),

    // --------------

    groupAlertsInfo: builder.query<GroupAlertsInfoRes, GroupAlertsInfoReq>({
      query: ({ params }) => ({
        url: "/v1/group_alert_information",
        params,
      }),
    }),

    markAlertAsViewed: builder.mutation<boolean | null, MarkAlertAsViewedReq>({
      query: ({ id, org_id }) => ({
        url: "/v1/alerts",
        method: "PUT",
        body: { id, org_id },
      }),
      invalidatesTags: (_result, _error, { id, setting_id }) => [
        { type: "Alert", id },
        { type: "AlertSettingState", id: setting_id },
      ],
    }),

    // TODO: add the corresponding provides alerts, so when editing an alert they are reloaded.
    orgAlertSettings: builder.query<OrgAlertSettingsRes, OrgAlertSettingsReq>({
      query: ({ org_id }) => ({
        url: `/v1/orgs/${encodeURIComponent(org_id)}/alerts/settings`,
      }),
      providesTags: (result, _error, { org_id }) => [
        ...(result || []).map(({ id }) => ({
          type: "AlertSetting" as const,
          id,
        })),
        {
          type: "AlertSetting",
          id: `Organization(${org_id})`,
        },
      ],
    }),
    orgAlertSettingsStates: builder.query<
      OrgAlertSettingsStatesRes,
      OrgAlertSettingsStatesReq
    >({
      query: ({ org_id }) => ({
        url: `/v1/orgs/${encodeURIComponent(org_id)}/alerts/states`,
      }),
      providesTags: (result, _error, { org_id }) => [
        ...(result || []).map(({ id }) => ({
          type: "AlertSettingState" as const,
          id,
        })),
        {
          type: "AlertSettingState",
          id: `Organization(${org_id})`,
        },
      ],
    }),
    updateOrgAlertState: builder.mutation<UpdateOrgAlertStateRes, UpdateOrgAlertStateReq>(
      {
        query: ({ orgId, id, action }) => ({
          url: `/v1/orgs/${encodeURIComponent(orgId)}/alerts/states/${encodeURIComponent(
            id,
          )}/action`,
          method: "POST",
          body: { action },
        }),
        invalidatesTags: (_result, error, { id }) => {
          return !error ? [{ type: "AlertSettingState", id }] : []
        },
      },
    ),
  }),
})
