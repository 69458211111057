import type { FC } from "react"
import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { type AlertSettingType, type AlertSettingUpsertForm } from "types/alerts.types"
import { selectCurrentOrgId } from "features/store/orgSlice"
import { useAddDeviceAlertSettingMutation } from "features/api"
import { formatMutationParams } from "helpers/utils/alerts"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import AlertSettingForm from "../AlertSettingForm"

interface AddDeviceAlertSettingsProps {
  types: AlertSettingType[]
  onClose: () => void
}

const AddDeviceAlertSetting: FC<AddDeviceAlertSettingsProps> = ({ types, onClose }) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const orgId = useSelector(selectCurrentOrgId)
  const methods = useForm<AlertSettingUpsertForm>({
    mode: "all",
    defaultValues: {
      type: "",
    },
  })
  const { resetField, watch } = methods
  const selectedTypeName = watch("type")
  const selectedType = useMemo(
    () => types?.find((type) => type.name === selectedTypeName),
    [types, selectedTypeName],
  )
  const [addDeviceAlertSetting, { isLoading }] = useAddDeviceAlertSettingMutation()

  useEffect(() => {
    selectedType?.params.map((param) =>
      resetField(param.id, {
        defaultValue: param.default !== null ? param.default : "",
      }),
    )
  }, [resetField, selectedType])

  const onSubmit = async (data: AlertSettingUpsertForm) => {
    const defaultEnabledData = {
      ...data,
      active: true,
    }
    const formattedData = formatMutationParams(defaultEnabledData, selectedType)
    snackbarMutation({
      mutation: addDeviceAlertSetting({
        less_id: Number(id),
        org_id: Number(orgId),
        ...formattedData,
      }).unwrap(),
      getErrorMessage: buildGetErrorMessage(
        t("error.CREATING_ITEM", { item: t("alert_settings.ALERT_SETTING") }),
      ),
      getSuccessMessage: () =>
        t("success.CREATING_ITEM", {
          item: t("alert_settings.ALERT_SETTING"),
          count: 1,
          context: "female",
        }),
    })
      .then(() => {
        onClose()
      })
      .catch()
  }

  return (
    <AlertSettingForm
      entity="device"
      isLoading={isLoading}
      title="alerts.NEW_ALERT_SETTING"
      onSubmit={methods.handleSubmit(onSubmit)}
      methods={methods}
      types={types}
      selectedTypeName={selectedTypeName}
      onClose={onClose}
      buttonText="generic.CREATE"
    />
  )
}

export default AddDeviceAlertSetting
