import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import ConditionalInput from "widgets/common/ConditionalInput"
import type { InstallationDetailsForm } from "./helpers"

interface DetailsSectionOneProps {
  defaultValues: InstallationDetailsForm
  isEditing: boolean
  isLoading: boolean
}

const DetailsSectionOne = ({
  defaultValues,
  isEditing,
  isLoading,
}: DetailsSectionOneProps) => {
  const { t } = useTranslation()

  const installationTypeDisplayedValue = useMemo(
    () =>
      defaultValues.installationType
        ? t(`device_installation.${defaultValues.installationType.toUpperCase()}`)
        : "",
    [defaultValues.installationType, t],
  )

  const orientationDisplayedValue = useMemo(
    () =>
      defaultValues.orientation
        ? t(`device_installation.${defaultValues.orientation.toUpperCase()}`)
        : "",
    [defaultValues.orientation, t],
  )

  return (
    <>
      <ConditionalInput
        name="installationType"
        labelKey="device_installation.INSTALLATION_TYPE"
        displayedValue={installationTypeDisplayedValue}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="orientation"
        labelKey="device_installation.ORIENTATION"
        displayedValue={orientationDisplayedValue}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="anchoringPointDistance"
        type="number"
        labelKey="device_installation.ANCHORING_POINT_DISTANCE"
        displayedValue={defaultValues.anchoringPointDistance}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="voltageNominal"
        type="number"
        labelKey="device_installation.VOLTAGE_NOMINAL"
        displayedValue={defaultValues.voltageNominal}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="powerlineId"
        type="number"
        labelKey="device_installation.POWERLINE_ID"
        displayedValue={defaultValues.powerlineId}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="spanId"
        type="number"
        labelKey="device_installation.SPAN_ID"
        displayedValue={defaultValues.spanId}
        isEditing={isEditing}
        isLoading={isLoading}
      />
      <ConditionalInput
        name="phaseId"
        labelKey="device_installation.PHASE_ID"
        displayedValue={defaultValues.phaseId}
        isEditing={isEditing}
        isLoading={isLoading}
      />
    </>
  )
}

export default DetailsSectionOne
