import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import type { AlertsFilterOptions } from "types/alerts.types"

import useAlertSettings from "helpers/hooks/useAlertSettings"
import useAlertSettingsStates from "helpers/hooks/useAlertSettingsStates"
import AccordionsSection from "widgets/alerts/AccordionsSection"
import AlertsFilter from "widgets/alerts/AlertsFilter"
import Pagination from "widgets/common/Pagination"
import Spinner from "widgets/common/Spinner"
import { SectionContainer } from "widgets/styled/containers"
import {
  formatPowerlineAlerts,
  getAlertAmounts,
  getFilteredSortedAlerts,
  sortAndGroupAlerts,
} from "helpers/formatters/alertSettingsFormatters"
import { paginateList } from "helpers/utils/common"
import usePowerline from "helpers/hooks/powerlines/usePowerline"

const PowerlineAlertsContent = () => {
  const { id } = useParams()
  const { orgActivePowerlinesAlertSettingsById, isLoading: settingsLoading } =
    useAlertSettings()
  const { getPowerlineAlertSettingsWStatesByEntityId, isLoading: settingsStatesLoading } =
    useAlertSettingsStates()
  const { powerlineById, isLoading: powerlineLoading } = usePowerline({ id: Number(id) })
  const [filters, setFilters] = useState<AlertsFilterOptions | null>(null)
  const [page, setPage] = useState(0)

  const powerlineSettingsStates = useMemo(
    () => getPowerlineAlertSettingsWStatesByEntityId(Number(id)),
    [getPowerlineAlertSettingsWStatesByEntityId, id],
  )

  const formattedPowerlineAlerts = useMemo(
    () =>
      formatPowerlineAlerts(
        powerlineSettingsStates,
        orgActivePowerlinesAlertSettingsById,
        powerlineById,
      ),
    [powerlineSettingsStates, orgActivePowerlinesAlertSettingsById, powerlineById],
  )
  const sortedAndGroupedAlerts = useMemo(
    () => sortAndGroupAlerts(formattedPowerlineAlerts),
    [formattedPowerlineAlerts],
  )
  const powerlineAlertsAmount = useMemo(
    () => getAlertAmounts(sortedAndGroupedAlerts),
    [sortedAndGroupedAlerts],
  )
  const powerlineAlertsSortedByState = useMemo(
    () => getFilteredSortedAlerts(sortedAndGroupedAlerts, filters),
    [sortedAndGroupedAlerts, filters],
  )
  const pagedAlerts = useMemo(
    () => paginateList(page, powerlineAlertsSortedByState),
    [page, powerlineAlertsSortedByState],
  )

  const isLoading = useMemo(
    () => settingsStatesLoading || settingsLoading || powerlineLoading,
    [powerlineLoading, settingsLoading, settingsStatesLoading],
  )

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <SectionContainer disableGutters maxWidth="xl">
          <AlertsFilter
            alertsAmount={powerlineAlertsAmount}
            filters={filters}
            setFilters={setFilters}
          />
          <AccordionsSection alerts={pagedAlerts} />
          <Pagination
            count={powerlineAlertsSortedByState.length}
            page={page}
            setPage={setPage}
          />
        </SectionContainer>
      )}
    </>
  )
}

export default PowerlineAlertsContent
