import type { SxProps } from "@mui/material"
import { Typography, useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import { useMemo } from "react"
import { degreesToString } from "helpers/utils/common"
import type { InstallationType } from "types/device.types"

interface AngleViewProps {
  src: string
  value: number
  variableName: "alpha" | "beta"
  installationType: InstallationType
  rowHeight: number
  alt?: string
}

export const AngleView = ({
  src,
  installationType,
  variableName,
  value,
  rowHeight,
}: AngleViewProps) => {
  const theme = useTheme()

  const imgBoxStyles = useMemo(() => {
    switch (installationType) {
      case "tower":
        return {
          height: `${rowHeight * 4}px`,
          [theme.breakpoints.up("sm")]: {
            height: `${rowHeight * 5}px`,
          },
        }
      case "powerline":
      default:
        return {
          height: `${rowHeight * 4}px`,
          [theme.breakpoints.up("sm")]: {
            height: `${rowHeight * 3.5}px`,
          },
        }
    }
  }, [installationType, rowHeight, theme.breakpoints])

  const imgStyles = useMemo(() => {
    switch (installationType) {
      case "powerline":
        switch (variableName) {
          case "alpha":
            return {
              transform: `rotate(${-value}deg)`,
              transformOrigin: "50% 20%",
              translate: `${Math.abs(value) > 90 ? "0 12rem" : "0"}`,
              height: "90%",
              margin: "3.5rem 0",
            }

          case "beta":
            return {
              transform: `rotate(${-value}deg)`,
              transformOrigin: "50% 30%",
              translate: `${Math.abs(value) > 90 ? "0 10rem" : "0"}`,
              height: "90%",
              margin: "3rem 0",
            }
        }
        break
      case "tower":
        switch (variableName) {
          case "alpha":
            return {
              transform: `rotate(${-value}deg)`,
              transformOrigin: "50% 70%",
              translate: `${Math.abs(value) > 90 ? "0 12rem" : "0 3rem"}`,
              height: "90%",
              width: "fit-content",
            }
          case "beta":
            return {
              transform: `rotate(${-value}deg)`,
              transformOrigin: "50% 70%",
              translate: `${Math.abs(value) > 90 ? "0 10rem" : "0 2rem"}`,
              margin: "3rem 0 0 0",
              height: "90%",
              width: "fit-content",
            }
        }
    }
  }, [installationType, value, variableName])

  const valueBoxStyles = useMemo(() => {
    switch (installationType) {
      case "tower":
        return {
          overflow: "hidden",
          position: "absolute",
          top: "1rem",
          width: "100%",
        } as SxProps
      case "powerline":
      default:
        return {
          overflow: "hidden",
          position: "absolute",
          bottom: "0.5rem",
          width: "100%",
        } as SxProps
    }
  }, [installationType])

  return (
    <>
      <Box sx={valueBoxStyles}>
        <Typography
          variant="h4"
          sx={{
            whiteSpace: "nowrap",
            color: theme.palette[variableName]?.main ?? theme.palette.text.primary,
            ml: "auto",
            mr: "auto",
            width: "fit-content",
            p: "0.3rem 1rem",
            backgroundColor: theme.palette.background.paper,
            zIndex: 10,
          }}
        >
          {variableName === "alpha" ? "α" : "β"} = {degreesToString(value)}
        </Typography>
      </Box>
      <Box
        justifyContent="center"
        alignItems="center"
        width="100%"
        overflow="hidden"
        display="flex"
        sx={imgBoxStyles}
      >
        <Box
          component="img"
          src={src}
          alt={`${variableName} angle view`}
          sx={imgStyles}
        />
      </Box>
    </>
  )
}
