import { useContext, useEffect, useMemo } from "react"
import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import type {
  IPlotView,
  PowerlinePlotReqConfig,
  RecordService,
} from "types/dashboard.types"

import { usePowerlineTelemetryQuery } from "features/api"
import { getRecordService } from "helpers/formatters/dataFormatters"
import useDateRange from "helpers/hooks/useDateRange"
import { getCellHeight } from "helpers/utils/dashboards"
import DashboardContext from "widgets/powerlines/Dashboard/DashboardContext"
import { selectCurrentOrgId } from "features/store/orgSlice"
import Plot from "../Plot"
import { formatTelemetryData, getFill, removeOldForecastData } from "../helpers"

interface PowerlineTelemetryPlotProps {
  view: IPlotView
  reqConfig: PowerlinePlotReqConfig
  draggable?: boolean
}

const PowerlineTelemetryPlot: FC<PowerlineTelemetryPlotProps> = ({
  view,
  draggable,
  reqConfig,
}) => {
  const { t } = useTranslation()
  const orgId = useSelector(selectCurrentOrgId)
  const {
    powerlineId,
    dispatchPlotLayout,
    dispatchPlotState,
    config: dashboardConfig,
  } = useContext(DashboardContext)
  const { powerlineDateRange, isPowerlineDateRangeValid } = useDateRange()
  const {
    aggregate,
    aggregate_n,
    fields,
    group,
    method,
    aggregate_every,
    measurement,
    forecastFields,
  } = reqConfig

  const { data, isFetching } = usePowerlineTelemetryQuery(
    {
      id: powerlineId,
      params: {
        aggregate,
        aggregate_n,
        method,
        group,
        aggregate_every,
        field: fields,
        from_date: powerlineDateRange?.fromDate,
        to_date: powerlineDateRange?.toDate,
        org_id: Number(orgId),
        measurement,
      },
    },
    {
      skip:
        !isPowerlineDateRangeValid || !powerlineDateRange || !fields || !fields.length,
    },
  )

  const dataWithoutOldForecast = useMemo(
    () => removeOldForecastData(data, forecastFields),
    [data, forecastFields],
  )

  const recordService: RecordService = useMemo(() => {
    const fill = getFill(view, reqConfig)
    return getRecordService(fill, t)
  }, [reqConfig, t, view])

  const formattedData = useMemo(
    () => formatTelemetryData(recordService, dataWithoutOldForecast, view),
    [dataWithoutOldForecast, recordService, view],
  )

  const shouldRender = dataWithoutOldForecast && dataWithoutOldForecast.length !== 0

  useEffect(() => {
    dispatchPlotLayout({
      type: "UPDATE_CELL_HEIGHT",
      payload: {
        id: view.nameKey,
        h: shouldRender ? getCellHeight(view.nameKey, dashboardConfig.type) : 0,
      },
    })
  }, [dispatchPlotLayout, view, shouldRender, dashboardConfig.type])

  useEffect(() => {
    dispatchPlotState({
      type: "UPDATE_CELL_STATE",
      payload: {
        id: view.nameKey,
        isFetching,
      },
    })
  }, [isFetching, dispatchPlotState, view])

  return (
    <>
      {formattedData && formattedData.length > 0 && (
        <Plot
          data={formattedData}
          view={view}
          draggable={draggable}
          recordService={recordService}
        />
      )}
    </>
  )
}

export default PowerlineTelemetryPlot
