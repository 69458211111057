import type { LinkProps } from "react-router-dom"
import { Link } from "react-router-dom"
import { alpha, keyframes, styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import type { TabProps } from "@mui/material"
import {
  Accordion as MUIAccordion,
  AccordionDetails as MUIAccordionDetails,
  AccordionSummary as MUIAccordionSummary,
  Tab as MUITab,
} from "@mui/material"
import { default as MUIDataTable } from "mui-datatables"

import type { IAccordionProps, IDefaultStyledProps } from "types/styled.types"
import { getAccordionStateColor } from "helpers/utils/styles"

const Tag = styled(Box)(() => ({
  fontWeight: "inherit",
  width: "fit-content",
  borderRadius: 5,
  padding: "0.3rem 0.6rem",
  border: "1px solid",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}))

export const ItemTag = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.light,
  padding: "0.5rem",
  borderRadius: "5px",
  width: "fit-content",
}))

export const Success = styled(Tag)<IDefaultStyledProps>(({ theme, styles }) => ({
  backgroundColor: alpha(theme.palette.secondary.light, 0.1),
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
  ...styles,
}))

export const Info = styled(Tag)<IDefaultStyledProps>(({ theme, styles }) => ({
  backgroundColor: alpha(theme.palette.primary.light, 0.1),
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  ...styles,
}))

export const NeutralLight = styled(Tag)<IDefaultStyledProps>(({ theme, styles }) => ({
  backgroundColor: theme.palette.neutral[300],
  color: theme.palette.text.primary,
  borderColor: theme.palette.neutral[300],
  ...styles,
}))

export const NeutralDark = styled(Tag)<IDefaultStyledProps>(({ theme, styles }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? alpha(theme.palette.grey[100], 0.1)
      : alpha(theme.palette.grey[100], 1),
  color: theme.palette.grey[400],
  borderColor: theme.palette.grey[400],
  ...styles,
}))

export const Error = styled(Tag)<IDefaultStyledProps>(({ theme, styles }) => ({
  backgroundColor: alpha(theme.palette.error.main, 0.1),
  color: theme.palette.error.main,
  borderColor: theme.palette.error.main,
  ...styles,
}))

export const Warning = styled(Tag)<IDefaultStyledProps>(({ theme, styles }) => ({
  backgroundColor: alpha(theme.palette.warning.main, 0.06),
  color: theme.palette.warning.main,
  borderColor: theme.palette.warning.main,
  ...styles,
}))

export const AutocompleteInput = styled(TextField)<IDefaultStyledProps>(
  ({ theme, styles }) => ({
    input: {
      color: theme.palette.grey[100],
    },
    label: {
      color: theme.palette.grey[100],
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.grey[100],
      },
      "&:hover fieldset": {
        borderColor: theme.palette.grey[100],
      },
    },
    ...styles,
  }),
)

export const DataTable = styled(MUIDataTable)<IDefaultStyledProps>(
  ({ theme, styles }) => ({
    "& .MuiTableCell-root": {
      fontWeight: "inherit",
      paddingTop: "0.3rem",
      paddingBottom: "0.3rem",
      borderColor: theme.palette.neutral[300],
    },
    "& .MuiTableCell-head": {
      fontWeight: 700,
    },
    "& .MuiToolbar-root": {
      minHeight: "50px",
    },
    "& .MuiTableCell-footer": {
      padding: 0,
      border: "none",
    },
    "& .MuiButtonBase-root": {
      fontSize: "0.7rem",
    },
    "& .MuiCheckbox-root": {
      padding: "0 1rem",
    },
    backgroundColor: theme.palette.background.paper,
    backgroundImage: "none",
    boxShadow: "none",
    "& .MuiTableRow-root": {
      border: "none",
    },
    "& .MuiTableCell-paddingCheckbox": {
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiTablePagination-toolbar": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      padding: 5,
      gap: "0px 10px",
    },
    "& .MuiTablePagination-input": {
      margin: 0,
    },
    "& .MuiTablePagination-actions": {
      marginLeft: 0,
    },
    ...styles,
  }),
)

const filler = keyframes`
    0% { transform: scale(0.9); opacity: 0.3; }
    100% { transform: scale(1); opacity: 1;}
`
export const SvgLogo = styled("img")(() => ({
  position: "absolute",
  height: "10vh",
  opacity: 0.2,
  animation: `${filler} 1s linear infinite`,
  animationDirection: "alternate",

  "&#progress.start": {
    animation: `${filler} 1.2s cubic-bezier(0.3, 0.3, 0.3, 1) 3s both`,
  },
}))

const Tab = styled(MUITab)(() => ({
  textTransform: "none",
}))

const _LinkTab: React.ComponentType<TabProps & LinkProps> = (props) => {
  const TabWithComponent = Tab as React.ComponentType<
    TabProps &
      LinkProps & {
        component: React.ForwardRefExoticComponent<
          LinkProps & React.RefAttributes<HTMLAnchorElement>
        >
      }
  >
  return <TabWithComponent {...props} component={Link} />
}

export const LinkTab: React.ComponentType<TabProps & LinkProps> = _LinkTab

export const Accordion = styled(MUIAccordion, {
  shouldForwardProp: (prop) => prop !== "state",
})<IAccordionProps>(({ theme, styles, state }) => ({
  backgroundColor: "transparent",
  borderColor: alpha(getAccordionStateColor(state, theme), 0.5),
  ...styles,
}))

export const AccordionSummary = styled(MUIAccordionSummary, {
  shouldForwardProp: (prop) => prop !== "state",
})<IAccordionProps>(({ theme, styles, state }) => ({
  backgroundColor: alpha(getAccordionStateColor(state, theme), 0.05),
  ...styles,
}))

export const AccordionDetails = styled(MUIAccordionDetails, {
  shouldForwardProp: (prop) => prop !== "state",
})<IAccordionProps>(({ theme, styles, state }) => ({
  borderTop: `1px solid ${alpha(getAccordionStateColor(state, theme), 0.5)}`,
  ...styles,
}))
