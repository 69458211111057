import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { Box, Stack } from "@mui/material"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"

import type { Device } from "types/device.types"
import type { DeviceGroupRef } from "types/group.types"

import usePermissions from "helpers/hooks/usePermissions"
import Spinner from "widgets/common/Spinner"
import GroupsChips from "widgets/deviceGroups/GroupsChips"
import Title from "widgets/common/Title"
import EntityTag from "widgets/common/EntityTag"
import EditableDeviceName from "./editor/EditableDeviceName"

interface IDeviceSummary {
  device: Device
  groups: DeviceGroupRef[]
}

const DeviceSummary: FC<IDeviceSummary> = ({ device, groups }) => {
  const { isEditor } = usePermissions()
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        marginBottom: "1rem",
      }}
      data-testid="device-summary"
    >
      <Stack
        flexDirection={"row"}
        columnGap={"15px"}
        rowGap={"5px"}
        alignItems={"center"}
        marginBottom={"0.5rem"}
        flexWrap={"wrap"}
      >
        <EntityTag entity="device" />
        {isEditor ? (
          <EditableDeviceName name={device.name} />
        ) : (
          <Title text={device.name} />
        )}
      </Stack>
      {device ? (
        <Grid
          container
          direction={"column"}
          sx={{
            width: "fit-content",
            gap: { xs: "0.3rem", sm: "0.7rem" },
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          <Grid
            container
            item
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ gap: { xs: "0.3rem", sm: "1rem" }, width: "fit-content" }}
          >
            <Typography>ID: {device.less_id}</Typography>
            <Typography>
              {t("device_information.TYPE")}: {device.device_type}
            </Typography>
          </Grid>
          {groups.length !== 0 && <GroupsChips groups={groups} />}
        </Grid>
      ) : (
        <Spinner />
      )}
    </Box>
  )
}

export default DeviceSummary
