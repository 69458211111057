import { Grid } from "@mui/material"
import PowerlineAlertSettings from "widgets/alerts/settings/powerline/PowerlineAlertSettings"

import { BodyContainer } from "widgets/styled/containers"

function PowerlineSettings() {
  return (
    <BodyContainer disableGutters maxWidth="xl" sx={{ pt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PowerlineAlertSettings />
        </Grid>
      </Grid>
    </BodyContainer>
  )
}

export default PowerlineSettings
