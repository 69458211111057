import type { FC } from "react"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { DateTime } from "luxon"

import { useLazyDeviceTelemetryQuery } from "features/api"
import type { AlertSettingParam } from "types/alerts.types"
import type { TelemetryType } from "types/telemetries.types"
import { formatSelectTelemetries } from "helpers/utils/telemetries"
import TelemetryInput from "../TelemetryInput"

interface DeviceTelemetryInputProps {
  param: AlertSettingParam
}

const DeviceTelemetryInput: FC<DeviceTelemetryInputProps> = ({ param }) => {
  const { t } = useTranslation()
  const { id } = useParams()

  const [telemetries, setTelemetries] = useState<TelemetryType[]>()
  const [fetchTelemetries, { isFetching }] = useLazyDeviceTelemetryQuery()

  useEffect(() => {
    const date = DateTime.now()
    const from_date: string = date.minus({ months: 6 }).toISO() as string
    const to_date: string = date.toISO() as string

    fetchTelemetries({
      id: Number(id),
      params: {
        only_field_names: true,
        types: ["int", "float"],
        from_date,
        to_date,
      },
    })
      .unwrap()
      .then((data: TelemetryType[]) => setTelemetries(data))
      .catch(console.error)
  }, [id, fetchTelemetries])

  const selectTelemetries = useMemo(
    () => formatSelectTelemetries(telemetries, t) || [],
    [telemetries, t],
  )

  return (
    <TelemetryInput
      param={param}
      isFetching={isFetching}
      telemetries={selectTelemetries}
    />
  )
}

export default DeviceTelemetryInput
