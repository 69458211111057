import { useContext, useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Divider from "@mui/material/Divider"
import { useTheme } from "@mui/material"
import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"

import type { DeviceDashboardOutletContext } from "types/dashboard.types"
import { formatPlotTitleTooltip } from "helpers/utils/plots"
import PlotTitle from "widgets/plots/PlotTitle"
import type { InstallationType } from "types/device.types"
import { getPlotTooltipContent, getViewsSrcs } from "helpers/utils/devicePosition"
import DashboardContext from "widgets/device/Dashboard/DashboardContext"
import { GridBox, PlotContainer, PositionBox } from "widgets/styled/containers"
import { AngleView } from "./AngleView"
import { TowerViewDialog } from "./TowerViewDialog"

export interface DevicePositionPlotValues {
  _time: number
  alpha: number
  beta: number
}

interface IDevicePositionPlotProps {
  values: DevicePositionPlotValues
  isLatest?: boolean
}

const DevicePositionPlot = ({ values, isLatest }: IDevicePositionPlotProps) => {
  const theme = useTheme()
  const { device } = useOutletContext<DeviceDashboardOutletContext>()
  const { rowHeight } = useContext(DashboardContext)
  const { alpha, beta, _time } = values
  const { t } = useTranslation()
  const [infoPopupOpen, setInfoPopupOpen] = useState(false)

  const handleInfoPopupOpen = () => {
    setInfoPopupOpen(true)
  }
  const handleInfoPopupClose = () => {
    setInfoPopupOpen(false)
  }

  const tooltipContent = useMemo(() => {
    if (!device.settings) return undefined
    const tooltipContent = getPlotTooltipContent(device.settings.installation_type)
    return formatPlotTitleTooltip(tooltipContent, t)
  }, [device, t])

  const { alphaViewSrc, betaViewSrc } = useMemo(() => getViewsSrcs(device), [device])

  // Making powerline the default as a transition
  const installationType = useMemo(
    () => device.settings?.installation_type ?? ("powerline" as InstallationType),
    [device],
  )

  return (
    <>
      <PlotContainer
        type=""
        disableGutters
        maxWidth="xl"
        styles={{
          height: `${rowHeight * 10}px`,
          marginTop: "1rem",
          padding: "1rem 2rem 3rem 2rem",
          marginLeft: 0,
          [theme.breakpoints.up("sm")]: {
            height: `${rowHeight * 5}px`,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
            mb: "0.5rem",
            [theme.breakpoints.up("sm")]: {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            },
          }}
        >
          <PlotTitle
            title={t("device_information.LAST_POSITION")}
            tooltipContent={tooltipContent}
            onIconClick={installationType === "tower" ? handleInfoPopupOpen : undefined}
          />

          <Typography variant="body1" color="text.secondary" textAlign="right">
            {DateTime.fromMillis(_time).toFormat("yyyy-MM-dd HH:mm:ss")}{" "}
            {isLatest && <span>({`${t("device_information.LAST_POSITION")}`})</span>}
          </Typography>
        </Box>
        <PositionBox>
          <GridBox />
          <Grid container height="100%" spacing={1}>
            <Grid item xs={12} sm={6} sx={{ position: "relative" }}>
              <Divider
                orientation="vertical"
                variant="middle"
                sx={{
                  border: "1px solid",
                  borderColor: "neutral.300",
                  position: "absolute",
                  left: "50%",
                  height: "90%",
                }}
              />
              <AngleView
                src={alphaViewSrc}
                installationType={installationType}
                rowHeight={rowHeight}
                value={alpha}
                variableName="alpha"
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ position: "relative" }}>
              <Divider
                orientation="vertical"
                variant="middle"
                sx={{
                  border: "1px solid",
                  borderColor: "neutral.300",
                  position: "absolute",
                  left: "50%",
                  height: "90%",
                }}
              />
              <AngleView
                src={betaViewSrc}
                installationType={installationType}
                rowHeight={rowHeight}
                value={beta}
                variableName="beta"
              />
            </Grid>
          </Grid>
        </PositionBox>
      </PlotContainer>
      <TowerViewDialog open={infoPopupOpen} onClose={handleInfoPopupClose} />
    </>
  )
}

export default DevicePositionPlot
