import { useMemo } from "react"
import type { MouseEvent, ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import type { LabelDisplayedRowsArgs } from "@mui/material"
import { Stack, TableCell, TableFooter, TablePagination, TableRow } from "@mui/material"

import LoadingButton from "./LoadingButton"

interface CustomTableFooterProps {
  rowCount: number
  page: number
  rowsPerPage: number
  changePage: (newPage: number) => void
  changeRowsPerPage: (rowsPerPage: number) => void
  areMoreAvailable: boolean
  loadMore: () => void
  isWaiting: boolean
}

const CustomTableFooter = ({
  rowCount,
  page,
  rowsPerPage,
  changePage,
  changeRowsPerPage,
  areMoreAvailable,
  loadMore,
  isWaiting,
}: CustomTableFooterProps) => {
  const { t } = useTranslation()
  const isLastPage = useMemo(
    () => Math.ceil(rowCount / rowsPerPage) === page + 1,
    [page, rowCount, rowsPerPage],
  )

  const areMoreResultsRequestable = useMemo(() => {
    return isLastPage && areMoreAvailable
  }, [areMoreAvailable, isLastPage])

  const handlePageChange = (_: MouseEvent<HTMLButtonElement> | null, page: number) => {
    changePage(page)
  }

  const handleRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    changePage(0)
    changeRowsPerPage(Number(event.target.value))
  }

  return (
    <TableFooter>
      <TableRow>
        <TableCell>
          <Stack direction={"row"} justifyContent={"end"} alignItems={"center"}>
            <TablePagination
              component={"div"}
              count={rowCount}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              labelRowsPerPage={t("table.ROWS_PER_PAGE")}
              labelDisplayedRows={({ from, to, count }: LabelDisplayedRowsArgs) =>
                `${from}-${to} ${t("table.OF")} ${count}`
              }
            />
            {areMoreResultsRequestable && (
              <LoadingButton
                type="button"
                disabled={!areMoreResultsRequestable}
                onClick={() => loadMore()}
                loading={isWaiting}
              >
                {t("table.LOAD_MORE")}
              </LoadingButton>
            )}
          </Stack>
        </TableCell>
      </TableRow>
    </TableFooter>
  )
}

export default CustomTableFooter
