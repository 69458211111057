import { Divider, Grid, Skeleton, Stack } from "@mui/material"
import fp from "lodash/fp"

import { CARD_MAP_HEIGHT } from "helpers/utils/constants"
import { Card } from "widgets/styled/containers"

const PowerlineCardSkeleton = () => {
  return (
    <Grid container spacing={2} alignItems="stretch" overflow={"hidden"} mb={"0.5rem"}>
      {fp
        .times((index) => index)(3)
        .map((powerline) => (
          <Grid item xs={12} sm={6} md={4} xl={4} key={powerline}>
            <Card
              elevation={0}
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                height: "450px",
                border: (theme) => `1px solid ${theme.palette.neutral[400]}`,
                padding: "1.5rem 1rem",
              }}
            >
              <Stack spacing={1} divider={<Divider flexItem />}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <Skeleton variant="circular" width={"30px"} height={"30px"} />
                  <Skeleton variant="rounded" height={"18px"} sx={{ flexGrow: 1 }} />
                </Stack>
                <Stack gap={1.3} width={"100%"}>
                  {fp
                    .times((index) => index)(4)
                    .map((label) => (
                      <Skeleton key={label} variant="rounded" height={"18px"} />
                    ))}
                </Stack>
                <Skeleton variant="rounded" height={CARD_MAP_HEIGHT} />
              </Stack>
            </Card>
          </Grid>
        ))}
    </Grid>
  )
}

export default PowerlineCardSkeleton
