import { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"

import type { Powerline } from "types/powerlines.types"

import { selectCurrentOrgId } from "features/store/orgSlice"
import { usePowerlinesQuery } from "features/api"

const usePowerlines = () => {
  const orgId = useSelector(selectCurrentOrgId)
  const [powerlines, setPowerlines] = useState<Powerline[]>([])
  const [cursor, setCursor] = useState<string | undefined>()

  const {
    data: pagedPowerlines,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = usePowerlinesQuery(
    {
      params: { org_id: Number(orgId), limit: 100, cursor },
    },
    {
      skip: !orgId,
    },
  )

  const next = useMemo(() => pagedPowerlines?.next, [pagedPowerlines?.next])

  const loadMore = useCallback(() => setCursor(next), [next])

  useEffect(() => {
    if (pagedPowerlines) {
      setPowerlines((prevState) => [...prevState, ...pagedPowerlines.results])
    }
  }, [pagedPowerlines])

  return {
    powerlines,
    areMoreAvailable: Boolean(next),
    loadMore,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export default usePowerlines
