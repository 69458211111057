import { lazy, Suspense } from "react"
import { Navigate, Route, Routes as RouterRoutes, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

import { selectCurrentUser, selectTerms } from "features/store/authSlice"
import { DEFAULT_TARGET, PREVIOUS_LOCATION_KEY } from "helpers/utils/constants"
import { termsCheckByStatus } from "helpers/utils/user"
import { DashboardLayout } from "layouts/DashboardLayout"
import { PublicLayout } from "layouts/PublicLayout"
import DeviceData from "pages/orgs/Device/DeviceData"
import MainDashboard from "pages/orgs/Device/MainDashboard"
import Login from "pages/accounts/Login"
import LoginOAuth from "pages/accounts/LoginOAuth"
import Logout from "pages/accounts/Logout"
import Register from "pages/accounts/Register"
import Device from "pages/orgs/Device"
import DeviceAlerts from "pages/orgs/Device/DeviceAlerts"
import DeviceEventsLog from "pages/orgs/Device/DeviceEventsLog"
import DeviceGroup from "pages/orgs/DeviceGroup"
import DeviceGroups from "pages/orgs/DeviceGroups"
import DevicePosition from "pages/orgs/Device/DevicePosition"
import DeviceSettings from "pages/orgs/Device/DeviceSettings"
import GroupAlerts from "pages/orgs/DeviceGroup/GroupAlerts"
import GroupEventsLog from "pages/orgs/DeviceGroup/GroupEventsLog"
import GroupInfo from "pages/orgs/DeviceGroup/GroupInfo"
import PlotsNew from "pages/orgs/PlotsNew"
import OrgSettings from "pages/orgs/OrgSettings"
import OrgHomepage from "pages/orgs"
import UserHomepage from "pages/users"
import CreateToken from "pages/users/CreateToken"
import Api from "pages/documentation/api"
import ApiDoc from "pages/documentation/api/ApiDoc"
import BoardLoader from "pages/BoardLoader"
import PasswordRecover from "pages/accounts/PasswordRecover"
import ActivateUser from "pages/accounts/ActivateUser"
import AlertsContent from "pages/orgs/Alerts/AlertsContent"
import EventsLog from "pages/orgs/Alerts/EventsLog"
import Alerts from "pages/orgs/Alerts"
import AccountGeneral from "pages/users/AccountSettings/AccountGeneral"
import AccountSecurity from "pages/users/AccountSettings/AccountSecurity"
import AccountSettings from "pages/users/AccountSettings"
import ProductDocs from "pages/documentation/product"
import AuditTrail from "pages/orgs/AuditTrail"
import Map from "pages/orgs/Map"
import TermsAndConditions from "pages/public/TermsAndConditions"
import AcceptTermsAndConditions from "pages/users/AcceptTermsAndConditions"
import Powerlines from "pages/orgs/Powerlines"
import Powerline from "pages/orgs/Powerline"
import PowerlineInfo from "pages/orgs/Powerline/PowerlineInfo"
import PowerlineDevices from "pages/orgs/Powerline/PowerlineDevices"
import PowerlineSettings from "pages/orgs/Powerline/PowerlineSettings"
import PowerlineAlerts from "pages/orgs/Powerline/PowerlineAlerts"
import PowerlineEventsLog from "pages/orgs/Powerline/PowerlineEventsLog"
import Preloader from "widgets/common/PreLoader"

const BaseLayout = lazy(() =>
  import("layouts").then((module) => ({
    default: module.BaseLayout,
  })),
)
const RecoverPasswordLazy = lazy(() => import("pages/accounts/Recover"))
const ModifyPasswordLazy = lazy(() => import("pages/users/ModifyPassword"))
const QRPageLazy = lazy(() => import("pages/QRPage"))

export default function Routes() {
  const username = useSelector(selectCurrentUser)
  const terms = useSelector(selectTerms)

  const location = useLocation()

  if (
    !location.pathname.includes("/accounts/") &&
    !location.pathname.includes("terms-and-conditions") &&
    location.pathname !== DEFAULT_TARGET
  ) {
    sessionStorage.setItem(
      PREVIOUS_LOCATION_KEY,
      `${location.pathname}${location.search}`,
    )
  }

  return (
    <RouterRoutes>
      <Route element={<PublicLayout />}>
        <Route path="/accounts/login" element={<Login />} />
        <Route path="/accounts/logout" element={<Logout />} />
        <Route path="/accounts/oauth-login" element={<LoginOAuth />} />
        <Route path="/accounts/register" element={<Register />} />
        <Route
          path="/accounts/recover"
          element={
            <Suspense fallback={<Preloader />}>
              <RecoverPasswordLazy />
            </Suspense>
          }
        />
        <Route
          path="/accounts/:username/password-recover/:code"
          element={<PasswordRecover />}
        />
        <Route path="/accounts/:username/activate/:secret" element={<ActivateUser />} />
      </Route>

      <Route
        path="/qr"
        element={
          <Suspense fallback={<Preloader />}>
            <QRPageLazy />
          </Suspense>
        }
      />

      <Route path="/users/:username/modify-password" element={<PublicLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<Preloader />}>
              <ModifyPasswordLazy />
            </Suspense>
          }
        />
      </Route>
      {username && !termsCheckByStatus(terms) && (
        <Route
          path="/accept-terms-and-conditions"
          element={<AcceptTermsAndConditions />}
        />
      )}
      <Route path="terms-and-conditions" element={<TermsAndConditions />} />

      <Route
        path="/"
        element={username ? <BoardLoader /> : <Navigate to="/accounts/login" />}
      />

      <Route
        element={
          !username ? (
            <Navigate to="/accounts/login" />
          ) : (
            <Suspense fallback={<Preloader />}>
              <BaseLayout />
            </Suspense>
          )
        }
      >
        <Route element={<DashboardLayout />}>
          <Route path="/users/:username">
            <Route index element={<UserHomepage />} />
            <Route path="settings" element={<AccountSettings />}>
              <Route index element={<AccountGeneral />} />
              <Route path="security" element={<AccountSecurity />} />
            </Route>
          </Route>

          <Route path="/orgs/:orgId">
            <Route index element={<OrgHomepage />} />
            <Route path="powerlines" element={<Powerlines />} />
            <Route path="powerlines/:id" element={<Powerline />}>
              <Route index element={<PowerlineInfo />} />
              <Route path="devices" element={<PowerlineDevices />} />
              <Route path="alerts" element={<PowerlineAlerts />} />
              <Route path="events-log" element={<PowerlineEventsLog />} />
              <Route path="settings" element={<PowerlineSettings />} />
            </Route>
            <Route path="device-groups" element={<DeviceGroups />} />
            <Route path="device-groups/:id" element={<DeviceGroup />}>
              <Route index element={<GroupInfo />} />
              <Route path="alerts" element={<GroupAlerts />} />
              <Route path="events-log" element={<GroupEventsLog />} />
            </Route>
            <Route path="devices/:id" element={<Device />}>
              <Route index element={<MainDashboard />} />
              <Route path="position" element={<DevicePosition />} />
              <Route path="device-data" element={<DeviceData />} />
              <Route path="alerts" element={<DeviceAlerts />} />
              <Route path="events-log" element={<DeviceEventsLog />} />
              <Route path="settings" element={<DeviceSettings />} />
            </Route>
            <Route path="alerts" element={<Alerts />}>
              <Route index element={<AlertsContent />} />
              <Route path="events-log" element={<EventsLog />} />
            </Route>
            {/* recharts plots tests/demo, not linked anywhere */}
            <Route path="plots-new" element={<PlotsNew />} />
            <Route path="administration/organization" element={<OrgSettings />} />
            <Route path="administration/audit-trail" element={<AuditTrail />} />
          </Route>
          <Route path="documentation/api" element={<Api />}>
            <Route index element={<ApiDoc />} />
            <Route path="create-token" element={<CreateToken />} />
          </Route>
          <Route path="documentation/product" element={<ProductDocs />} />
        </Route>
        <Route element={<DashboardLayout fullWidth />}>
          <Route path="/orgs/:orgId">
            <Route path="map" element={<Map />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<Navigate to={DEFAULT_TARGET} replace />} />
    </RouterRoutes>
  )
}
