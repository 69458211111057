import { t } from "i18next"
import fp from "lodash/fp"

import type {
  AlertSettingParam,
  AlertSettingState,
  AlertSetting,
  AlertSettingType,
  DeviceAlertSettingAccordion,
  AccordionsList,
  OptionsParamSchema,
  AlertSettingConfigThreshold,
  AlertSettingWState,
  AlertsAmount,
  AlertsFilterOptions,
  AlertSettingAccordion,
  DeviceAlertSettingWState,
  AlertSettingConfig,
  PowerlineAlertSettingWState,
  DeviceAlertSetting,
  PowerlineAlertSetting,
  PowerlineAlertSettingAccordion,
} from "types/alerts.types"
import type { IDeviceWRelsById, IDevsWRelsByIdWStates } from "types/device.types"
import type { DeviceGroup, IGroupsWRelsByIdWStates } from "types/group.types"
import type { Powerline } from "types/powerlines.types"

import { translateBoolean } from "helpers/utils/translations"
import { getAlertState } from "helpers/utils/alerts"

export const getTypeParams = (
  type: string,
  alertTypes: AlertSettingType[],
  filters: string[],
): AlertSettingParam[] | undefined => {
  const alertType = alertTypes.find(({ name }) => name === type)
  return alertType?.params.filter(({ id }) => !filters.includes(id))
}

export const getConfigValue = (
  typeParam: AlertSettingParam,
  alertSetting: AlertSetting,
) => {
  const { id } = typeParam
  const value = alertSetting?.config[id as keyof AlertSettingConfig]
  if (value === undefined) {
    return typeParam.default ? typeParam.default : "-"
  }
  return value
}

export const getUiConfigValue = (
  typeParam: AlertSettingParam,
  alertSetting: AlertSetting,
) => {
  let value = getConfigValue(typeParam, alertSetting)
  if (typeParam.type === "bool") {
    return translateBoolean(value as boolean)
  }
  if (typeParam.type === "array") {
    return fp
      .map(
        (item: string | AlertSettingConfigThreshold) => {
          if (typeof item !== "object") {
            return item
          }
          const parts = []
          // U00a0: nbsp ; U2192: ->
          if (item.min !== undefined && item.min !== null) {
            parts.push(`${item.min}\u00a0<`)
          }
          parts.push(t("alerts.VALUE"))
          if (item.max !== undefined && item.max !== null) {
            parts.push(`<\u00a0${item.max}`)
          }
          parts.push(`\u2192\u00a0${item.target}`)
          return parts.join(" ")
        },
        value as string[] | AlertSettingConfigThreshold[],
      )
      .join(", ")
  }
  if (typeParam.type === "options") {
    const optionSchema = typeParam.schema as OptionsParamSchema
    const option = optionSchema?.oneOf.find((item) => item.const === value)
    value = option && option.label ? option.label : value
  }
  if (typeParam.unit) {
    return `${value} ${typeParam.unit}`
  }
  return value
}

export const formatStateBySettingId = (
  states: AlertSettingWState[] | undefined,
): { [key: string]: AlertSettingState } =>
  fp.flow(fp.indexBy("id"), fp.mapValues(fp.prop("state")))(states)

export const formatSettingsById = (
  settings: AlertSetting[] | undefined,
): { [key: string]: AlertSetting } => fp.indexBy("id", settings)

export const formatSettingsByDeviceId = (
  states: AlertSettingWState[] | undefined,
): { [key: string]: number[] } =>
  fp.flow(
    fp.filter("less_id"),
    fp.groupBy("less_id"),
    fp.mapValues(fp.map(fp.prop("id"))),
  )(states)

export const formatSettingsByGroupId = (
  states: AlertSettingWState[] | undefined,
): { [key: string]: number[] } =>
  fp.flow(
    fp.filter("group_id"),
    fp.groupBy("group_id"),
    fp.mapValues(fp.map(fp.prop("id"))),
  )(states)

export const formatSettingsByPowerlineId = (
  states: AlertSettingWState[] | undefined,
): { [key: string]: number[] } =>
  fp.flow(
    fp.filter((alertSetting: AlertSettingWState) => alertSetting.entity === "powerline"),
    fp.groupBy("entity_id"),
    fp.mapValues(fp.map(fp.prop("id"))),
  )(states)

// This function is aimed to format groups and devices
export const formatEntitiesWithStates = (
  entitiesById: { [key: string]: { group: DeviceGroup } } | IDeviceWRelsById | undefined,
  settingsByEntityId: { [key: string]: number[] },
  statesById: { [key: string]: AlertSettingState },
): IDevsWRelsByIdWStates | IGroupsWRelsByIdWStates =>
  fp.flow(
    fp.defaultTo({}),
    fp.toPairs,
    fp.map(([entityId, entity]) => {
      const getSettingsStates = fp.flow(
        fp.get(entityId),
        fp.map((settingId: number) => [settingId, fp.get(settingId)(statesById)]),
        fp.fromPairs,
      )
      const settingsStates = getSettingsStates(settingsByEntityId)
      return [
        entityId,
        {
          ...entity,
          settingsStates,
        },
      ]
    }),
    fp.fromPairs,
  )(entitiesById)

export const formatDeviceAlerts = (
  orgDeviceSettingsStates: DeviceAlertSettingWState[],
  orgActiveDeviceAlertSettingsById: { [key: string]: DeviceAlertSetting },
  devsWRelsById: IDeviceWRelsById | undefined,
  groupId?: number, // used for the group alerts list (device alerts belonging to a certain group)
): DeviceAlertSettingAccordion[] =>
  fp.flow(
    fp.defaultTo([] as DeviceAlertSettingWState[]),
    fp.filter((alert: DeviceAlertSettingWState) => {
      const deviceExistsInMemory = fp.has(alert.entity_id, devsWRelsById)
      const alertExistsInMemory = fp.has(alert.id, orgActiveDeviceAlertSettingsById)
      return deviceExistsInMemory && alertExistsInMemory
    }),
    fp.map((alert: DeviceAlertSettingWState): DeviceAlertSettingAccordion => {
      return {
        ...orgActiveDeviceAlertSettingsById[alert.id],
        name: (devsWRelsById || {})[alert.entity_id].device.name,
        state: getAlertState(alert.state),
        triggered_at: alert.state?.triggered_at,
        groupsIds: (devsWRelsById || {})[alert.entity_id].groups.map((group) => group.id),
      }
    }),
    fp.filter((alert) => !groupId || (alert.groupsIds as number[]).includes(groupId)),
  )(orgDeviceSettingsStates)

export const formatPowerlineAlerts = (
  orgPowerlineSettingsStates: PowerlineAlertSettingWState[],
  orgActivePowerlineAlertSettingsById: { [key: string]: PowerlineAlertSetting },
  powerlinesById: {
    [key: number]: Powerline
  },
): PowerlineAlertSettingAccordion[] =>
  fp.flow(
    fp.defaultTo([] as PowerlineAlertSettingWState[]),
    fp.filter((alert: PowerlineAlertSettingWState) => {
      const powerlineExistsInMemory = fp.has(alert.entity_id, powerlinesById)
      const alertExistsInMemory = fp.has(alert.id, orgActivePowerlineAlertSettingsById)
      return powerlineExistsInMemory && alertExistsInMemory
    }),
    fp.map((alert: PowerlineAlertSettingWState): PowerlineAlertSettingAccordion => {
      return {
        ...orgActivePowerlineAlertSettingsById[alert.id],
        name: powerlinesById[alert.entity_id].name,
        state: getAlertState(alert.state),
        triggered_at: alert.state?.triggered_at,
      }
    }),
  )(orgPowerlineSettingsStates)

export const sortAndGroupAlerts = (
  alertSettingAccordions: AlertSettingAccordion[],
): AccordionsList =>
  fp.flow(
    fp.sortBy((alert: AlertSettingAccordion) => alert.triggered_at),
    fp.groupBy((alert: AlertSettingAccordion) => alert.state),
    fp.defaults({
      open: [],
      silenced: [],
      notTriggered: [],
    }),
  )(alertSettingAccordions)

export const getAlertAmounts = (alerts: AccordionsList): AlertsAmount => {
  return fp.mapValues((stateAlerts) => stateAlerts.length, alerts)
}

export const getFilteredSortedAlerts = (
  alerts: AccordionsList,
  filters: AlertsFilterOptions | null,
): AlertSettingAccordion[] => {
  if (!filters) {
    return []
  }
  const keys: ("open" | "silenced" | "notTriggered")[] = [
    "open",
    "silenced",
    "notTriggered",
  ]
  return fp.flatMap((key) => (filters[key] ? alerts[key] : []), keys)
}
