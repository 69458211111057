import { useTranslation } from "react-i18next"
import { Grid, useTheme } from "@mui/material"

import usePowerlines from "helpers/hooks/powerlines/usePowerlines"
import Title from "widgets/common/Title"
import { HeaderContainer } from "widgets/styled/containers"
import PowerlineCardsSection from "./PowerlineCardsSection"
import PowerlinesTable from "./PowerlinesTable"

const PowerlinesContent = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { powerlines, areMoreAvailable, loadMore, isLoading, isFetching, isError } =
    usePowerlines()

  return (
    <>
      <HeaderContainer>
        <Title text={t("powerlines.POWERLINES")} />
      </HeaderContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PowerlineCardsSection
            powerlines={powerlines}
            areMoreAvailable={areMoreAvailable}
            loadMore={loadMore}
            isFetching={isFetching}
            isLoading={isLoading}
            isError={isError}
          />
        </Grid>
        <Grid item xs={12}>
          <PowerlinesTable
            powerlines={powerlines}
            areMoreAvailable={areMoreAvailable}
            loadMore={loadMore}
            isFetching={isFetching}
            isLoading={isLoading}
            titleKey="powerlines.POWERLINES"
            styles={{ border: `1px solid ${theme.palette.neutral[400]}` }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default PowerlinesContent
