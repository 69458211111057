import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import {
  formatDeviceAlerts,
  getAlertAmounts,
  getFilteredSortedAlerts,
  sortAndGroupAlerts,
} from "helpers/formatters/alertSettingsFormatters"
import useAlertSettings from "helpers/hooks/useAlertSettings"
import useAlertSettingsWStates from "helpers/hooks/useAlertSettingsStates"
import useDevices from "helpers/hooks/useDevices"
import { paginateList } from "helpers/utils/common"
import type { AlertsFilterOptions } from "types/alerts.types"
import Spinner from "widgets/common/Spinner"
import AccordionsSection from "widgets/alerts/AccordionsSection"
import AlertsFilter from "widgets/alerts/AlertsFilter"
import Pagination from "widgets/common/Pagination"
import { SectionContainer } from "widgets/styled/containers"

const GroupAlertsContent = () => {
  const { id } = useParams()
  const { orgDevicesAlertSettingsWStates, isLoading } = useAlertSettingsWStates()
  const { orgActiveDevicesAlertSettingsById } = useAlertSettings()
  const { devsWRelsById } = useDevices()
  const [filters, setFilters] = useState<AlertsFilterOptions | null>(null)
  const [page, setPage] = useState(0)

  const formattedGroupAlerts = useMemo(
    () =>
      formatDeviceAlerts(
        orgDevicesAlertSettingsWStates,
        orgActiveDevicesAlertSettingsById,
        devsWRelsById,
        Number(id),
      ),
    [
      orgDevicesAlertSettingsWStates,
      orgActiveDevicesAlertSettingsById,
      devsWRelsById,
      id,
    ],
  )
  const sortedAndGroupedAlerts = useMemo(
    () => sortAndGroupAlerts(formattedGroupAlerts),
    [formattedGroupAlerts],
  )
  const groupAlertsAmount = useMemo(
    () => getAlertAmounts(sortedAndGroupedAlerts),
    [sortedAndGroupedAlerts],
  )
  const groupAlertsSortedByState = useMemo(
    () => getFilteredSortedAlerts(sortedAndGroupedAlerts, filters),
    [filters, sortedAndGroupedAlerts],
  )
  const pagedAlerts = useMemo(
    () => paginateList(page, groupAlertsSortedByState),
    [groupAlertsSortedByState, page],
  )

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <SectionContainer disableGutters maxWidth="xl">
          <AlertsFilter
            alertsAmount={groupAlertsAmount}
            filters={filters}
            setFilters={setFilters}
          />
          <AccordionsSection alerts={pagedAlerts} />
          <Pagination
            count={groupAlertsSortedByState.length}
            page={page}
            setPage={setPage}
          />
        </SectionContainer>
      )}
    </>
  )
}

export default GroupAlertsContent
