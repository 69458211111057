import type { Dispatch, SetStateAction } from "react"
import type { SetURLSearchParams } from "react-router-dom"
import fp from "lodash/fp"

import type { AlertsFilterOptions, AlertsFilterParams } from "types/alerts.types"

const convertStringToBoolean = (value: string | null): boolean => value === "true"
const getFilterDefaultValue = (
  value: keyof AlertsFilterOptions,
  defaultValues: (keyof AlertsFilterOptions)[],
): string => (defaultValues.includes(value) ? "true" : "false")

const updater = (
  params: AlertsFilterParams,
  filters: AlertsFilterOptions,
  setSearchParams: SetURLSearchParams,
  setFilters: Dispatch<SetStateAction<AlertsFilterOptions | null>>,
) => {
  setSearchParams(
    (prev: URLSearchParams) => {
      const pairs: [string, string][] = []
      prev.forEach((value: string, key: string) => {
        return pairs.push([key, value])
      })
      return { ...fp.fromPairs(pairs), ...params }
    },
    { replace: true },
  )
  setFilters(filters)
}

export const initializeAlertsFilters = (
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
  setFilters: Dispatch<SetStateAction<AlertsFilterOptions | null>>,
  defaultValues = ["open", "silenced", "notTriggered"] as (keyof AlertsFilterOptions)[],
): void => {
  const open = searchParams.get("open") || getFilterDefaultValue("open", defaultValues)
  const silenced =
    searchParams.get("silenced") || getFilterDefaultValue("silenced", defaultValues)
  const notTriggered =
    searchParams.get("notTriggered") ||
    getFilterDefaultValue("notTriggered", defaultValues)
  const params = { open, silenced, notTriggered }
  const filters = fp.mapValues(
    (filter) => convertStringToBoolean(filter),
    params as AlertsFilterParams,
  )

  updater(params, filters, setSearchParams, setFilters)
}

export const updateAlertsFilters = (
  filters: AlertsFilterOptions,
  setSearchParams: SetURLSearchParams,
  setFilters: Dispatch<SetStateAction<AlertsFilterOptions | null>>,
): void => {
  const params = fp.mapValues((filter) => String(filter), filters)

  updater(params, filters, setSearchParams, setFilters)
}
