import { DateTime } from "luxon"
import fp from "lodash/fp"

import type { TFunction } from "i18next"
import type { Dictionary } from "@reduxjs/toolkit"
import type { MUIDataTableColumnState } from "mui-datatables"

import type { Powerline, PowerlinesTableRow } from "types/powerlines.types"
import type { DeviceWGroupsNStates, IDevsWRelsByIdWStates } from "types/device.types"
import type { IDefaultIdName } from "types/common.types"

import { formatDateTime } from "helpers/utils/dateUtils"
import CustomLink from "widgets/common/CustomLink"

export const getPowerlinesTableColumns = (baseOrgURL: string, t: TFunction) => {
  return [
    {
      name: "ID",
      label: "ID",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => {
          return (
            <CustomLink href={`${baseOrgURL}/powerlines/${value}`} hover bold>
              {value}
            </CustomLink>
          )
        },
      },
    },
    {
      name: "name",
      label: t("powerlines.NAME"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: { name: string; id: number }) => {
          return (
            <CustomLink href={`${baseOrgURL}/powerlines/${value.id}`} hover bold>
              {value.name}
            </CustomLink>
          )
        },
      },
    },
    {
      name: "nominalAmpacity",
      label: t("powerlines.NOMINAL_AMPACITY"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "nominalVoltage",
      label: t("powerlines.NOMINAL_VOLTAGE"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "updatedAt",
      label: t("powerlines.UPDATED_AT"),
      options: {
        filter: true,
        sort: true,
      },
    },
  ]
}

export const getPowerlinesTableRows = (powerlines: Powerline[]) => {
  return powerlines?.map((powerline) => {
    const updatedAt = powerline?.updated_at
      ? DateTime.fromISO(powerline?.updated_at)
      : undefined
    return {
      name: { name: powerline?.name, id: powerline?.id },
      ID: powerline?.id,
      nominalAmpacity: powerline?.ampacity_nominal,
      nominalVoltage: powerline?.voltage_nominal,
      updatedAt: formatDateTime(updatedAt),
    }
  })
}

export const getDevicesByPowerlineId = (
  devices: IDevsWRelsByIdWStates,
  powerlineId: number,
): DeviceWGroupsNStates[] => {
  return fp.flow(
    fp.values,
    fp.filter((entry) => entry.device?.settings?.powerline_id === powerlineId),
  )(devices)
}

export const formatPowerlinesTableCsv = (
  data: { index: number; data: PowerlinesTableRow }[],
  columnsIndexes: Dictionary<number>,
) => {
  return data?.map((row, index) => ({
    index,
    data: row?.data?.map((field: any, index) => {
      switch (index) {
        case columnsIndexes.name:
          return field.name
        default:
          return field
      }
    }),
  }))
}

export const buildPowerlinesSearch = (columnsIndexes: Dictionary<number>) => {
  const getSearchInName = (text: string, row: PowerlinesTableRow) => {
    if (columnsIndexes.name === undefined) {
      return false
    }
    const powerlineName = row[columnsIndexes.name] as IDefaultIdName
    return powerlineName.name.toLowerCase().includes(text)
  }

  const powerlinesCustomSearch = (
    searchQuery: string,
    currentRow: PowerlinesTableRow,
    _columns: MUIDataTableColumnState[],
  ): boolean => {
    const searchText = searchQuery.toLowerCase()

    const searchInName = getSearchInName(searchText, currentRow)
    const isFound = currentRow.some(
      (col) => col.toString().toLowerCase().includes(searchText) || searchInName,
    )
    return isFound
  }

  return powerlinesCustomSearch
}
export const getPowerlinesById = (
  powerlines: Powerline[],
): { [key: number]: Powerline } =>
  fp.indexBy((powerline: Powerline) => powerline.id)(powerlines)
